/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: any; output: any };
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: string; output: string };
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: { input: any; output: any };
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: { input: any; output: any };
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: string; output: string };
};

/** An enumeration. */
export enum AccountAcquisitionChannel {
  /** Organic */
  Organic = 'ORGANIC',
  /** Paid */
  Paid = 'PAID',
  /** Partner */
  Partner = 'PARTNER',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type AccountAudioAssetNode = Node & {
  __typename: 'AccountAudioAssetNode';
  account: AccountRelayNode;
  createdAt: Scalars['DateTime']['output'];
  /** The audio asset editor label. */
  displayName?: Maybe<Scalars['String']['output']>;
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Length (in seconds) of the audio asset. */
  length?: Maybe<Scalars['Int']['output']>;
  /** The datetime the user deleted this asset from their account. */
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Reference key provided by the upload API. */
  uploadKey: Scalars['String']['output'];
};

export type AccountAudioAssetNodeConnection = {
  __typename: 'AccountAudioAssetNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountAudioAssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AccountAudioAssetNode` and its cursor. */
export type AccountAudioAssetNodeEdge = {
  __typename: 'AccountAudioAssetNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AccountAudioAssetNode>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNode = Node & {
  __typename: 'AccountGroupRelayNode';
  accountpartnerSet: AccountPartnerRelayNodeConnection;
  accounts: AccountRelayNodeConnection;
  accountsOtm: AccountRelayNodeConnection;
  children: AccountGroupRelayNodeConnection;
  colorLibraries: ColorLibraryNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  draftVideoCount?: Maybe<Scalars['Int']['output']>;
  /** An external ID number associated with an AccountGroup and used for reporting and tracking by partners. */
  externalId: Scalars['String']['output'];
  fontLibraries: FontLibraryNodeConnection;
  generatedVideoCount?: Maybe<Scalars['Int']['output']>;
  guid: Scalars['String']['output'];
  /** Whether accounts under this account group have access to Omni variations. */
  hasOmni: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  imageLibraries: ImageLibraryNodeConnection;
  parent?: Maybe<AccountGroupRelayNode>;
  partner?: Maybe<AccountPartnerRelayNode>;
  pk?: Maybe<Scalars['Int']['output']>;
  purchasedVideoCount?: Maybe<Scalars['Int']['output']>;
  renderedVideoCount?: Maybe<Scalars['Int']['output']>;
  /** Whether users in this account group should see public collections in the collection dropdown header. */
  shouldShowPublicCollections: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variantGroups: VideoTemplateCollectionRelayNodeConnection;
  videoAssetLibraries: VideoAssetLibraryNodeConnection;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeAccountpartnerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeAccountsArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeAccountsOtmArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeColorLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeDraftVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeFontLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeGeneratedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeImageLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodePurchasedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeRenderedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeVariantGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeVideoAssetLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for AccountGroups. */
export type AccountGroupRelayNodeVisitCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountGroupRelayNodeConnection = {
  __typename: 'AccountGroupRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountGroupRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AccountGroupRelayNode` and its cursor. */
export type AccountGroupRelayNodeEdge = {
  __typename: 'AccountGroupRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AccountGroupRelayNode>;
};

/** Object representing account group usage for CS reports. */
export type AccountGroupUsage = {
  __typename: 'AccountGroupUsage';
  draftVideoCount?: Maybe<Scalars['Int']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  parentName?: Maybe<Scalars['String']['output']>;
  purchasedVideoCount?: Maybe<Scalars['Int']['output']>;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

/** An enumeration. */
export enum AccountOnboardingType {
  /** Could Not Reach */
  CouldNotReach = 'COULD_NOT_REACH',
  /** Kickoff Call */
  KickoffCall = 'KICKOFF_CALL',
  /** Not Onboarded */
  None = 'NONE',
}

/** An enumeration. */
export enum AccountPartnerIntegrationFormats {
  /** All */
  All = 'ALL',
  /** Social Only */
  SocialOnly = 'SOCIAL_ONLY',
  /** TV Only */
  TvOnly = 'TV_ONLY',
}

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNode = Node & {
  __typename: 'AccountPartnerRelayNode';
  accountGroups: AccountGroupRelayNodeConnection;
  accounts: AccountRelayNodeConnection;
  activeUserCount?: Maybe<Scalars['Int']['output']>;
  /** Whether accounts under this partner can purchase or not. */
  canUsersPurchase: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentMonthPurchasedVideoCount?: Maybe<Scalars['Int']['output']>;
  defaultAccountGroup?: Maybe<AccountGroupRelayNode>;
  draftVideoCount?: Maybe<Scalars['Int']['output']>;
  /** Domain used for validating partner email addresses. */
  emailDomain?: Maybe<Scalars['String']['output']>;
  exclusiveTemplateVariants: VideoTemplateVariantRelayNodeConnection;
  exclusiveTemplates: VideoTemplateNodeConnection;
  generatedVideoCount?: Maybe<Scalars['Int']['output']>;
  guid: Scalars['String']['output'];
  /** Whether accounts under this partner have access to Omni variations. */
  hasOmni: Scalars['Boolean']['output'];
  /** Whether accounts under this partner have unlimited purchasing. */
  hasUnlimited: Scalars['Boolean']['output'];
  /** Ability to purchase voice-over services. */
  hasVoiceOver: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** The formats allowed for this partner's SDK integration. */
  integrationFormats: AccountPartnerIntegrationFormats;
  isInvoiced: Scalars['Boolean']['output'];
  isTvOnlyIntegration: Scalars['Boolean']['output'];
  /** The maximum of active user accounts this partner may have. Leave this empty to indicate no limit. */
  maxActiveUsers?: Maybe<Scalars['Int']['output']>;
  /** The maximum of videos this partner may purchase each month. Leave this empty to indicate no limit. */
  maxMonthlyVideos?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  previewLogo: Scalars['String']['output'];
  previewLogoHeight?: Maybe<Scalars['Int']['output']>;
  previewLogoWidth?: Maybe<Scalars['Int']['output']>;
  purchasedVideoCount?: Maybe<Scalars['Int']['output']>;
  renderedVideoCount?: Maybe<Scalars['Int']['output']>;
  /** Unique ID used to initialize the SDK in the host application. This is the same thing that other APIs call an API ID or Customer ID, and it can be any unique string. */
  sdkPartnerId?: Maybe<Scalars['String']['output']>;
  /** Hide the auto-personalize button from the editor. */
  shouldHideBusinessPersonalization: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  /** The SSO handler class that will manage all SSO logins for this partner. */
  ssoHandlerClassPath?: Maybe<AccountPartnerSsoHandlerClassPath>;
  /** Configuration for the SSO handler. */
  ssoHandlerConfiguration: Scalars['JSONString']['output'];
  updatedAt: Scalars['DateTime']['output'];
  visitCount?: Maybe<Scalars['Int']['output']>;
  /** The URL to call for SDK partners webhook events. */
  webhookEndpoint: Scalars['String']['output'];
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeAccountsArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeDraftVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeExclusiveTemplateVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  aspectRatio_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  displayDuration_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupVariant?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupVariant_VariantGroup_Slug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensing?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  videoTemplate?: InputMaybe<Scalars['ID']['input']>;
  videoTemplate_HasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  videoTemplate_ImageAssetCount_Gte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_ImageAssetCount_Lte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_Name?: InputMaybe<Scalars['String']['input']>;
  videoTemplate_Slug?: InputMaybe<Scalars['String']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeExclusiveTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeGeneratedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodePurchasedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeRenderedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * GraphQL schema for Partners.
 *
 * Allows filtering on name.
 */
export type AccountPartnerRelayNodeVisitCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountPartnerRelayNodeConnection = {
  __typename: 'AccountPartnerRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountPartnerRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AccountPartnerRelayNode` and its cursor. */
export type AccountPartnerRelayNodeEdge = {
  __typename: 'AccountPartnerRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AccountPartnerRelayNode>;
};

/** An enumeration. */
export enum AccountPartnerSsoHandlerClassPath {
  /** DummySSOHandler */
  Dummyssohandler = 'DUMMYSSOHANDLER',
  /** FordDirectSSOHandler */
  Forddirectssohandler = 'FORDDIRECTSSOHANDLER',
  /** GrayTVSSOHandler */
  Graytvssohandler = 'GRAYTVSSOHANDLER',
  /** OIDCSSOHandler */
  Oidcssohandler = 'OIDCSSOHANDLER',
  /** SimplifiSSOHandler */
  Simplifissohandler = 'SIMPLIFISSOHANDLER',
}

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNode = Node & {
  __typename: 'AccountRelayNode';
  accountGroup?: Maybe<AccountGroupRelayNode>;
  accountGroups: AccountGroupRelayNodeConnection;
  acquisitionChannel: AccountAcquisitionChannel;
  audioAssets: AccountAudioAssetNodeConnection;
  autofillResults?: Maybe<UserVideoAutofillResultNodeConnection>;
  bulkrenderjobSet: Array<BulkRenderJobType>;
  businesses?: Maybe<BusinessRelayNodeConnection>;
  /** City where account holder is located. */
  city: Scalars['String']['output'];
  colorLibraries: ColorLibraryNodeConnection;
  companyName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  draftVideoCount?: Maybe<Scalars['Int']['output']>;
  emailAddress: Scalars['String']['output'];
  /** An external ID number associated with a Partner and used to identify the account. */
  externalId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** The date and time of this account's first purchase. */
  firstPurchasedAt?: Maybe<Scalars['DateTime']['output']>;
  fontLibraries: FontLibraryNodeConnection;
  generatedVideoCount?: Maybe<Scalars['Int']['output']>;
  guid: Scalars['String']['output'];
  hasActiveSubscriptions?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  imageLibraries: ImageLibraryNodeConnection;
  /** Inactive users are prevented from logging in and will be logged out on their next visit. */
  isActive: Scalars['Boolean']['output'];
  isOmniEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Automatically set by kickoff calls as well. */
  isOnboarded: Scalars['Boolean']['output'];
  /** True for users who created their account via the Waymark SDK. */
  isSdkAccount: Scalars['Boolean']['output'];
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  /** Notes and comments about this account (internal use) */
  notes: Scalars['String']['output'];
  offerContext?: Maybe<OfferContextType>;
  /** The date/time when the onboarding took place. Set automatically when this client is onboarded. */
  onboardedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Automatically set by kickoff calls as well. */
  onboardingType: AccountOnboardingType;
  partner?: Maybe<AccountPartnerRelayNode>;
  paymentProviderObjectId?: Maybe<Scalars['Int']['output']>;
  phone: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  profileImageCropping: Scalars['String']['output'];
  purchasedVideoCount?: Maybe<Scalars['Int']['output']>;
  renderedVideoCount?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  serviceActivities: ServiceActivityRelayNodeConnection;
  shopUrl?: Maybe<Scalars['String']['output']>;
  /** State where account holder is located. */
  state: Scalars['String']['output'];
  stockVideoLibraries: StockVideoLibraryNodeConnection;
  subscriptions: SubscriptionRelayNodeConnection;
  summaryUrl?: Maybe<Scalars['String']['output']>;
  /** Describes any custom targeting requests made by the account. */
  targetingNotes: Scalars['String']['output'];
  totalVideosCompleted?: Maybe<Scalars['Int']['output']>;
  totalVideosCreated?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The account that approved the user video. */
  userVideoPurchaseApprovals: UserVideoPurchaseApprovalNodeConnection;
  userVideos?: Maybe<UserVideoNodeConnection>;
  videoLibraries: VideoAssetLibraryNodeConnection;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeAudioAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeAutofillResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAgeDays?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  requestGuid?: InputMaybe<Scalars['String']['input']>;
  requestSource?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeBusinessesArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeColorLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeDraftVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeFontLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeGeneratedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeImageLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodePurchasedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeRenderedVideoCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeServiceActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeStockVideoLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeUserVideoPurchaseApprovalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeUserVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeVideoLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Account schema for a Relay implementation of Account. Graphene's filtering and sorting
 * requires the use of Relay.
 */
export type AccountRelayNodeVisitCountArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountRelayNodeConnection = {
  __typename: 'AccountRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AccountRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `AccountRelayNode` and its cursor. */
export type AccountRelayNodeEdge = {
  __typename: 'AccountRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AccountRelayNode>;
};

/** Object representing account usage for CS reports. */
export type AccountUsage = {
  __typename: 'AccountUsage';
  accountGroupName?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  draftVideoCount?: Maybe<Scalars['Int']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  generatedVideoCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  purchasedVideoCount?: Maybe<Scalars['Int']['output']>;
  renderedVideoCount?: Maybe<Scalars['Int']['output']>;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

/** Object representing a video for CS reports. */
export type AccountVideoDetail = {
  __typename: 'AccountVideoDetail';
  accountDisplayName?: Maybe<Scalars['String']['output']>;
  accountEmailAddress?: Maybe<Scalars['String']['output']>;
  accountId?: Maybe<Scalars['Int']['output']>;
  aspectRatio?: Maybe<Scalars['String']['output']>;
  brandName?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  licensing?: Maybe<Scalars['String']['output']>;
  previewLink?: Maybe<Scalars['String']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
  videoCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  videoGuid?: Maybe<Scalars['String']['output']>;
  videoPurchasedAt?: Maybe<Scalars['DateTime']['output']>;
  videoTitle?: Maybe<Scalars['String']['output']>;
};

export type AddAccountCreditsMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditCount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

/** Account schema mutation to implement adding credits to an account. */
export type AddAccountCreditsMutationPayload = {
  __typename: 'AddAccountCreditsMutationPayload';
  account?: Maybe<AccountRelayNode>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type AddFontToFontLibraryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  fontBfsUuid: Scalars['String']['input'];
  fontLibraryGuid: Scalars['String']['input'];
};

/** Mutation takes a font and adds it directly to a given FontLibrary. */
export type AddFontToFontLibraryMutationPayload = {
  __typename: 'AddFontToFontLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdFontLibraryFont?: Maybe<FontLibraryFontNode>;
  updatedFontLibrary?: Maybe<FontLibraryNode>;
};

export type AddImageToImageLibraryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  imageFileName: Scalars['String']['input'];
  imageHeight: Scalars['Int']['input'];
  imageLibrarySlug: Scalars['String']['input'];
  imageType: Scalars['String']['input'];
  imageWidth: Scalars['Int']['input'];
  source: Scalars['String']['input'];
  stockAssetId?: InputMaybe<Scalars['String']['input']>;
  stockSearchId?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation takes data for an uploaded or stock image and adds it to a given ImageLibrary. */
export type AddImageToImageLibraryMutationPayload = {
  __typename: 'AddImageToImageLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdImageLibraryImage?: Maybe<ImageLibraryImageNode>;
  updatedImageLibrary?: Maybe<ImageLibraryNode>;
};

export type AddStockVideoToLibraryMutationInput = {
  accountGuid?: InputMaybe<Scalars['String']['input']>;
  businessGuid?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<InputMaybe<ShutterstockCategoryType>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  length?: InputMaybe<Scalars['Int']['input']>;
  sourceAssetId?: InputMaybe<Scalars['String']['input']>;
  sourceSearchId?: InputMaybe<Scalars['String']['input']>;
  stockSource?: InputMaybe<Scalars['String']['input']>;
  vpsKey?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Mutation adds a stock video asset to the asset library for an account or business.
 *
 * mutation {
 *     addStockVideoToLibrary(input: {
 *         "accountGuid": "abcd-1234",
 *         "stockSource": "shutterstock",
 *         "sourceAssetId": "1234-5135",
 *         "sourceSearchId": "0125-12512",
 *         "width": 1920,
 *         "height": 1080,
 *         "length": 15,
 *      }) {
 *         createdStockVideoLibraryVideo {
 *             ...
 *         }
 *         licensedVideoUrl
 *     }
 * }
 */
export type AddStockVideoToLibraryMutationPayload = {
  __typename: 'AddStockVideoToLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdStockVideoLibrary?: Maybe<StockVideoLibraryNode>;
  createdStockVideoLibraryVideo?: Maybe<StockVideoLibraryVideoNode>;
  licensedVideoUrl?: Maybe<Scalars['String']['output']>;
};

export type AddUploadedImageToImageLibraryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  imageFileName: Scalars['String']['input'];
  imageHeight: Scalars['Int']['input'];
  imageLibrarySlug: Scalars['String']['input'];
  imageWidth: Scalars['Int']['input'];
};

/** Mutation takes data for an uploaded image and adds it to a given ImageLibrary. */
export type AddUploadedImageToImageLibraryMutationPayload = {
  __typename: 'AddUploadedImageToImageLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdImageLibraryImage?: Maybe<ImageLibraryImageNode>;
  updatedImageLibrary?: Maybe<ImageLibraryNode>;
};

export type ApproveUserVideoMutationInput = {
  approvedConfiguration: Scalars['JSONString']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  userVideoGuid: Scalars['String']['input'];
};

/** Mutation updates a UserVideoApproval request as approved for a UserVideo. */
export type ApproveUserVideoMutationPayload = {
  __typename: 'ApproveUserVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userVideo?: Maybe<UserVideoNode>;
};

export type ApproveUserVideoPurchaseMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userVideoGuid: Scalars['String']['input'];
};

/**
 * Mutation to approve the purchase of a UserVideo.
 *
 * mutation ApproveUserVideoPurchaseMutation($userVideoGuid: String!) {
 *     approveUserVideoPurchase(input: {
 *         userVideoGuid: $userVideoGuid,
 *     }) {
 *         userVideo {
 *             id
 *             guid
 *         }
 *     }
 * }
 */
export type ApproveUserVideoPurchaseMutationPayload = {
  __typename: 'ApproveUserVideoPurchaseMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userVideo?: Maybe<UserVideoNode>;
};

export type AutofillFeedbackChipCategoryNode = Node & {
  __typename: 'AutofillFeedbackChipCategoryNode';
  chips?: Maybe<Array<Maybe<AutofillFeedbackChipNode>>>;
  createdAt: Scalars['DateTime']['output'];
  feedbackChips: AutofillFeedbackChipNodeConnection;
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AutofillFeedbackChipCategoryNodeFeedbackChipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AutofillFeedbackChipNode = Node & {
  __typename: 'AutofillFeedbackChipNode';
  autofillFeedback: AutofillFeedbackNodeConnection;
  category: AutofillFeedbackChipCategoryNode;
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  suggestion: Scalars['String']['output'];
  suggestionTitle: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AutofillFeedbackChipNodeAutofillFeedbackArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AutofillFeedbackChipNodeConnection = {
  __typename: 'AutofillFeedbackChipNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AutofillFeedbackChipNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AutofillFeedbackChipNode` and its cursor. */
export type AutofillFeedbackChipNodeEdge = {
  __typename: 'AutofillFeedbackChipNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AutofillFeedbackChipNode>;
};

/** GraphQL Schema for AutofillFeedback */
export type AutofillFeedbackNode = Node & {
  __typename: 'AutofillFeedbackNode';
  autofillResult?: Maybe<UserVideoAutofillResultNode>;
  createdAt: Scalars['DateTime']['output'];
  feedbackChips: AutofillFeedbackChipNodeConnection;
  feedbackText?: Maybe<Scalars['String']['output']>;
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  rating: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** GraphQL Schema for AutofillFeedback */
export type AutofillFeedbackNodeFeedbackChipsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AutofillFeedbackNodeConnection = {
  __typename: 'AutofillFeedbackNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AutofillFeedbackNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AutofillFeedbackNode` and its cursor. */
export type AutofillFeedbackNodeEdge = {
  __typename: 'AutofillFeedbackNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<AutofillFeedbackNode>;
};

export type AvailableVideoTemplateVariantFilters = {
  __typename: 'AvailableVideoTemplateVariantFilters';
  displayDuration?: Maybe<Array<Scalars['Int']['output']>>;
};

/** Schema for font variant objects returned from the BFS API */
export type BfsFontVariant = {
  __typename: 'BFSFontVariant';
  isItalic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  previewImage: Scalars['String']['output'];
  resource: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  weightIndex: Scalars['Int']['output'];
};

/**
 * Provides queries of the BulkRenderJobType model, including exports of configured
 * variants.
 */
export type BulkRenderJobType = {
  __typename: 'BulkRenderJobType';
  bulkRenderVideos: Array<BulkRenderVideoType>;
  configurationMapping: Scalars['JSONString']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  destinationAccount?: Maybe<AccountRelayNode>;
  guid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  videoTemplateVariant: VideoTemplateVariantRelayNode;
};

/**
 * Provides queries of the BulkRenderVideoType model, including exports of configured
 * variants.
 */
export type BulkRenderVideoType = {
  __typename: 'BulkRenderVideoType';
  configuration: Scalars['JSONString']['output'];
  configuredVideo?: Maybe<ConfiguredVideoNode>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  job: BulkRenderJobType;
  rawData: Scalars['JSONString']['output'];
  renderId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNode = Node & {
  __typename: 'BusinessRelayNode';
  /** Scraped about section of the business. */
  about?: Maybe<Scalars['String']['output']>;
  account?: Maybe<AccountRelayNode>;
  autofillResults: UserVideoAutofillResultNodeConnection;
  businessAbout?: Maybe<Scalars['String']['output']>;
  businessName: Scalars['String']['output'];
  city: Scalars['String']['output'];
  colorLibraries: ColorLibraryNodeConnection;
  contactEmailAddress: Scalars['String']['output'];
  contactPhone: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Scraped description section of the business. */
  description?: Maybe<Scalars['String']['output']>;
  fontLibraries: FontLibraryNodeConnection;
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  imageLibraries: ImageLibraryNodeConnection;
  industry: IndustryRelayNode;
  industryName?: Maybe<Scalars['String']['output']>;
  /** If this business is the currently selected business in the editor for its account. */
  isSelected: Scalars['Boolean']['output'];
  /** Whether the business should be displayed in the business profiles UI. */
  isVisible: Scalars['Boolean']['output'];
  logoImage?: Maybe<ImageLibraryImageNode>;
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  stockVideoLibraries: StockVideoLibraryNodeConnection;
  streetAddress1: Scalars['String']['output'];
  streetAddress2: Scalars['String']['output'];
  streetAddress3: Scalars['String']['output'];
  totalImageCount?: Maybe<Scalars['Int']['output']>;
  totalVideoCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Business associated with this User Video, if any. */
  uservideoSet: UserVideoNodeConnection;
  videoLibraries: VideoAssetLibraryNodeConnection;
  videovoiceoverproductSet: VideoVoiceOverProductConnection;
  websiteUrl: Scalars['String']['output'];
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeAutofillResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAgeDays?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestGuid?: InputMaybe<Scalars['String']['input']>;
  requestSource?: InputMaybe<Scalars['String']['input']>;
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeColorLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeFontLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeImageLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeStockVideoLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeUservideoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeVideoLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for Businesses. */
export type BusinessRelayNodeVideovoiceoverproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type BusinessRelayNodeConnection = {
  __typename: 'BusinessRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BusinessRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `BusinessRelayNode` and its cursor. */
export type BusinessRelayNodeEdge = {
  __typename: 'BusinessRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BusinessRelayNode>;
};

/** Schema for ColorLibraryColor objects. */
export type ColorLibraryColorNode = Node & {
  __typename: 'ColorLibraryColorNode';
  colorLibrary: ColorLibraryNode;
  createdAt: Scalars['DateTime']['output'];
  /** Optional descriptive name for the color which will be displayed in the editor. If not provided, the editor will just show the hex code by default. */
  displayName?: Maybe<Scalars['String']['output']>;
  hexCode: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** For determining the order that colors should appear in the library. */
  order: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ColorLibraryColorNodeConnection = {
  __typename: 'ColorLibraryColorNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ColorLibraryColorNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ColorLibraryColorNode` and its cursor. */
export type ColorLibraryColorNodeEdge = {
  __typename: 'ColorLibraryColorNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ColorLibraryColorNode>;
};

/** Schema for ColorLibrary objects. */
export type ColorLibraryNode = Node & {
  __typename: 'ColorLibraryNode';
  accountGroups: AccountGroupRelayNodeConnection;
  accounts: AccountRelayNodeConnection;
  businesses: BusinessRelayNodeConnection;
  colors: ColorLibraryColorNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this collection of colors in the Waymark Editor. */
  displayName: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Schema for ColorLibrary objects. */
export type ColorLibraryNodeAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for ColorLibrary objects. */
export type ColorLibraryNodeAccountsArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for ColorLibrary objects. */
export type ColorLibraryNodeBusinessesArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for ColorLibrary objects. */
export type ColorLibraryNodeColorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ColorLibraryNodeConnection = {
  __typename: 'ColorLibraryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ColorLibraryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ColorLibraryNode` and its cursor. */
export type ColorLibraryNodeEdge = {
  __typename: 'ColorLibraryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ColorLibraryNode>;
};

/** Provides queries of the ConfiguredVideo model. */
export type ConfiguredVideoNode = Node & {
  __typename: 'ConfiguredVideoNode';
  bulkrendervideoSet: Array<BulkRenderVideoType>;
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  renderStatus?: Maybe<Scalars['String']['output']>;
  renderedVideos: RenderedVideoNodeConnection;
  thumbnail?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userVideos: UserVideoNodeConnection;
  uservideoSet: UserVideoNodeConnection;
  videoConfiguration: Scalars['JSONString']['output'];
  videoTemplateVariant: VideoTemplateVariantRelayNode;
  videotemplatevariantSet: VideoTemplateVariantRelayNodeConnection;
};

/** Provides queries of the ConfiguredVideo model. */
export type ConfiguredVideoNodeRenderedVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the ConfiguredVideo model. */
export type ConfiguredVideoNodeUserVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/** Provides queries of the ConfiguredVideo model. */
export type ConfiguredVideoNodeUservideoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/** Provides queries of the ConfiguredVideo model. */
export type ConfiguredVideoNodeVideotemplatevariantSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  aspectRatio_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  displayDuration_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupVariant?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupVariant_VariantGroup_Slug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensing?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  videoTemplate?: InputMaybe<Scalars['ID']['input']>;
  videoTemplate_HasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  videoTemplate_ImageAssetCount_Gte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_ImageAssetCount_Lte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_Name?: InputMaybe<Scalars['String']['input']>;
  videoTemplate_Slug?: InputMaybe<Scalars['String']['input']>;
};

export type ConfiguredVideoNodeConnection = {
  __typename: 'ConfiguredVideoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ConfiguredVideoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ConfiguredVideoNode` and its cursor. */
export type ConfiguredVideoNodeEdge = {
  __typename: 'ConfiguredVideoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ConfiguredVideoNode>;
};

export type CreateAccountMutationInput = {
  accountGroupGuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  partnerGuid?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  temporaryPassword?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation creates an account. Usage looks like:
 *
 * mutation {
 *     createAccount(input: { emailAddress:"example@waymark.com", temporaryPassword: "abc123" }) {
 *         createdAccount{
 *             guid
 *         }
 *     }
 * }
 */
export type CreateAccountMutationPayload = {
  __typename: 'CreateAccountMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdAccount?: Maybe<AccountRelayNode>;
};

export type CreateAccountVideoLibraryVideoMutationInput = {
  accountGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  uploadKey: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

/** VideoAssetLibraryVideo schema mutation to create new records associated with an Account. */
export type CreateAccountVideoLibraryVideoMutationPayload = {
  __typename: 'CreateAccountVideoLibraryVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  videoLibraryVideo?: Maybe<VideoAssetLibraryVideoNode>;
};

export type CreateAutofillFeedbackMutationInput = {
  autofillRequestGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackChipGuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedbackText?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['String']['input'];
};

/** Mutation creates a new AutofillFeedback record. */
export type CreateAutofillFeedbackMutationPayload = {
  __typename: 'CreateAutofillFeedbackMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdAutofillFeedback?: Maybe<AutofillFeedbackNode>;
};

export type CreateBusinessMutationInput = {
  accountGuid: Scalars['String']['input'];
  businessAbout?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  industryName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation creates a new Business record. Initial default values can also optionally be provided.
 * Usage looks like:
 *
 * mutation {
 *     createBusiness(input: {
 *         "accountGuid": "abcd-1234",
 *         "businessName": "The Business Name",
 *     }) {
 *         createdBusiness {
 *             guid
 *             businessName
 *         }
 *     }
 * }
 */
export type CreateBusinessMutationPayload = {
  __typename: 'CreateBusinessMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdBusiness?: Maybe<BusinessRelayNode>;
};

export type CreateBusinessVideoLibraryVideoMutationInput = {
  businessGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  uploadKey: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

/** VideoAssetLibraryVideo schema mutation to create new records associated with a Business. */
export type CreateBusinessVideoLibraryVideoMutationPayload = {
  __typename: 'CreateBusinessVideoLibraryVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  videoLibraryVideo?: Maybe<VideoAssetLibraryVideoNode>;
};

export type CreateImageLibraryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

/**
 * Image library schema mutation to create new ImageLibrary records.
 *
 * This mutation is only used in the Waymark Admin.
 * TODO: Once image libraries can be created via the Waymark App, it should be moved to
 * api/libraries/image.py and secured by account role and permissions.
 */
export type CreateImageLibraryMutationPayload = {
  __typename: 'CreateImageLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  imageLibrary?: Maybe<ImageLibraryNode>;
};

export type CreateUserVideoApprovalRequestMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userVideoGuid: Scalars['String']['input'];
};

/** Mutation creates a UserVideoApproval request for a UserVideo. */
export type CreateUserVideoApprovalRequestMutationPayload = {
  __typename: 'CreateUserVideoApprovalRequestMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userVideo?: Maybe<UserVideoNode>;
};

export type CreateUserVideoFeedbackMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackText: Scalars['String']['input'];
  rating: Scalars['String']['input'];
  timeToCreate?: InputMaybe<Scalars['String']['input']>;
  userVideoGuid: Scalars['String']['input'];
};

/** Mutation creates a new UserVideoFeedback record. */
export type CreateUserVideoFeedbackMutationPayload = {
  __typename: 'CreateUserVideoFeedbackMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdUserVideoFeedback?: Maybe<UserVideoFeedbackNode>;
  updatedUserVideo?: Maybe<UserVideoNode>;
};

export type CreateUserVideoMutationInput = {
  autofillRequestGuid?: InputMaybe<Scalars['String']['input']>;
  businessGuid?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copyUserVideoGuid?: InputMaybe<Scalars['String']['input']>;
  originVariantGroupSlug?: InputMaybe<Scalars['String']['input']>;
  stockVideoAssetVpsKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  variantGuid?: InputMaybe<Scalars['String']['input']>;
  videoConfiguration?: InputMaybe<Scalars['JSONString']['input']>;
  voiceOverSpeakerGuid?: InputMaybe<Scalars['String']['input']>;
  voiceOverText?: InputMaybe<Scalars['String']['input']>;
  voiceOverTiming?: InputMaybe<Scalars['JSONString']['input']>;
};

/** Mutation creates a new UserVideo record. */
export type CreateUserVideoMutationPayload = {
  __typename: 'CreateUserVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userVideo?: Maybe<UserVideoNode>;
};

export type DeleteBusinessMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation deletes a given Business record by guid. Usage looks like:
 *
 * mutation {
 *     deleteBusiness(input: { guid: "abcd-1234" }) {
 *         deletedBusinessId
 *     }
 * }
 */
export type DeleteBusinessMutationPayload = {
  __typename: 'DeleteBusinessMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedBusinessId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteColorLibraryColorMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  hexCode: Scalars['String']['input'];
  libraryGuid: Scalars['String']['input'];
};

/** Mutation deletes a color from the color library. */
export type DeleteColorLibraryColorMutationPayload = {
  __typename: 'DeleteColorLibraryColorMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  removedColorId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteImageLibraryImageMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/**
 * Image library image schema mutation to delete an ImageLibraryImage record.
 *
 * This mutation is only used in the Waymark Admin.
 * TODO: Once image libraries can be created via the Waymark App, it should be moved to
 * api/libraries/image.py and secured by account role and permissions.
 */
export type DeleteImageLibraryImageMutationPayload = {
  __typename: 'DeleteImageLibraryImageMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteUserVideoMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid: Scalars['String']['input'];
};

/**
 * Mutation marks a UserVideo record as deleted so it won't show up in the user's drafts anymore.
 * Note that this doesn't actually delete the record from the database, it just sets a
 * `has_been_deleted_by_user` flag to True.
 */
export type DeleteUserVideoMutationPayload = {
  __typename: 'DeleteUserVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deletedUserVideo?: Maybe<UserVideoNode>;
};

/** GraphQL schema for EnhancedImageLibraryImage. */
export type EnhancedImageLibraryImageNode = Node & {
  __typename: 'EnhancedImageLibraryImageNode';
  baseUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  imageHeight?: Maybe<Scalars['Int']['output']>;
  imageLibraryImage: ImageLibraryImageNode;
  imageWidth?: Maybe<Scalars['Int']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EnhancedImageLibraryImageNodeConnection = {
  __typename: 'EnhancedImageLibraryImageNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EnhancedImageLibraryImageNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EnhancedImageLibraryImageNode` and its cursor. */
export type EnhancedImageLibraryImageNodeEdge = {
  __typename: 'EnhancedImageLibraryImageNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<EnhancedImageLibraryImageNode>;
};

/** Schema for FontLibraryFont objects. */
export type FontLibraryFontNode = Node & {
  __typename: 'FontLibraryFontNode';
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this font in the Waymark Editor. */
  displayName: Scalars['String']['output'];
  font: TemplateFontNode;
  fontLibrary: FontLibraryNode;
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** For determining the order that fonts should appear in the library. */
  order?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FontLibraryFontNodeConnection = {
  __typename: 'FontLibraryFontNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FontLibraryFontNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FontLibraryFontNode` and its cursor. */
export type FontLibraryFontNodeEdge = {
  __typename: 'FontLibraryFontNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FontLibraryFontNode>;
};

/** Schema for FontLibrary objects. */
export type FontLibraryNode = Node & {
  __typename: 'FontLibraryNode';
  accountGroups: AccountGroupRelayNodeConnection;
  accounts: AccountRelayNodeConnection;
  businesses: BusinessRelayNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this collection of images in the Waymark editor. */
  displayName: Scalars['String']['output'];
  fonts: FontLibraryFontNodeConnection;
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  templatefontSet: TemplateFontNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
};

/** Schema for FontLibrary objects. */
export type FontLibraryNodeAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for FontLibrary objects. */
export type FontLibraryNodeAccountsArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for FontLibrary objects. */
export type FontLibraryNodeBusinessesArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for FontLibrary objects. */
export type FontLibraryNodeFontsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for FontLibrary objects. */
export type FontLibraryNodeTemplatefontSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type FontLibraryNodeConnection = {
  __typename: 'FontLibraryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FontLibraryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FontLibraryNode` and its cursor. */
export type FontLibraryNodeEdge = {
  __typename: 'FontLibraryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<FontLibraryNode>;
};

export type GetOrCreateAccountColorLibraryMutationInput = {
  accountGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation that gets or creates a default AccountColorLibrary for a given account */
export type GetOrCreateAccountColorLibraryMutationPayload = {
  __typename: 'GetOrCreateAccountColorLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  colorLibrary?: Maybe<ColorLibraryNode>;
  didCreate?: Maybe<Scalars['Boolean']['output']>;
};

export type GetOrCreateBusinessColorLibraryMutationInput = {
  businessGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation that gets or creates a default BusinessColorLibrary for a given business */
export type GetOrCreateBusinessColorLibraryMutationPayload = {
  __typename: 'GetOrCreateBusinessColorLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  colorLibrary?: Maybe<ColorLibraryNode>;
  didCreate?: Maybe<Scalars['Boolean']['output']>;
};

export type GetOrCreateDefaultAccountFontLibraryMutationInput = {
  accountGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation gets or creates the default AccountFontLibrary for a given account. */
export type GetOrCreateDefaultAccountFontLibraryMutationPayload = {
  __typename: 'GetOrCreateDefaultAccountFontLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fontLibrary?: Maybe<FontLibraryNode>;
};

export type GetOrCreateDefaultAccountImageLibraryMutationInput = {
  accountGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation gets or creates the default AccountImageLibrary for a given account. */
export type GetOrCreateDefaultAccountImageLibraryMutationPayload = {
  __typename: 'GetOrCreateDefaultAccountImageLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  imageLibrary?: Maybe<ImageLibraryNode>;
};

export type GetOrCreateDefaultBusinessFontLibraryMutationInput = {
  businessGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation gets or creates the default BusinessFontLibrary for a given account. */
export type GetOrCreateDefaultBusinessFontLibraryMutationPayload = {
  __typename: 'GetOrCreateDefaultBusinessFontLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fontLibrary?: Maybe<FontLibraryNode>;
};

export type GetOrCreateDefaultBusinessImageLibraryMutationInput = {
  businessGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation gets or creates the default BusinessImageLibrary for a given business. */
export type GetOrCreateDefaultBusinessImageLibraryMutationPayload = {
  __typename: 'GetOrCreateDefaultBusinessImageLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  imageLibrary?: Maybe<ImageLibraryNode>;
};

/** GraphQL schema for ImageLibraryImage. */
export type ImageLibraryImageNode = Node & {
  __typename: 'ImageLibraryImageNode';
  baseUrl?: Maybe<Scalars['String']['output']>;
  businessSet: BusinessRelayNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this image in the editor. */
  displayName?: Maybe<Scalars['String']['output']>;
  enhancedImages: EnhancedImageLibraryImageNodeConnection;
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  imageHeight?: Maybe<Scalars['Int']['output']>;
  imageLibrary: ImageLibraryNode;
  imageType?: Maybe<Scalars['String']['output']>;
  imageWidth?: Maybe<Scalars['Int']['output']>;
  isFavorite: Scalars['Boolean']['output'];
  /** The last time the image was used in a video */
  lastUsedAt?: Maybe<Scalars['DateTime']['output']>;
  /** For determining the order that images should appear in the library. */
  order: Scalars['Int']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  /** The datetime this image was deleted from a user's library. */
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  /** Search origin URL, only populated for images created by a business search. */
  sourceUrl?: Maybe<Scalars['String']['output']>;
  /** The image asset's stock asset service identifier. */
  stockAssetId?: Maybe<Scalars['String']['output']>;
  /** The ID of the search used to find this image via Shutterstock. */
  stockSearchId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  upscaledImageUrl?: Maybe<Scalars['String']['output']>;
};

/** GraphQL schema for ImageLibraryImage. */
export type ImageLibraryImageNodeBusinessSetArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for ImageLibraryImage. */
export type ImageLibraryImageNodeEnhancedImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageLibraryImageNodeConnection = {
  __typename: 'ImageLibraryImageNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ImageLibraryImageNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ImageLibraryImageNode` and its cursor. */
export type ImageLibraryImageNodeEdge = {
  __typename: 'ImageLibraryImageNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ImageLibraryImageNode>;
};

/** GraphQL schema for ImageLibrary. */
export type ImageLibraryNode = Node & {
  __typename: 'ImageLibraryNode';
  accountGroups: AccountGroupRelayNodeConnection;
  accounts: AccountRelayNodeConnection;
  businesses: BusinessRelayNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this collection of images in the Waymark editor. */
  displayName: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  images?: Maybe<ImageLibraryImageNodeConnection>;
  pk?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** GraphQL schema for ImageLibrary. */
export type ImageLibraryNodeAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for ImageLibrary. */
export type ImageLibraryNodeAccountsArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for ImageLibrary. */
export type ImageLibraryNodeBusinessesArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for ImageLibrary. */
export type ImageLibraryNodeImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageLibraryNodeConnection = {
  __typename: 'ImageLibraryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ImageLibraryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ImageLibraryNode` and its cursor. */
export type ImageLibraryNodeEdge = {
  __typename: 'ImageLibraryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ImageLibraryNode>;
};

/** GraphQL schema for business Industries */
export type IndustryRelayNode = Node & {
  __typename: 'IndustryRelayNode';
  businessSet: BusinessRelayNodeConnection;
  children: IndustryRelayNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  keywords: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  lft: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<IndustryRelayNode>;
  rght: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  treeId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** GraphQL schema for business Industries */
export type IndustryRelayNodeBusinessSetArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for business Industries */
export type IndustryRelayNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type IndustryRelayNodeConnection = {
  __typename: 'IndustryRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IndustryRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `IndustryRelayNode` and its cursor. */
export type IndustryRelayNodeEdge = {
  __typename: 'IndustryRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IndustryRelayNode>;
};

/** GraphQL schema for LoginUsers. */
export type LoginUserType = Node & {
  __typename: 'LoginUserType';
  createdAt: Scalars['DateTime']['output'];
  dateJoined: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  /** The last login time of this Login User. */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  password: Scalars['String']['output'];
  serviceActivities: ServiceActivityRelayNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
  /** Required. Letters, numbers and @/./+/-/_ characters */
  username: Scalars['String']['output'];
};

/** GraphQL schema for LoginUsers. */
export type LoginUserTypeServiceActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MarkStockVideoAssetAsTranscodedMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  vpsKey?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation updates a stock asset to be marked as having been transcoded, meaning
 * it's been run through the VPS and is usable in the UI.
 *
 * mutation {
 *     markStockVideoAssetAsTranscoded(input: {
 *         "vpsKey": "abcd123",
 *      }) {
 *         updatedStockVideoAsset {
 *             id
 *             vpsKey
 *             ...
 *         }
 *     }
 * }
 */
export type MarkStockVideoAssetAsTranscodedMutationPayload = {
  __typename: 'MarkStockVideoAssetAsTranscodedMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedStockVideoAsset?: Maybe<StockVideoAssetNode>;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type Mutation = {
  __typename: 'Mutation';
  /** Account schema mutation to implement adding credits to an account. */
  addAccountCredits?: Maybe<AddAccountCreditsMutationPayload>;
  /** Mutation takes a font and adds it directly to a given FontLibrary. */
  addFontToFontLibrary?: Maybe<AddFontToFontLibraryMutationPayload>;
  /** Mutation takes data for an uploaded or stock image and adds it to a given ImageLibrary. */
  addImageToImageLibrary?: Maybe<AddImageToImageLibraryMutationPayload>;
  /**
   * Mutation adds a stock video asset to the asset library for an account or business.
   *
   * mutation {
   *     addStockVideoToLibrary(input: {
   *         "accountGuid": "abcd-1234",
   *         "stockSource": "shutterstock",
   *         "sourceAssetId": "1234-5135",
   *         "sourceSearchId": "0125-12512",
   *         "width": 1920,
   *         "height": 1080,
   *         "length": 15,
   *      }) {
   *         createdStockVideoLibraryVideo {
   *             ...
   *         }
   *         licensedVideoUrl
   *     }
   * }
   */
  addStockVideoToLibrary?: Maybe<AddStockVideoToLibraryMutationPayload>;
  /** Mutation takes data for an uploaded image and adds it to a given ImageLibrary. */
  addUploadedImageToImageLibrary?: Maybe<AddUploadedImageToImageLibraryMutationPayload>;
  /** Mutation updates a UserVideoApproval request as approved for a UserVideo. */
  approveUserVideo?: Maybe<ApproveUserVideoMutationPayload>;
  /**
   * Mutation to approve the purchase of a UserVideo.
   *
   * mutation ApproveUserVideoPurchaseMutation($userVideoGuid: String!) {
   *     approveUserVideoPurchase(input: {
   *         userVideoGuid: $userVideoGuid,
   *     }) {
   *         userVideo {
   *             id
   *             guid
   *         }
   *     }
   * }
   */
  approveUserVideoPurchase?: Maybe<ApproveUserVideoPurchaseMutationPayload>;
  /**
   * Mutation creates an account. Usage looks like:
   *
   * mutation {
   *     createAccount(input: { emailAddress:"example@waymark.com", temporaryPassword: "abc123" }) {
   *         createdAccount{
   *             guid
   *         }
   *     }
   * }
   */
  createAccount?: Maybe<CreateAccountMutationPayload>;
  /** VideoAssetLibraryVideo schema mutation to create new records associated with an Account. */
  createAccountVideoLibraryVideo?: Maybe<CreateAccountVideoLibraryVideoMutationPayload>;
  /** Mutation creates a new AutofillFeedback record. */
  createAutofillFeedback?: Maybe<CreateAutofillFeedbackMutationPayload>;
  /**
   * Mutation creates a new Business record. Initial default values can also optionally be provided.
   * Usage looks like:
   *
   * mutation {
   *     createBusiness(input: {
   *         "accountGuid": "abcd-1234",
   *         "businessName": "The Business Name",
   *     }) {
   *         createdBusiness {
   *             guid
   *             businessName
   *         }
   *     }
   * }
   */
  createBusiness?: Maybe<CreateBusinessMutationPayload>;
  /** VideoAssetLibraryVideo schema mutation to create new records associated with a Business. */
  createBusinessVideoLibraryVideo?: Maybe<CreateBusinessVideoLibraryVideoMutationPayload>;
  /**
   * Image library schema mutation to create new ImageLibrary records.
   *
   * This mutation is only used in the Waymark Admin.
   * TODO: Once image libraries can be created via the Waymark App, it should be moved to
   * api/libraries/image.py and secured by account role and permissions.
   */
  createImageLibrary?: Maybe<CreateImageLibraryMutationPayload>;
  /** Mutation creates a new UserVideo record. */
  createUserVideo?: Maybe<CreateUserVideoMutationPayload>;
  /** Mutation creates a UserVideoApproval request for a UserVideo. */
  createUserVideoApprovalRequest?: Maybe<CreateUserVideoApprovalRequestMutationPayload>;
  /** Mutation creates a new UserVideoFeedback record. */
  createUserVideoFeedback?: Maybe<CreateUserVideoFeedbackMutationPayload>;
  /**
   * Mutation deletes a given Business record by guid. Usage looks like:
   *
   * mutation {
   *     deleteBusiness(input: { guid: "abcd-1234" }) {
   *         deletedBusinessId
   *     }
   * }
   */
  deleteBusiness?: Maybe<DeleteBusinessMutationPayload>;
  /** Mutation deletes a color from the color library. */
  deleteColorLibraryColor?: Maybe<DeleteColorLibraryColorMutationPayload>;
  /**
   * Image library image schema mutation to delete an ImageLibraryImage record.
   *
   * This mutation is only used in the Waymark Admin.
   * TODO: Once image libraries can be created via the Waymark App, it should be moved to
   * api/libraries/image.py and secured by account role and permissions.
   */
  deleteImageLibraryImage?: Maybe<DeleteImageLibraryImageMutationPayload>;
  /**
   * Mutation marks a UserVideo record as deleted so it won't show up in the user's drafts anymore.
   * Note that this doesn't actually delete the record from the database, it just sets a
   * `has_been_deleted_by_user` flag to True.
   */
  deleteUserVideo?: Maybe<DeleteUserVideoMutationPayload>;
  /** Mutation that gets or creates a default AccountColorLibrary for a given account */
  getOrCreateAccountColorLibrary?: Maybe<GetOrCreateAccountColorLibraryMutationPayload>;
  /** Mutation that gets or creates a default BusinessColorLibrary for a given business */
  getOrCreateBusinessColorLibrary?: Maybe<GetOrCreateBusinessColorLibraryMutationPayload>;
  /** Mutation gets or creates the default AccountFontLibrary for a given account. */
  getOrCreateDefaultAccountFontLibrary?: Maybe<GetOrCreateDefaultAccountFontLibraryMutationPayload>;
  /** Mutation gets or creates the default AccountImageLibrary for a given account. */
  getOrCreateDefaultAccountImageLibrary?: Maybe<GetOrCreateDefaultAccountImageLibraryMutationPayload>;
  /** Mutation gets or creates the default BusinessFontLibrary for a given account. */
  getOrCreateDefaultBusinessFontLibrary?: Maybe<GetOrCreateDefaultBusinessFontLibraryMutationPayload>;
  /** Mutation gets or creates the default BusinessImageLibrary for a given business. */
  getOrCreateDefaultBusinessImageLibrary?: Maybe<GetOrCreateDefaultBusinessImageLibraryMutationPayload>;
  /**
   * Mutation updates a stock asset to be marked as having been transcoded, meaning
   * it's been run through the VPS and is usable in the UI.
   *
   * mutation {
   *     markStockVideoAssetAsTranscoded(input: {
   *         "vpsKey": "abcd123",
   *      }) {
   *         updatedStockVideoAsset {
   *             id
   *             vpsKey
   *             ...
   *         }
   *     }
   * }
   */
  markStockVideoAssetAsTranscoded?: Maybe<MarkStockVideoAssetAsTranscodedMutationPayload>;
  /** Mutation takes a font and removes if from a font library */
  removeFontFromFontLibrary?: Maybe<RemoveFontFromFontLibraryMutationPayload>;
  /**
   * Mutation adds a stock video asset to the asset library for an account or business.
   *
   * mutation {
   *     removeStockVideoFromLibrary(input: {
   *         "stock_video_library_video_guid": "abcd-1234",
   *      }) {
   *         updatedStockVideoLibraryVideo {
   *             id
   *             removedAt
   *         }
   *     }
   * }
   */
  removeStockVideoFromLibrary?: Maybe<RemoveStockVideoFromLibraryMutationPayload>;
  /** Mutation kicks off new render(s) for a UserVideo. */
  renderUserVideo?: Maybe<RenderUserVideoMutationPayload>;
  /**
   * Mutation adds a stock video to the library for an account or business.
   *
   * mutation {
   *     restoreRemovedStockVideoToLibrary(input: {
   *         "stock_video_library_video_guid": "abcd-1234",
   *      }) {
   *         updatedStockVideoLibraryVideo {
   *             id
   *             removedAt
   *         }
   *     }
   * }
   */
  restoreRemovedStockVideoToLibrary?: Maybe<RestoreRemovedStockVideoToLibraryMutationPayload>;
  /**
   * Mutation updates fields on a list of accounts by guid. Usage looks like:
   *
   * mutation {
   *     updateAccount(input: { guids: ["abc-123", "asdf-3455", "fake-guid"], firstName: "John", lastName: "Smith" }) {
   *         updatedAccounts{
   *             guid
   *             firstName
   *             lastName
   *         }
   *     }
   * }
   */
  updateAccount?: Maybe<UpdateAccountMutationPayload>;
  /** Mutation updates fields on a AutofillFeedback record. */
  updateAutofillFeedback?: Maybe<UpdateAutofillFeedbackMutationPayload>;
  /**
   * Mutation updates fields on a UserVideoAutofillResult record.
   *
   * This mutation is currently only used to manually update the video_descriptor field to
   * reflect some manual configuration modificactions that we make on the frontend after
   * an autofill comes back, but eventually that should not be necessary. In that event,
   * it's hard to imagine any other reasons why we'd want to update an autofill result like this,
   * so we can probably remove this mutation entirely then.
   */
  updateAutofillResult?: Maybe<UpdateAutofillResultMutationPayload>;
  /**
   * Mutation updates fields on a list of accounts by guid. Usage looks like:
   *
   * mutation {
   *     updateBusiness(input: {
   *         "guid": "abcd-1234",
   *         "businessName": "New Business Name",
   *      }) {
   *         updatedBusiness {
   *             guid
   *             businessName
   *         }
   *     }
   * }
   */
  updateBusiness?: Maybe<UpdateBusinessMutationPayload>;
  /** Mutation updates fields on an ImageLibraryImage record. */
  updateImageLibraryImage?: Maybe<UpdateImageLibraryImageMutationPayload>;
  /** Mutation updates the last_used_at field on an ImageLibraryImage record. */
  updateImageLibraryImageLastUsedAt?: Maybe<UpdateImageLibraryImageLastUsedAtMutationPayload>;
  /** Mutation that upserts a color in a color library. */
  updateOrCreateColorLibraryColor?: Maybe<UpdateOrCreateColorLibraryColorMutationPayload>;
  /** Mutation updates fields on a UserVideo record. */
  updateUserVideo?: Maybe<UpdateUserVideoMutationPayload>;
  /** Mutation updates fields on an VideoAssetLibraryVideo record. */
  updateVideoLibraryVideo?: Maybe<UpdateVideoLibraryVideoMutationPayload>;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationAddAccountCreditsArgs = {
  input: AddAccountCreditsMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationAddFontToFontLibraryArgs = {
  input: AddFontToFontLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationAddImageToImageLibraryArgs = {
  input: AddImageToImageLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationAddStockVideoToLibraryArgs = {
  input: AddStockVideoToLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationAddUploadedImageToImageLibraryArgs = {
  input: AddUploadedImageToImageLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationApproveUserVideoArgs = {
  input: ApproveUserVideoMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationApproveUserVideoPurchaseArgs = {
  input: ApproveUserVideoPurchaseMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateAccountArgs = {
  input: CreateAccountMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateAccountVideoLibraryVideoArgs = {
  input: CreateAccountVideoLibraryVideoMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateAutofillFeedbackArgs = {
  input: CreateAutofillFeedbackMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateBusinessArgs = {
  input: CreateBusinessMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateBusinessVideoLibraryVideoArgs = {
  input: CreateBusinessVideoLibraryVideoMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateImageLibraryArgs = {
  input: CreateImageLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateUserVideoArgs = {
  input: CreateUserVideoMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateUserVideoApprovalRequestArgs = {
  input: CreateUserVideoApprovalRequestMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationCreateUserVideoFeedbackArgs = {
  input: CreateUserVideoFeedbackMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationDeleteBusinessArgs = {
  input: DeleteBusinessMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationDeleteColorLibraryColorArgs = {
  input: DeleteColorLibraryColorMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationDeleteImageLibraryImageArgs = {
  input: DeleteImageLibraryImageMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationDeleteUserVideoArgs = {
  input: DeleteUserVideoMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationGetOrCreateAccountColorLibraryArgs = {
  input: GetOrCreateAccountColorLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationGetOrCreateBusinessColorLibraryArgs = {
  input: GetOrCreateBusinessColorLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationGetOrCreateDefaultAccountFontLibraryArgs = {
  input: GetOrCreateDefaultAccountFontLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationGetOrCreateDefaultAccountImageLibraryArgs = {
  input: GetOrCreateDefaultAccountImageLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationGetOrCreateDefaultBusinessFontLibraryArgs = {
  input: GetOrCreateDefaultBusinessFontLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationGetOrCreateDefaultBusinessImageLibraryArgs = {
  input: GetOrCreateDefaultBusinessImageLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationMarkStockVideoAssetAsTranscodedArgs = {
  input: MarkStockVideoAssetAsTranscodedMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationRemoveFontFromFontLibraryArgs = {
  input: RemoveFontFromFontLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationRemoveStockVideoFromLibraryArgs = {
  input: RemoveStockVideoFromLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationRenderUserVideoArgs = {
  input: RenderUserVideoMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationRestoreRemovedStockVideoToLibraryArgs = {
  input: RestoreRemovedStockVideoToLibraryMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateAccountArgs = {
  input: UpdateAccountMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateAutofillFeedbackArgs = {
  input: UpdateAutofillFeedbackMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateAutofillResultArgs = {
  input: UpdateAutofillResultMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateBusinessArgs = {
  input: UpdateBusinessMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateImageLibraryImageArgs = {
  input: UpdateImageLibraryImageMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateImageLibraryImageLastUsedAtArgs = {
  input: UpdateImageLibraryImageLastUsedAtMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateOrCreateColorLibraryColorArgs = {
  input: UpdateOrCreateColorLibraryColorMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateUserVideoArgs = {
  input: UpdateUserVideoMutationInput;
};

/** Top-level collection of all of the mutations defined in the various API directories. */
export type MutationUpdateVideoLibraryVideoArgs = {
  input: UpdateVideoLibraryVideoMutationInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

/**
 * Describes the dict that is returned by Account.get_offer_context as a Graphene Type.
 *
 * If/when we need the video download credit and subscription detail we can add a
 * graphene.List() for them.
 */
export type OfferContextType = {
  __typename: 'OfferContextType';
  hasConcierge?: Maybe<Scalars['Boolean']['output']>;
  hasUnlimited?: Maybe<Scalars['Boolean']['output']>;
  hasVideoApproval?: Maybe<Scalars['Boolean']['output']>;
  hasVoiceOver?: Maybe<Scalars['Boolean']['output']>;
  hasWhiteLabeling?: Maybe<Scalars['Boolean']['output']>;
  latestVideoDownloadSubscriptionValidThrough?: Maybe<Scalars['DateTime']['output']>;
  recurringMonthlyDownloads?: Maybe<Scalars['Int']['output']>;
  recurringRolloverDownloads?: Maybe<Scalars['Int']['output']>;
  totalRemainingDownloads?: Maybe<Scalars['Int']['output']>;
  totalRemainingNonrecurringDownloads?: Maybe<Scalars['Int']['output']>;
  totalRemainingRecurringDownloads?: Maybe<Scalars['Int']['output']>;
};

export type OfferType = {
  __typename: 'OfferType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** The name that we want customers to see on things like purchase receipts. */
  displayName?: Maybe<Scalars['String']['output']>;
  guid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Whether or not an offer is available to customers for purchase. */
  isActive: Scalars['Boolean']['output'];
  /** Whether or not an offer is complete and ready for purchase. 'True' denotes a launchable but not necessarily active offer. */
  isLive: Scalars['Boolean']['output'];
  /** Whether or not an offer is available to all customers. 'False' denotes an offer that requires specific AccountGroup identity. */
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** The Offer to apply to the linked Variant. */
  variantOffer: Array<VideoTemplateVariantOfferType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type Query = {
  __typename: 'Query';
  /** The ID of the object */
  account?: Maybe<AccountRelayNode>;
  accountAudioAssetForUploadKey?: Maybe<AccountAudioAssetNode>;
  accountByGuid?: Maybe<AccountRelayNode>;
  accountByPk?: Maybe<AccountRelayNode>;
  /** The ID of the object */
  accountGroup?: Maybe<AccountGroupRelayNode>;
  accountGroups?: Maybe<AccountGroupRelayNodeConnection>;
  accounts?: Maybe<AccountRelayNodeConnection>;
  authToken?: Maybe<Scalars['GenericScalar']['output']>;
  autofillFeedbackChipCategories?: Maybe<Array<Maybe<AutofillFeedbackChipCategoryNode>>>;
  autofillResultByRequestGuid?: Maybe<UserVideoAutofillResultNode>;
  bulkRenderJobs?: Maybe<Array<BulkRenderJobType>>;
  businessByGuid?: Maybe<BusinessRelayNode>;
  canPurchaseForAccount: Scalars['Boolean']['output'];
  fontLibraryByGuid?: Maybe<FontLibraryNode>;
  globalAudioLibraries?: Maybe<VideoTemplateGlobalAudioLibraryNodeConnection>;
  globalFontLibraries?: Maybe<FontLibraryNodeConnection>;
  imageLibraries?: Maybe<ImageLibraryNodeConnection>;
  imageLibrariesForAccount?: Maybe<ImageLibraryNodeConnection>;
  imageLibraryByPk?: Maybe<ImageLibraryNode>;
  imageLibraryBySlug?: Maybe<ImageLibraryNode>;
  imageLibraryImageByGuid?: Maybe<ImageLibraryImageNode>;
  imageLibraryImageByImageKey?: Maybe<ImageLibraryImageNode>;
  managedAccountGroups?: Maybe<Array<AccountGroupRelayNode>>;
  /** The ID of the object */
  partner?: Maybe<AccountPartnerRelayNode>;
  partnerByPk?: Maybe<AccountPartnerRelayNode>;
  partnerUsageByAccount?: Maybe<Array<Maybe<AccountUsage>>>;
  partnerUsageByAccountGroup?: Maybe<Array<Maybe<AccountGroupUsage>>>;
  partnerVideosByAccount?: Maybe<Array<Maybe<AccountVideoDetail>>>;
  partners?: Maybe<AccountPartnerRelayNodeConnection>;
  passwordValid: Scalars['Boolean']['output'];
  serviceActivities?: Maybe<ServiceActivityRelayNodeConnection>;
  serviceAddresses?: Maybe<ServiceAddressesType>;
  stockImagesForImageKeys?: Maybe<Array<ImageLibraryImageNode>>;
  takenEmails: Array<Scalars['String']['output']>;
  userVideoByGuid?: Maybe<UserVideoNode>;
  vibes?: Maybe<Array<Maybe<VibeNode>>>;
  videoLibraries?: Maybe<Array<VideoAssetLibraryNode>>;
  videoLibraryByPk?: Maybe<VideoAssetLibraryNode>;
  videoLibraryVideoByPk?: Maybe<VideoAssetLibraryVideoNode>;
  videoTemplateAudioForAi?: Maybe<VideoTemplateAudioNode>;
  videoTemplateAudioForVariantGuid?: Maybe<Array<Maybe<VideoTemplateAudioNode>>>;
  videoTemplateCollectionBySlug?: Maybe<VideoTemplateCollectionRelayNode>;
  videoTemplateCollections?: Maybe<Array<VideoTemplateCollectionRelayNode>>;
  videoTemplateVariantBySlug?: Maybe<VideoTemplateVariantRelayNode>;
  videoTemplateVariantForAi?: Maybe<VideoTemplateVariantRelayNode>;
  videoTemplateVariants?: Maybe<VideoTemplateVariantRelayNodeConnection>;
  videoTemplateVariantsByIds?: Maybe<Array<VideoTemplateVariantRelayNode>>;
  videoTemplates?: Maybe<Array<VideoTemplateNode>>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAccountArgs = {
  id: Scalars['ID']['input'];
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAccountAudioAssetForUploadKeyArgs = {
  uploadKey?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAccountByGuidArgs = {
  guid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAccountByPkArgs = {
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAccountGroupArgs = {
  id: Scalars['ID']['input'];
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAccountsArgs = {
  accountGroup?: InputMaybe<Scalars['ID']['input']>;
  accountGroups_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroups_DisplayName_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  accountGroups_Guid?: InputMaybe<Scalars['String']['input']>;
  accountGroups_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Istartswith?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Istartswith?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Istartswith?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Istartswith?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  partner_Guid?: InputMaybe<Scalars['String']['input']>;
  partner_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  phone_Startswith?: InputMaybe<Scalars['String']['input']>;
  pk?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  searchString?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryAutofillResultByRequestGuidArgs = {
  requestGuid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryBulkRenderJobsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryBusinessByGuidArgs = {
  guid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryCanPurchaseForAccountArgs = {
  accountGuid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryFontLibraryByGuidArgs = {
  guid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryGlobalAudioLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryGlobalFontLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryImageLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pk?: InputMaybe<Scalars['Int']['input']>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryImageLibrariesForAccountArgs = {
  accountGuid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryImageLibraryByPkArgs = {
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryImageLibraryBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryImageLibraryImageByGuidArgs = {
  guid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryImageLibraryImageByImageKeyArgs = {
  imageKey?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryManagedAccountGroupsArgs = {
  partnerGuid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryPartnerArgs = {
  id: Scalars['ID']['input'];
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryPartnerByPkArgs = {
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryPartnerUsageByAccountArgs = {
  accountGroupGUID?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryPartnerUsageByAccountGroupArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryPartnerVideosByAccountArgs = {
  accountGroupGUID?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  purchased?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryPasswordValidArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryServiceActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryStockImagesForImageKeysArgs = {
  imageKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryTakenEmailsArgs = {
  emailAddresses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryUserVideoByGuidArgs = {
  guid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVibesArgs = {
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoLibrariesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoLibraryByPkArgs = {
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoLibraryVideoByPkArgs = {
  pk?: InputMaybe<Scalars['Int']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplateAudioForAiArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  shouldIncludeInWaymarkAi?: InputMaybe<Scalars['Boolean']['input']>;
  vibe?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplateAudioForVariantGuidArgs = {
  variantGuid?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplateCollectionBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplateVariantBySlugArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplateVariantForAiArgs = {
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  businessGuid?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  hasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  recentVariants?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shouldEnforceDemoBehavior?: InputMaybe<Scalars['Boolean']['input']>;
  shouldIncludeInWaymarkAi?: InputMaybe<Scalars['Boolean']['input']>;
  vibe?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplateVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  aspectRatio_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  displayDuration_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupVariant?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupVariant_VariantGroup_Slug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensing?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pk?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  videoTemplate?: InputMaybe<Scalars['ID']['input']>;
  videoTemplate_HasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  videoTemplate_ImageAssetCount_Gte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_ImageAssetCount_Lte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_Name?: InputMaybe<Scalars['String']['input']>;
  videoTemplate_Slug?: InputMaybe<Scalars['String']['input']>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplateVariantsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Top-level collection of all of the queries defined in the various API directories. */
export type QueryVideoTemplatesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type RemoveFontFromFontLibraryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fontBfsUuid: Scalars['String']['input'];
  fontLibraryGuid: Scalars['String']['input'];
};

/** Mutation takes a font and removes if from a font library */
export type RemoveFontFromFontLibraryMutationPayload = {
  __typename: 'RemoveFontFromFontLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  removedFontLibraryFontId?: Maybe<Scalars['ID']['output']>;
};

export type RemoveStockVideoFromLibraryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stockVideoLibraryVideoGuid?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation adds a stock video asset to the asset library for an account or business.
 *
 * mutation {
 *     removeStockVideoFromLibrary(input: {
 *         "stock_video_library_video_guid": "abcd-1234",
 *      }) {
 *         updatedStockVideoLibraryVideo {
 *             id
 *             removedAt
 *         }
 *     }
 * }
 */
export type RemoveStockVideoFromLibraryMutationPayload = {
  __typename: 'RemoveStockVideoFromLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedStockVideoLibraryVideo?: Maybe<StockVideoLibraryVideoNode>;
};

export type RenderUserVideoMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid: Scalars['String']['input'];
  renderFormats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Mutation kicks off new render(s) for a UserVideo. */
export type RenderUserVideoMutationPayload = {
  __typename: 'RenderUserVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  userVideo?: Maybe<UserVideoNode>;
};

/** Provides queries of the RenderedVideo model. */
export type RenderedVideoNode = Node & {
  __typename: 'RenderedVideoNode';
  configuredVideo: ConfiguredVideoNode;
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  hasWatermark?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  render: Scalars['String']['output'];
  renderDuration?: Maybe<Scalars['Float']['output']>;
  renderFormat?: Maybe<Scalars['String']['output']>;
  renderHeight?: Maybe<Scalars['Int']['output']>;
  renderMimetype: Scalars['String']['output'];
  renderSize?: Maybe<Scalars['String']['output']>;
  renderStatus?: Maybe<Scalars['String']['output']>;
  renderUrl?: Maybe<Scalars['String']['output']>;
  renderWidth?: Maybe<Scalars['Int']['output']>;
  renderedAt?: Maybe<Scalars['String']['output']>;
  thumbnail: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RenderedVideoNodeConnection = {
  __typename: 'RenderedVideoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RenderedVideoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RenderedVideoNode` and its cursor. */
export type RenderedVideoNodeEdge = {
  __typename: 'RenderedVideoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RenderedVideoNode>;
};

export type RestoreRemovedStockVideoToLibraryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  stockVideoLibraryVideoGuid?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation adds a stock video to the library for an account or business.
 *
 * mutation {
 *     restoreRemovedStockVideoToLibrary(input: {
 *         "stock_video_library_video_guid": "abcd-1234",
 *      }) {
 *         updatedStockVideoLibraryVideo {
 *             id
 *             removedAt
 *         }
 *     }
 * }
 */
export type RestoreRemovedStockVideoToLibraryMutationPayload = {
  __typename: 'RestoreRemovedStockVideoToLibraryMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedStockVideoLibraryVideo?: Maybe<StockVideoLibraryVideoNode>;
};

/** GraphQL schema for ServiceActivities. */
export type ServiceActivityRelayNode = Node & {
  __typename: 'ServiceActivityRelayNode';
  account?: Maybe<AccountRelayNode>;
  /** Additional data specific to the service activity type. */
  activityData: Scalars['JSONString']['output'];
  activityType: ServiceActivityTypeRelayNode;
  adminUser?: Maybe<LoginUserType>;
  /** The date/time when the activity took place. */
  completedOn?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  objectId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ServiceActivityRelayNodeConnection = {
  __typename: 'ServiceActivityRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ServiceActivityRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ServiceActivityRelayNode` and its cursor. */
export type ServiceActivityRelayNodeEdge = {
  __typename: 'ServiceActivityRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ServiceActivityRelayNode>;
};

/** GraphQL schema for ServiceActivityTypes. */
export type ServiceActivityTypeRelayNode = Node & {
  __typename: 'ServiceActivityTypeRelayNode';
  /** The date/time we started recording this activity type. */
  beganLoggingAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isCallType: Scalars['Boolean']['output'];
  isSystemType: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  serviceActivities: ServiceActivityRelayNodeConnection;
  slug?: Maybe<ServiceActivityTypeSlug>;
  updatedAt: Scalars['DateTime']['output'];
};

/** GraphQL schema for ServiceActivityTypes. */
export type ServiceActivityTypeRelayNodeServiceActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An enumeration. */
export enum ServiceActivityTypeSlug {
  /** Cancellation Call */
  CancellationCall = 'CANCELLATION_CALL',
  /** delinquent-call */
  DelinquentCall = 'DELINQUENT_CALL',
  /** email */
  Email = 'EMAIL',
  /** Facebook Reply */
  FacebookReply = 'FACEBOOK_REPLY',
  /** kickoff-call */
  KickoffCall = 'KICKOFF_CALL',
  /** Live Chat */
  LiveChat = 'LIVE_CHAT',
  /** New Version */
  NewVersion = 'NEW_VERSION',
  /** note */
  Note = 'NOTE',
  /** olark */
  Olark = 'OLARK',
  /** phone */
  Phone = 'PHONE',
  /** unknown-backfill */
  UnknownBackfill = 'UNKNOWN_BACKFILL',
}

/** Describes the dict that defines each of the service endpoints needed by the API. */
export type ServiceAddressesType = {
  __typename: 'ServiceAddressesType';
  appSyncGraphqlEndpoint?: Maybe<Scalars['String']['output']>;
};

/** Object describing a Shutterstock API response category. */
export type ShutterstockCategoryType = {
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** GraphQL schema for StockVideoAsset */
export type StockVideoAssetNode = Node & {
  __typename: 'StockVideoAssetNode';
  /** Video asset category names and IDs, from the Shutterstock API. */
  categories?: Maybe<Scalars['JSONString']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Description of what's happening in the video clip, from the Shutterstock API. */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether or not the asset has been transcoded by the Video Processing Service. */
  hasTranscoded: Scalars['Boolean']['output'];
  /** Height (in pixels) of the video asset. */
  height?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** List of tags associated with the video content, from the Shutterstock API. */
  keywords?: Maybe<Scalars['JSONString']['output']>;
  /** Length (in seconds) of the video asset. */
  length?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  /** The video asset's source-provided identifier. */
  sourceAssetId: Scalars['String']['output'];
  stockVideoLibraryVideos: StockVideoLibraryVideoNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
  /** Waymark's Video Processing Service key. */
  vpsKey?: Maybe<Scalars['String']['output']>;
  /** Width (in pixels) of the video asset. */
  width?: Maybe<Scalars['Int']['output']>;
};

/** GraphQL schema for StockVideoAsset */
export type StockVideoAssetNodeStockVideoLibraryVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for StockVideoLibrary */
export type StockVideoLibraryNode = Node & {
  __typename: 'StockVideoLibraryNode';
  accounts: AccountRelayNodeConnection;
  businesses: BusinessRelayNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this collection of stock video assets in UIs. */
  displayName: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  stockVideoLibraryVideos: StockVideoLibraryVideoNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
};

/** GraphQL schema for StockVideoLibrary */
export type StockVideoLibraryNodeAccountsArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for StockVideoLibrary */
export type StockVideoLibraryNodeBusinessesArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** GraphQL schema for StockVideoLibrary */
export type StockVideoLibraryNodeStockVideoLibraryVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type StockVideoLibraryNodeConnection = {
  __typename: 'StockVideoLibraryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StockVideoLibraryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StockVideoLibraryNode` and its cursor. */
export type StockVideoLibraryNodeEdge = {
  __typename: 'StockVideoLibraryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<StockVideoLibraryNode>;
};

/** GraphQL schema for StockVideoLibraryVideo */
export type StockVideoLibraryVideoNode = Node & {
  __typename: 'StockVideoLibraryVideoNode';
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** The datetime that this stock video asset was deleted from its library. */
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  /** API search response ID. Required by Shutterstock. */
  sourceSearchId?: Maybe<Scalars['String']['output']>;
  stockAsset: StockVideoAssetNode;
  stockVideoLibrary: StockVideoLibraryNode;
  updatedAt: Scalars['DateTime']['output'];
};

export type StockVideoLibraryVideoNodeConnection = {
  __typename: 'StockVideoLibraryVideoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StockVideoLibraryVideoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StockVideoLibraryVideoNode` and its cursor. */
export type StockVideoLibraryVideoNodeEdge = {
  __typename: 'StockVideoLibraryVideoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<StockVideoLibraryVideoNode>;
};

/** An enumeration. */
export enum SubscriptionLastPaymentStatus {
  /** Failed */
  Failed = 'FAILED',
  /** None */
  None = 'NONE',
  /** Succeeded */
  Succeeded = 'SUCCEEDED',
}

/** GraphQL schema for SubscriptionPlan */
export type SubscriptionPlan = Node & {
  __typename: 'SubscriptionPlan';
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  interval: SubscriptionPlanInterval;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  subscriptionSet: SubscriptionRelayNodeConnection;
  subscriptionType?: Maybe<SubscriptionPlanSubscriptionType>;
  updatedAt: Scalars['DateTime']['output'];
};

/** GraphQL schema for SubscriptionPlan */
export type SubscriptionPlanSubscriptionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An enumeration. */
export enum SubscriptionPlanInterval {
  /** Day */
  Day = 'DAY',
  /** Month */
  Month = 'MONTH',
  /** Week */
  Week = 'WEEK',
  /** Year */
  Year = 'YEAR',
}

/** An enumeration. */
export enum SubscriptionPlanSubscriptionType {
  /** Facebook Ad */
  FacebookAd = 'FACEBOOK_AD',
  /** Lifetime Deal */
  LifetimeDeal = 'LIFETIME_DEAL',
  /** Premiere */
  Premiere = 'PREMIERE',
  /** Pro */
  Pro = 'PRO',
}

/** GraphQL schema for Subscription */
export type SubscriptionRelayNode = Node & {
  __typename: 'SubscriptionRelayNode';
  account: AccountRelayNode;
  cancelationDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  lastPaymentAmountDue?: Maybe<Scalars['Decimal']['output']>;
  lastPaymentAttempts?: Maybe<Scalars['Int']['output']>;
  lastPaymentStatus?: Maybe<SubscriptionLastPaymentStatus>;
  /** The date/time when the subscription became delinquent. Set automatically when an invoice for this subscription misses a payment. */
  lastPaymentStatusChangedAt?: Maybe<Scalars['DateTime']['output']>;
  nextPaymentAttemptAt?: Maybe<Scalars['DateTime']['output']>;
  paymentProviderSubscriptionId?: Maybe<Scalars['Int']['output']>;
  startedAt: Scalars['DateTime']['output'];
  status?: Maybe<Scalars['String']['output']>;
  subscriptionPlan: SubscriptionPlan;
  /** The time at which a trial period (if present) would end. None represents that there is no trial period. */
  trialPeriodEndsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionRelayNodeConnection = {
  __typename: 'SubscriptionRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SubscriptionRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SubscriptionRelayNode` and its cursor. */
export type SubscriptionRelayNodeEdge = {
  __typename: 'SubscriptionRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SubscriptionRelayNode>;
};

/** Schema for TemplateFont objects. */
export type TemplateFontNode = Node & {
  __typename: 'TemplateFontNode';
  bfsUuid?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fontFamilyName: Scalars['String']['output'];
  fontlibraryfontSet: FontLibraryFontNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  libraries: FontLibraryNodeConnection;
  /** Where the font is hosted. */
  source: TemplateFontSource;
  updatedAt: Scalars['DateTime']['output'];
  variants: Array<BfsFontVariant>;
};

/** Schema for TemplateFont objects. */
export type TemplateFontNodeFontlibraryfontSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema for TemplateFont objects. */
export type TemplateFontNodeLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TemplateFontNodeConnection = {
  __typename: 'TemplateFontNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TemplateFontNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TemplateFontNode` and its cursor. */
export type TemplateFontNodeEdge = {
  __typename: 'TemplateFontNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TemplateFontNode>;
};

/** An enumeration. */
export enum TemplateFontSource {
  /** Google fonts */
  Google = 'GOOGLE',
  /** Waymark fonts */
  WaymarkRenderer = 'WAYMARK_RENDERER',
}

export type UpdateAccountMutationInput = {
  addAccountGroupGuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  guids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  removeAccountGroupGuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  role?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation updates fields on a list of accounts by guid. Usage looks like:
 *
 * mutation {
 *     updateAccount(input: { guids: ["abc-123", "asdf-3455", "fake-guid"], firstName: "John", lastName: "Smith" }) {
 *         updatedAccounts{
 *             guid
 *             firstName
 *             lastName
 *         }
 *     }
 * }
 */
export type UpdateAccountMutationPayload = {
  __typename: 'UpdateAccountMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedAccounts?: Maybe<Array<AccountRelayNode>>;
};

export type UpdateAutofillFeedbackMutationInput = {
  autofillRequestGuid: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  feedbackChipGuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  feedbackText?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation updates fields on a AutofillFeedback record. */
export type UpdateAutofillFeedbackMutationPayload = {
  __typename: 'UpdateAutofillFeedbackMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedAutofillFeedback?: Maybe<AutofillFeedbackNode>;
};

export type UpdateAutofillResultMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  requestGuid: Scalars['String']['input'];
  videoDescriptorJson?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation updates fields on a UserVideoAutofillResult record.
 *
 * This mutation is currently only used to manually update the video_descriptor field to
 * reflect some manual configuration modificactions that we make on the frontend after
 * an autofill comes back, but eventually that should not be necessary. In that event,
 * it's hard to imagine any other reasons why we'd want to update an autofill result like this,
 * so we can probably remove this mutation entirely then.
 */
export type UpdateAutofillResultMutationPayload = {
  __typename: 'UpdateAutofillResultMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedAutofillResult?: Maybe<UserVideoAutofillResultNode>;
};

export type UpdateBusinessMutationInput = {
  businessAbout?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  industryName?: InputMaybe<Scalars['String']['input']>;
  industrySlug?: InputMaybe<Scalars['String']['input']>;
  logoImageGuid?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Mutation updates fields on a list of accounts by guid. Usage looks like:
 *
 * mutation {
 *     updateBusiness(input: {
 *         "guid": "abcd-1234",
 *         "businessName": "New Business Name",
 *      }) {
 *         updatedBusiness {
 *             guid
 *             businessName
 *         }
 *     }
 * }
 */
export type UpdateBusinessMutationPayload = {
  __typename: 'UpdateBusinessMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedBusiness?: Maybe<BusinessRelayNode>;
};

export type UpdateImageLibraryImageLastUsedAtMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
};

/** Mutation updates the last_used_at field on an ImageLibraryImage record. */
export type UpdateImageLibraryImageLastUsedAtMutationPayload = {
  __typename: 'UpdateImageLibraryImageLastUsedAtMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedImage?: Maybe<ImageLibraryImageNode>;
};

export type UpdateImageLibraryImageMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  shouldRemove?: InputMaybe<Scalars['Boolean']['input']>;
  shouldRestore?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Mutation updates fields on an ImageLibraryImage record. */
export type UpdateImageLibraryImageMutationPayload = {
  __typename: 'UpdateImageLibraryImageMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedImage?: Maybe<ImageLibraryImageNode>;
};

export type UpdateOrCreateColorLibraryColorMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  hexCode: Scalars['String']['input'];
  libraryGuid: Scalars['String']['input'];
  libraryName?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** Mutation that upserts a color in a color library. */
export type UpdateOrCreateColorLibraryColorMutationPayload = {
  __typename: 'UpdateOrCreateColorLibraryColorMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  color?: Maybe<ColorLibraryColorNode>;
  updatedColorLibrary?: Maybe<ColorLibraryNode>;
};

export type UpdateUserVideoMutationInput = {
  businessGuid?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  guid: Scalars['String']['input'];
  stockVideoAssetVpsKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  videoConfiguration?: InputMaybe<Scalars['JSONString']['input']>;
  voiceOverSpeakerGuid?: InputMaybe<Scalars['String']['input']>;
  voiceOverText?: InputMaybe<Scalars['String']['input']>;
  voiceOverTiming?: InputMaybe<Scalars['JSONString']['input']>;
};

/** Mutation updates fields on a UserVideo record. */
export type UpdateUserVideoMutationPayload = {
  __typename: 'UpdateUserVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedUserVideo?: Maybe<UserVideoNode>;
};

export type UpdateVideoLibraryVideoMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  shouldRemove?: InputMaybe<Scalars['Boolean']['input']>;
  shouldRestore?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Mutation updates fields on an VideoAssetLibraryVideo record. */
export type UpdateVideoLibraryVideoMutationPayload = {
  __typename: 'UpdateVideoLibraryVideoMutationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  updatedVideo?: Maybe<VideoAssetLibraryVideoNode>;
};

/** GraphQL Schema for UserVideoApprovals */
export type UserVideoApprovalNode = Node & {
  __typename: 'UserVideoApprovalNode';
  /** The datetime the user video was approved by a client */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  clientIpAddress?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fullName: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  lastApprovedConfiguration?: Maybe<Scalars['JSONString']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** User Video that this record is tracking the approval status of. */
  userVideo: UserVideoNode;
};

/** GraphQL Schema for UserVideoAutofillResults */
export type UserVideoAutofillResultNode = Node & {
  __typename: 'UserVideoAutofillResultNode';
  account?: Maybe<AccountRelayNode>;
  autofillFeedback: AutofillFeedbackNodeConnection;
  /** AWS Request ID for the Lambda function that generated this result. */
  awsRequestId?: Maybe<Scalars['String']['output']>;
  business?: Maybe<BusinessRelayNode>;
  /** The time the generated video response was received. */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Any error generated during video generation. */
  error?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isOmni: Scalars['Boolean']['output'];
  /** The browser has requested and received this result. Can be False if the push publish succeeded. */
  isRequestConfirmed: Scalars['Boolean']['output'];
  /** If the generation was created with the speaker locked */
  isSpeakerLocked: Scalars['Boolean']['output'];
  /** If the generation was created with the template locked */
  isTemplateLocked: Scalars['Boolean']['output'];
  /** If the generation was created with the voice over script locked */
  isVoiceOverLocked: Scalars['Boolean']['output'];
  languageCode?: Maybe<Scalars['String']['output']>;
  omniType?: Maybe<Scalars['String']['output']>;
  /** The original user video that was used to create this Omni video. */
  originalUserVideo?: Maybe<UserVideoNode>;
  pk?: Maybe<Scalars['Int']['output']>;
  /** Unique string sent in AutofillService requests to identify async responses. */
  requestGuid: Scalars['String']['output'];
  requestSource?: Maybe<Scalars['String']['output']>;
  /** Artifacts from the AutofillService strategy used in the request. */
  strategyRunRecord?: Maybe<Scalars['JSONString']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Any video generation instructions sent by the user */
  userInstructions?: Maybe<Scalars['String']['output']>;
  userVideo?: Maybe<UserVideoNode>;
  vibe?: Maybe<Scalars['String']['output']>;
  /** The video created by autofill. */
  videoDescriptor?: Maybe<Scalars['JSONString']['output']>;
  videoTemplateVariant?: Maybe<VideoTemplateVariantRelayNode>;
  /** The voiceover created by autofill, if any. */
  voiceover?: Maybe<Scalars['JSONString']['output']>;
  /** The voiceover scenes created by autofill, if any. Deprecated, use the voiceover field. */
  voiceoverScenes?: Maybe<Scalars['JSONString']['output']>;
  /** The voiceover script created by autofill, if any. */
  voiceoverText?: Maybe<Scalars['String']['output']>;
};

/** GraphQL Schema for UserVideoAutofillResults */
export type UserVideoAutofillResultNodeAutofillFeedbackArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserVideoAutofillResultNodeConnection = {
  __typename: 'UserVideoAutofillResultNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserVideoAutofillResultNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UserVideoAutofillResultNode` and its cursor. */
export type UserVideoAutofillResultNodeEdge = {
  __typename: 'UserVideoAutofillResultNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserVideoAutofillResultNode>;
};

/** GraphQL Schema for UserVideoFeedback */
export type UserVideoFeedbackNode = Node & {
  __typename: 'UserVideoFeedbackNode';
  createdAt: Scalars['DateTime']['output'];
  feedbackText: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  rating: Scalars['String']['output'];
  timeToCreate: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVideo?: Maybe<UserVideoNode>;
};

export type UserVideoFeedbackNodeConnection = {
  __typename: 'UserVideoFeedbackNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserVideoFeedbackNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserVideoFeedbackNode` and its cursor. */
export type UserVideoFeedbackNodeEdge = {
  __typename: 'UserVideoFeedbackNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserVideoFeedbackNode>;
};

/** Provides queries of the UserVideoNode model. */
export type UserVideoNode = Node & {
  __typename: 'UserVideoNode';
  /** Account associated with this User Video, if any. This will be automatically filled when an Account is created for a ShopState. */
  account?: Maybe<AccountRelayNode>;
  autofillResults: UserVideoAutofillResultNodeConnection;
  /** Business associated with this User Video, if any. */
  business?: Maybe<BusinessRelayNode>;
  configuredVideos: ConfiguredVideoNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  currentConfiguredVideo?: Maybe<ConfiguredVideoNode>;
  currentUserVideoApproval?: Maybe<UserVideoApprovalNode>;
  derivedUserVideos?: Maybe<UserVideoNodeConnection>;
  feedback: UserVideoFeedbackNodeConnection;
  guid: Scalars['String']['output'];
  /** A record of whether this userVideo has been deleted by the user, either from their cart or from their saved drafts. */
  hasBeenDeletedByUser: Scalars['Boolean']['output'];
  hasUnapprovedChanges?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** The date and time that this user video will stop being editable. */
  lastEditableDate?: Maybe<Scalars['DateTime']['output']>;
  /** The most recent date and time the user made edits to this video via the Editor. */
  lastEditedByUser?: Maybe<Scalars['DateTime']['output']>;
  /** The original user video that was used to create this Omni video. */
  omniAutofillResults: UserVideoAutofillResultNodeConnection;
  omniType?: Maybe<Scalars['String']['output']>;
  /** This will be set if this video was initiated via a collection */
  originVariantGroup?: Maybe<VideoTemplateCollectionRelayNode>;
  /** The original User Video that this User Video was derived from. */
  originalUserVideo?: Maybe<UserVideoNode>;
  purchaseApproval?: Maybe<UserVideoPurchaseApprovalNode>;
  /** The date and time this user video was purchased. */
  purchasedAt?: Maybe<Scalars['DateTime']['output']>;
  purchasedProductObjectId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userVideoApprovals?: Maybe<Array<UserVideoApprovalNode>>;
  videoConfiguration: Scalars['JSONString']['output'];
  videoTemplateVariant: VideoTemplateVariantRelayNode;
  voiceOverProduct?: Maybe<VideoVoiceOverProduct>;
  voiceOverSpeakerGuid?: Maybe<Scalars['String']['output']>;
  voiceOverText: Scalars['String']['output'];
  voiceOverTiming?: Maybe<Scalars['JSONString']['output']>;
};

/** Provides queries of the UserVideoNode model. */
export type UserVideoNodeAutofillResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAgeDays?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestGuid?: InputMaybe<Scalars['String']['input']>;
  requestSource?: InputMaybe<Scalars['String']['input']>;
};

/** Provides queries of the UserVideoNode model. */
export type UserVideoNodeConfiguredVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the UserVideoNode model. */
export type UserVideoNodeDerivedUserVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/** Provides queries of the UserVideoNode model. */
export type UserVideoNodeFeedbackArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the UserVideoNode model. */
export type UserVideoNodeOmniAutofillResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAgeDays?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestGuid?: InputMaybe<Scalars['String']['input']>;
  requestSource?: InputMaybe<Scalars['String']['input']>;
};

export type UserVideoNodeConnection = {
  __typename: 'UserVideoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserVideoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `UserVideoNode` and its cursor. */
export type UserVideoNodeEdge = {
  __typename: 'UserVideoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserVideoNode>;
};

/** GraphQL Schema for UserVideoPurchaseApprovals */
export type UserVideoPurchaseApprovalNode = Node & {
  __typename: 'UserVideoPurchaseApprovalNode';
  /** The datetime the user video was approved for purchase. */
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The account that approved the user video. */
  approvedBy?: Maybe<AccountRelayNode>;
  createdAt: Scalars['DateTime']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** User video that was approved for purchase. */
  userVideo: UserVideoNode;
};

export type UserVideoPurchaseApprovalNodeConnection = {
  __typename: 'UserVideoPurchaseApprovalNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserVideoPurchaseApprovalNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserVideoPurchaseApprovalNode` and its cursor. */
export type UserVideoPurchaseApprovalNodeEdge = {
  __typename: 'UserVideoPurchaseApprovalNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserVideoPurchaseApprovalNode>;
};

/** An enumeration. */
export enum VariantGroupThumbnailPosterTextColor {
  /** Black */
  Black = 'BLACK',
  /** White */
  White = 'WHITE',
}

/** An enumeration. */
export enum VariantGroupThumbnailPosterTextPosition {
  /** Bottom */
  Bottom = 'BOTTOM',
  /** Middle */
  Middle = 'MIDDLE',
  /** Top */
  Top = 'TOP',
}

/** Provides queries of the Vibe model. */
export type VibeNode = Node & {
  __typename: 'VibeNode';
  createdAt: Scalars['DateTime']['output'];
  /** Lower numbers will be placed higher in the editor selection component. */
  displayOrder: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** A unique name for the vibe. */
  name: Scalars['String']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  videoTemplateAudios: VideoTemplateAudioNodeConnection;
  videoTemplateVariants: VideoTemplateVariantRelayNodeConnection;
  /** Whether this vibe should be selectable in the client. */
  visibleInClient: Scalars['Boolean']['output'];
};

/** Provides queries of the Vibe model. */
export type VibeNodeVideoTemplateAudiosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the Vibe model. */
export type VibeNodeVideoTemplateVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  aspectRatio_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  displayDuration_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupVariant?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupVariant_VariantGroup_Slug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensing?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  videoTemplate?: InputMaybe<Scalars['ID']['input']>;
  videoTemplate_HasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  videoTemplate_ImageAssetCount_Gte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_ImageAssetCount_Lte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_Name?: InputMaybe<Scalars['String']['input']>;
  videoTemplate_Slug?: InputMaybe<Scalars['String']['input']>;
};

export type VibeNodeConnection = {
  __typename: 'VibeNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VibeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VibeNode` and its cursor. */
export type VibeNodeEdge = {
  __typename: 'VibeNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VibeNode>;
};

/** Provides queries of the VideoAssetLibrary model. */
export type VideoAssetLibraryNode = Node & {
  __typename: 'VideoAssetLibraryNode';
  accountGroups: AccountGroupRelayNodeConnection;
  accounts: AccountRelayNodeConnection;
  businesses: BusinessRelayNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this collection of video assets in the Waymark Editor. */
  displayName: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  videoAssets?: Maybe<VideoAssetLibraryVideoNodeConnection>;
};

/** Provides queries of the VideoAssetLibrary model. */
export type VideoAssetLibraryNodeAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the VideoAssetLibrary model. */
export type VideoAssetLibraryNodeAccountsArgs = {
  accountGroup_DisplayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  accountGroup_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  companyName_Icontains?: InputMaybe<Scalars['String']['input']>;
  companyName_Iexact?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Icontains?: InputMaybe<Scalars['String']['input']>;
  emailAddress_Iexact?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName_Iexact?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  lastName_Iexact?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the VideoAssetLibrary model. */
export type VideoAssetLibraryNodeBusinessesArgs = {
  account_Guid?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the VideoAssetLibrary model. */
export type VideoAssetLibraryNodeVideoAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VideoAssetLibraryNodeConnection = {
  __typename: 'VideoAssetLibraryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoAssetLibraryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VideoAssetLibraryNode` and its cursor. */
export type VideoAssetLibraryNodeEdge = {
  __typename: 'VideoAssetLibraryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoAssetLibraryNode>;
};

/** Provides queries of the VideoAssetLibraryVideo model. */
export type VideoAssetLibraryVideoNode = Node & {
  __typename: 'VideoAssetLibraryVideoNode';
  createdAt: Scalars['DateTime']['output'];
  /** Used to label this video in the editor. */
  displayName?: Maybe<Scalars['String']['output']>;
  guid: Scalars['String']['output'];
  /** Height (in pixels) of the video asset. */
  height?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** Length (in seconds) of the video asset. */
  length?: Maybe<Scalars['Int']['output']>;
  /** For determining the order that video assets should appear in the library. */
  order: Scalars['Int']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  /** The datetime this video asset was removed from its library. */
  removedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Reference key provided by the Upload API. */
  uploadKey: Scalars['String']['output'];
  videoAssetLibrary?: Maybe<VideoAssetLibraryNode>;
  /** Width (in pixels) of the video asset. */
  width?: Maybe<Scalars['Int']['output']>;
};

export type VideoAssetLibraryVideoNodeConnection = {
  __typename: 'VideoAssetLibraryVideoNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoAssetLibraryVideoNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VideoAssetLibraryVideoNode` and its cursor. */
export type VideoAssetLibraryVideoNodeEdge = {
  __typename: 'VideoAssetLibraryVideoNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoAssetLibraryVideoNode>;
};

/** Provides queries of the VideoTemplateAudio model. */
export type VideoTemplateAudioNode = Node & {
  __typename: 'VideoTemplateAudioNode';
  /** An audio file that can be used with videos. */
  audio: Scalars['String']['output'];
  audioPath: Scalars['String']['output'];
  audioUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** A name to display for this audio clip. */
  displayName: Scalars['String']['output'];
  /** The duration of the audio track. */
  duration: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** A unique name to use for administration of this clip (some clips may have the display name but be mastered differently for different variants). */
  name: Scalars['String']['output'];
  omniGroupSlug?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  /** Whether this audio track should be included in Waymark AI. */
  shouldIncludeInWaymarkAi: Scalars['Boolean']['output'];
  transcodeJobId?: Maybe<Scalars['String']['output']>;
  /** A transcoded, optimized version of the audio file. */
  transcodedAudio?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vibes: VibeNodeConnection;
  videoTemplateVariants: VideoTemplateVariantRelayNodeConnection;
  videotemplateglobalaudioSet: VideoTemplateGlobalAudioNodeConnection;
};

/** Provides queries of the VideoTemplateAudio model. */
export type VideoTemplateAudioNodeVibesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the VideoTemplateAudio model. */
export type VideoTemplateAudioNodeVideoTemplateVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  aspectRatio_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  displayDuration_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupVariant?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupVariant_VariantGroup_Slug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensing?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  videoTemplate?: InputMaybe<Scalars['ID']['input']>;
  videoTemplate_HasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  videoTemplate_ImageAssetCount_Gte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_ImageAssetCount_Lte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_Name?: InputMaybe<Scalars['String']['input']>;
  videoTemplate_Slug?: InputMaybe<Scalars['String']['input']>;
};

/** Provides queries of the VideoTemplateAudio model. */
export type VideoTemplateAudioNodeVideotemplateglobalaudioSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VideoTemplateAudioNodeConnection = {
  __typename: 'VideoTemplateAudioNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoTemplateAudioNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VideoTemplateAudioNode` and its cursor. */
export type VideoTemplateAudioNodeEdge = {
  __typename: 'VideoTemplateAudioNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoTemplateAudioNode>;
};

/** Provides queries of VariantGroup model for the template chooser. */
export type VideoTemplateCollectionRelayNode = Node & {
  __typename: 'VideoTemplateCollectionRelayNode';
  accountGroups: AccountGroupRelayNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  desktopHeaderBackgroundImage?: Maybe<Scalars['String']['output']>;
  desktopHeaderLogoImage?: Maybe<Scalars['String']['output']>;
  /** A human-readable identifier for the variant group */
  displayName: Scalars['String']['output'];
  /** A short variant group identifier, added for the purpose of storing a name to be used in the Waymark store header dropdown. */
  displayNameShort: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** If false, this collection will not be indexed on Google. */
  isPublic: Scalars['Boolean']['output'];
  isRetired: Scalars['Boolean']['output'];
  mobileHeaderBackgroundImage?: Maybe<Scalars['String']['output']>;
  mobileHeaderLogoImage?: Maybe<Scalars['String']['output']>;
  /** The representative open graph image of the variant group. */
  openGraphImage: Scalars['String']['output'];
  openGraphImageHeight?: Maybe<Scalars['Int']['output']>;
  openGraphImageWidth?: Maybe<Scalars['Int']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  /**
   *
   *             A short description of the variant group to display at the top of the template browser when this collection is selected.<br/><br/>
   *             <u>This field supports the following markdown syntax:</u><br/>
   *             Link: [link text](link url)<br/>
   *             Line break: press enter twice<br/>
   *             Headings: "# Huge heading" ... "### Medium heading" ... "###### Smallest possible heading"<br/>
   *             Bold: **bold text**<br/>
   *             Italics: _italic text_
   *
   */
  shortDescription: Scalars['String']['output'];
  /** Slug to be used to identify a variant group, often used in the URL */
  slug: Scalars['String']['output'];
  /** The representative poster image of the variant group. */
  thumbnailPoster: Scalars['String']['output'];
  thumbnailPosterImageHeight?: Maybe<Scalars['Int']['output']>;
  thumbnailPosterImageWidth?: Maybe<Scalars['Int']['output']>;
  thumbnailPosterTextColor: VariantGroupThumbnailPosterTextColor;
  thumbnailPosterTextPosition: VariantGroupThumbnailPosterTextPosition;
  updatedAt: Scalars['DateTime']['output'];
  /** This will be set if this video was initiated via a collection */
  uservideoSet: UserVideoNodeConnection;
};

/** Provides queries of VariantGroup model for the template chooser. */
export type VideoTemplateCollectionRelayNodeAccountGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayName_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of VariantGroup model for the template chooser. */
export type VideoTemplateCollectionRelayNodeUservideoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type VideoTemplateCollectionRelayNodeConnection = {
  __typename: 'VideoTemplateCollectionRelayNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoTemplateCollectionRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VideoTemplateCollectionRelayNode` and its cursor. */
export type VideoTemplateCollectionRelayNodeEdge = {
  __typename: 'VideoTemplateCollectionRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoTemplateCollectionRelayNode>;
};

export type VideoTemplateGlobalAudioLibraryNode = Node & {
  __typename: 'VideoTemplateGlobalAudioLibraryNode';
  createdAt: Scalars['DateTime']['output'];
  /** Used to differentiate between libraries in the Editor. */
  displayName: Scalars['String']['output'];
  /** Libraries with lower numbers will be placed higher in the Editor. */
  displayOrder: Scalars['Int']['output'];
  globalAudio: VideoTemplateGlobalAudioNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VideoTemplateGlobalAudioLibraryNodeGlobalAudioArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VideoTemplateGlobalAudioLibraryNodeConnection = {
  __typename: 'VideoTemplateGlobalAudioLibraryNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoTemplateGlobalAudioLibraryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VideoTemplateGlobalAudioLibraryNode` and its cursor. */
export type VideoTemplateGlobalAudioLibraryNodeEdge = {
  __typename: 'VideoTemplateGlobalAudioLibraryNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoTemplateGlobalAudioLibraryNode>;
};

/** VideoTemplateGlobalAudio model */
export type VideoTemplateGlobalAudioNode = Node & {
  __typename: 'VideoTemplateGlobalAudioNode';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** The length of the audio file in seconds. */
  length: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  videoTemplateAudio: VideoTemplateAudioNode;
  videoTemplateGlobalAudioLibraries: VideoTemplateGlobalAudioLibraryNodeConnection;
};

/** VideoTemplateGlobalAudio model */
export type VideoTemplateGlobalAudioNodeVideoTemplateGlobalAudioLibrariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VideoTemplateGlobalAudioNodeConnection = {
  __typename: 'VideoTemplateGlobalAudioNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoTemplateGlobalAudioNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VideoTemplateGlobalAudioNode` and its cursor. */
export type VideoTemplateGlobalAudioNodeEdge = {
  __typename: 'VideoTemplateGlobalAudioNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoTemplateGlobalAudioNode>;
};

/** Provides queries of the VideoTemplate model. */
export type VideoTemplateNode = Node & {
  __typename: 'VideoTemplateNode';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  exclusivePartner?: Maybe<AccountPartnerRelayNode>;
  /** Whether this template has footage fields in its configuration */
  hasFootage: Scalars['Boolean']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** The number of unique image fields in this template */
  imageAssetCount: Scalars['Int']['output'];
  isRetired: Scalars['Boolean']['output'];
  isWaymarkAuthorTemplate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variants: VideoTemplateVariantRelayNodeConnection;
};

/** Provides queries of the VideoTemplate model. */
export type VideoTemplateNodeVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  aspectRatio_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  before?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  displayDuration_In?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupVariant?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupVariant_VariantGroup_Slug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  licensing?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  videoTemplate?: InputMaybe<Scalars['ID']['input']>;
  videoTemplate_HasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  videoTemplate_ImageAssetCount_Gte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_ImageAssetCount_Lte?: InputMaybe<Scalars['Float']['input']>;
  videoTemplate_Name?: InputMaybe<Scalars['String']['input']>;
  videoTemplate_Slug?: InputMaybe<Scalars['String']['input']>;
};

export type VideoTemplateNodeConnection = {
  __typename: 'VideoTemplateNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VideoTemplateNode` and its cursor. */
export type VideoTemplateNodeEdge = {
  __typename: 'VideoTemplateNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoTemplateNode>;
};

/** An enumeration. */
export enum VideoTemplateVariantDefaultRenderFormat {
  /** TV Quality Video Format */
  BroadcastQuality = 'BROADCAST_QUALITY',
  /** Email Video Format */
  Email = 'EMAIL',
  /** Hover Preview Video Format */
  Preview = 'PREVIEW',
}

export type VideoTemplateVariantOfferType = {
  __typename: 'VideoTemplateVariantOfferType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The Offer to apply to the linked Variant. */
  offer: OfferType;
  updatedAt: Scalars['DateTime']['output'];
  /** Video Template Variant that should be purchasable with the linked Offer. */
  videoTemplateVariant: VideoTemplateVariantRelayNode;
};

/** Provides queries of the VideoTemplateVariant model, including exports of configured variants. */
export type VideoTemplateVariantRelayNode = Node & {
  __typename: 'VideoTemplateVariantRelayNode';
  /** The variant's aspect ratio represented as a string in format 'W:H' */
  aspectRatio: Scalars['String']['output'];
  audioTracks: VideoTemplateAudioNodeConnection;
  autofillResults: UserVideoAutofillResultNodeConnection;
  bulkrenderjobSet: Array<BulkRenderJobType>;
  compoundSlug: Scalars['String']['output'];
  configuration?: Maybe<Scalars['JSONString']['output']>;
  configuredvideoSet: ConfiguredVideoNodeConnection;
  createdAt: Scalars['DateTime']['output'];
  creationCsv?: Maybe<Scalars['String']['output']>;
  creationData?: Maybe<Scalars['String']['output']>;
  defaultRenderFormat: VideoTemplateVariantDefaultRenderFormat;
  /** A short description (2-3 sentences) describing the audience for a template, to be displayed in the `Who is this video best for?` section of an ItemView. */
  descriptionAudience: Scalars['String']['output'];
  /** The duration to display for the variant, in seconds. Strictly for display purposes, not intended to be used to play or render the variant. */
  displayDuration?: Maybe<Scalars['Int']['output']>;
  displayName: Scalars['String']['output'];
  exclusivePartner?: Maybe<AccountPartnerRelayNode>;
  /** When should this variant be available to the general audience (non-Premiere users)? */
  generalAudienceAvailableOn: Scalars['Date']['output'];
  guid: Scalars['String']['output'];
  /** Height (in pixels) of the variant. */
  height: Scalars['Int']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isOmni: Scalars['Boolean']['output'];
  isPartnerExclusive?: Maybe<Scalars['Boolean']['output']>;
  licensing?: Maybe<Scalars['String']['output']>;
  omniGroupSlug?: Maybe<Scalars['String']['output']>;
  /**
   *
   *             The list of configuration dotpaths that should *not* be modified from the default variant configuration
   *             after personalization. Value should be one of 'NONE' (default), 'ALL', or a list of dotpath strings
   *             (e.g., ["offersText3.content", "slideText1.headline.content"]).
   *
   */
  personalizationBlacklist: Scalars['JSONString']['output'];
  pk?: Maybe<Scalars['Int']['output']>;
  previewConfiguredVideo?: Maybe<ConfiguredVideoNode>;
  previewVideo: Scalars['String']['output'];
  previewVideoUrl?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  /** List of related variant slugs. Used for the recommended videos on the variant's info page. */
  relatedVariants: Array<Scalars['String']['output']>;
  /** Whether this variant should include global audio tracks in its list of available tracks. */
  shouldIncludeGlobalAudio: Scalars['Boolean']['output'];
  /** Whether this variant should be included in Waymark AI. */
  shouldIncludeInWaymarkAi: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  /** Shortest (1 sentence) summary of the variant, for use in variant list feeds. */
  summary: Scalars['String']['output'];
  thumbnailImage: Scalars['String']['output'];
  thumbnailImageHeight?: Maybe<Scalars['Int']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  thumbnailImageWidth?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uservideoSet: UserVideoNodeConnection;
  variantOffer?: Maybe<VideoTemplateVariantOfferType>;
  vibes: VibeNodeConnection;
  videoDescriptor?: Maybe<Scalars['JSONString']['output']>;
  videoTemplate: VideoTemplateNode;
  /** Width (in pixels) of the variant. */
  width: Scalars['Int']['output'];
};

/** Provides queries of the VideoTemplateVariant model, including exports of configured variants. */
export type VideoTemplateVariantRelayNodeAudioTracksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the VideoTemplateVariant model, including exports of configured variants. */
export type VideoTemplateVariantRelayNodeAutofillResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  maxAgeDays?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestGuid?: InputMaybe<Scalars['String']['input']>;
  requestSource?: InputMaybe<Scalars['String']['input']>;
};

/** Provides queries of the VideoTemplateVariant model, including exports of configured variants. */
export type VideoTemplateVariantRelayNodeConfiguredvideoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Provides queries of the VideoTemplateVariant model, including exports of configured variants. */
export type VideoTemplateVariantRelayNodeUservideoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

/** Provides queries of the VideoTemplateVariant model, including exports of configured variants. */
export type VideoTemplateVariantRelayNodeVibesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type VideoTemplateVariantRelayNodeConnection = {
  __typename: 'VideoTemplateVariantRelayNodeConnection';
  availableFilters?: Maybe<AvailableVideoTemplateVariantFilters>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoTemplateVariantRelayNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `VideoTemplateVariantRelayNode` and its cursor. */
export type VideoTemplateVariantRelayNodeEdge = {
  __typename: 'VideoTemplateVariantRelayNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoTemplateVariantRelayNode>;
};

/** Provides queries of the RenderedVideo model. */
export type VideoVoiceOverProduct = Node & {
  __typename: 'VideoVoiceOverProduct';
  business?: Maybe<BusinessRelayNode>;
  createdAt: Scalars['DateTime']['output'];
  finalVideo: Scalars['String']['output'];
  guid: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  /** If the voice-over product has been rendered. */
  isDelivered: Scalars['Boolean']['output'];
  /** Pronunciations for any potentially difficult words related to the customer's business. */
  specialPronunciations: Scalars['String']['output'];
  /** The style that the script should be read in. */
  style?: Maybe<VideoVoiceOverProductStyle>;
  /** Anything that the customer would like to be emphasized in their script. */
  thingsToEmphasize: Scalars['String']['output'];
  /** The preferred depth of the reader's voice. */
  timbre?: Maybe<VideoVoiceOverProductTimbre>;
  /** The preferred tone of the reader's voice. */
  tone?: Maybe<VideoVoiceOverProductTone>;
  updatedAt: Scalars['DateTime']['output'];
  userVideo?: Maybe<UserVideoNode>;
};

export type VideoVoiceOverProductConnection = {
  __typename: 'VideoVoiceOverProductConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VideoVoiceOverProductEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `VideoVoiceOverProduct` and its cursor. */
export type VideoVoiceOverProductEdge = {
  __typename: 'VideoVoiceOverProductEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<VideoVoiceOverProduct>;
};

/** An enumeration. */
export enum VideoVoiceOverProductStyle {
  /** Calming */
  Calming = 'CALMING',
  /** Conversational */
  Conversational = 'CONVERSATIONAL',
  /** Energetic */
  Energetic = 'ENERGETIC',
  /** Friendly */
  Friendly = 'FRIENDLY',
  /** Professional */
  Professional = 'PROFESSIONAL',
  /** Radio DJ */
  Radio = 'RADIO',
  /** Serious */
  Serious = 'SERIOUS',
}

/** An enumeration. */
export enum VideoVoiceOverProductTimbre {
  /** Feminine */
  Feminine = 'FEMININE',
  /** Masculine */
  Masculine = 'MASCULINE',
  /** No preference */
  NoPreference = 'NO_PREFERENCE',
}

/** An enumeration. */
export enum VideoVoiceOverProductTone {
  /** Middle aged and familiar */
  MiddleAged = 'MIDDLE_AGED',
  /** Older and experienced */
  Older = 'OLDER',
  /** Millenial and youthful */
  Youthful = 'YOUTHFUL',
}

export type AccountDetailQueryVariables = Exact<{
  pk?: InputMaybe<Scalars['Int']['input']>;
}>;

export type AccountDetailQuery = {
  __typename: 'Query';
  accountByPk?: {
    __typename: 'AccountRelayNode';
    id: string;
    pk?: number | null;
    guid: string;
    externalId: string;
    role?: string | null;
    firstName: string;
    lastName: string;
    displayName?: string | null;
    emailAddress: string;
    profileImage?: string | null;
    companyName: string;
    phone: string;
    city: string;
    state: string;
    notes: string;
    createdAt: string;
    firstPurchasedAt?: string | null;
    hasActiveSubscriptions?: boolean | null;
    shopUrl?: string | null;
    summaryUrl?: string | null;
    offerContext?: {
      __typename: 'OfferContextType';
      hasUnlimited?: boolean | null;
      hasWhiteLabeling?: boolean | null;
      hasVideoApproval?: boolean | null;
      hasVoiceOver?: boolean | null;
      hasConcierge?: boolean | null;
      latestVideoDownloadSubscriptionValidThrough?: string | null;
      recurringMonthlyDownloads?: number | null;
      recurringRolloverDownloads?: number | null;
      totalRemainingRecurringDownloads?: number | null;
      totalRemainingDownloads?: number | null;
    } | null;
    accountGroups: {
      __typename: 'AccountGroupRelayNodeConnection';
      edges: Array<{
        __typename: 'AccountGroupRelayNodeEdge';
        node?: {
          __typename: 'AccountGroupRelayNode';
          id: string;
          displayName: string;
          partner?: { __typename: 'AccountPartnerRelayNode'; id: string; name: string } | null;
        } | null;
      } | null>;
    };
    serviceActivities: {
      __typename: 'ServiceActivityRelayNodeConnection';
      edges: Array<{
        __typename: 'ServiceActivityRelayNodeEdge';
        node?: {
          __typename: 'ServiceActivityRelayNode';
          description: string;
          completedOn?: string | null;
          adminUser?: { __typename: 'LoginUserType'; fullName: string } | null;
          activityType: { __typename: 'ServiceActivityTypeRelayNode'; name: string };
        } | null;
      } | null>;
    };
  } | null;
};

export type AddCreditsMutationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  creditCount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
}>;

export type AddCreditsMutationMutation = {
  __typename: 'Mutation';
  addAccountCredits?: {
    __typename: 'AddAccountCreditsMutationPayload';
    account?: {
      __typename: 'AccountRelayNode';
      id: string;
      offerContext?: {
        __typename: 'OfferContextType';
        totalRemainingDownloads?: number | null;
      } | null;
    } | null;
  } | null;
};

export type AdminAccountListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  id?: InputMaybe<Scalars['ID']['input']>;
  pk?: InputMaybe<Scalars['Int']['input']>;
  guid?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  accountGroupName?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminAccountListQuery = {
  __typename: 'Query';
  accounts?: {
    __typename: 'AccountRelayNodeConnection';
    totalCount?: number | null;
    pageInfo: {
      __typename: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      __typename: 'AccountRelayNodeEdge';
      cursor: string;
      node?: {
        __typename: 'AccountRelayNode';
        id: string;
        pk?: number | null;
        guid: string;
        externalId: string;
        createdAt: string;
        firstName: string;
        lastName: string;
        emailAddress: string;
        companyName: string;
        phone: string;
        accountGroups: {
          __typename: 'AccountGroupRelayNodeConnection';
          edges: Array<{
            __typename: 'AccountGroupRelayNodeEdge';
            node?: {
              __typename: 'AccountGroupRelayNode';
              id: string;
              displayName: string;
              partner?: { __typename: 'AccountPartnerRelayNode'; id: string; name: string } | null;
            } | null;
          } | null>;
        };
        partner?: { __typename: 'AccountPartnerRelayNode'; id: string; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type BulkRenderJobDetailQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type BulkRenderJobDetailQuery = {
  __typename: 'Query';
  bulkRenderJobs?: Array<{
    __typename: 'BulkRenderJobType';
    bulkRenderVideos: Array<{
      __typename: 'BulkRenderVideoType';
      id: string;
      renderId: string;
      description: string;
      configuration: string;
    }>;
  }> | null;
};

export type BulkRenderingJobsQueryVariables = Exact<{ [key: string]: never }>;

export type BulkRenderingJobsQuery = {
  __typename: 'Query';
  bulkRenderJobs?: Array<{
    __typename: 'BulkRenderJobType';
    id: string;
    createdAt: string;
    jobId: string;
    description: string;
    configurationMapping: string;
    destinationAccount?: {
      __typename: 'AccountRelayNode';
      id: string;
      firstName: string;
      lastName: string;
    } | null;
  }> | null;
};

export type ImageLibraryDetailQueryVariables = Exact<{
  pk?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ImageLibraryDetailQuery = {
  __typename: 'Query';
  imageLibraryByPk?: {
    __typename: 'ImageLibraryNode';
    id: string;
    pk?: number | null;
    slug: string;
    displayName: string;
    images?: {
      __typename: 'ImageLibraryImageNodeConnection';
      edges: Array<{
        __typename: 'ImageLibraryImageNodeEdge';
        node?: {
          __typename: 'ImageLibraryImageNode';
          id: string;
          pk?: number | null;
          guid: string;
          displayName?: string | null;
          image: string;
          order: number;
          source?: string | null;
          imageType?: string | null;
          isFavorite: boolean;
          createdAt: string;
          removedAt?: string | null;
          updatedAt: string;
          lastUsedAt?: string | null;
          baseUrl?: string | null;
          imageWidth?: number | null;
          imageHeight?: number | null;
          upscaledImageUrl?: string | null;
          stockAssetId?: string | null;
          stockSearchId?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type UpdateImageLibraryImageMutationMutationVariables = Exact<{
  input: UpdateImageLibraryImageMutationInput;
}>;

export type UpdateImageLibraryImageMutationMutation = {
  __typename: 'Mutation';
  updateImageLibraryImage?: {
    __typename: 'UpdateImageLibraryImageMutationPayload';
    updatedImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      image: string;
      order: number;
      source?: string | null;
      imageType?: string | null;
      isFavorite: boolean;
      createdAt: string;
      removedAt?: string | null;
      updatedAt: string;
      lastUsedAt?: string | null;
      baseUrl?: string | null;
      imageWidth?: number | null;
      imageHeight?: number | null;
      upscaledImageUrl?: string | null;
      stockAssetId?: string | null;
      stockSearchId?: string | null;
    } | null;
  } | null;
};

export type DeleteImageLibraryImageMutationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteImageLibraryImageMutationMutation = {
  __typename: 'Mutation';
  deleteImageLibraryImage?: {
    __typename: 'DeleteImageLibraryImageMutationPayload';
    ok?: boolean | null;
  } | null;
};

export type ImageLibraryListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  id?: InputMaybe<Scalars['ID']['input']>;
  pk?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
}>;

export type ImageLibraryListQuery = {
  __typename: 'Query';
  imageLibraries?: {
    __typename: 'ImageLibraryNodeConnection';
    totalCount?: number | null;
    pageInfo: {
      __typename: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      __typename: 'ImageLibraryNodeEdge';
      cursor: string;
      node?: {
        __typename: 'ImageLibraryNode';
        id: string;
        pk?: number | null;
        slug: string;
        displayName: string;
        createdAt: string;
      } | null;
    } | null>;
  } | null;
};

export type CreateImageLibraryMutationMutationVariables = Exact<{
  displayName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;

export type CreateImageLibraryMutationMutation = {
  __typename: 'Mutation';
  createImageLibrary?: {
    __typename: 'CreateImageLibraryMutationPayload';
    imageLibrary?: { __typename: 'ImageLibraryNode'; id: string; pk?: number | null } | null;
  } | null;
};

export type SelectedPartnerQueryVariables = Exact<{
  pk?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SelectedPartnerQuery = {
  __typename: 'Query';
  partnerByPk?: {
    __typename: 'AccountPartnerRelayNode';
    pk?: number | null;
    name: string;
    slug: string;
  } | null;
};

export type SearchPartnerByNameQueryVariables = Exact<{
  nameSearchString: Scalars['String']['input'];
}>;

export type SearchPartnerByNameQuery = {
  __typename: 'Query';
  partners?: {
    __typename: 'AccountPartnerRelayNodeConnection';
    edges: Array<{
      __typename: 'AccountPartnerRelayNodeEdge';
      node?: {
        __typename: 'AccountPartnerRelayNode';
        pk?: number | null;
        name: string;
        slug: string;
      } | null;
    } | null>;
  } | null;
};

export type AdminVideoTemplateVariantsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminVideoTemplateVariantsQuery = {
  __typename: 'Query';
  videoTemplateVariants?: {
    __typename: 'VideoTemplateVariantRelayNodeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename: 'VideoTemplateVariantRelayNodeEdge';
      cursor: string;
      node?: {
        __typename: 'VideoTemplateVariantRelayNode';
        id: string;
        createdAt: string;
        slug: string;
        displayName: string;
        isEnabled: boolean;
        defaultRenderFormat: VideoTemplateVariantDefaultRenderFormat;
        videoTemplate: {
          __typename: 'VideoTemplateNode';
          id: string;
          slug: string;
          name: string;
          permissions?: string | null;
        };
      } | null;
    } | null>;
  } | null;
};

export type VariantExportQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>;
}>;

export type VariantExportQuery = {
  __typename: 'Query';
  videoTemplateVariantsByIds?: Array<{
    __typename: 'VideoTemplateVariantRelayNode';
    creationData?: string | null;
  }> | null;
};

export type UserVideoQueryVariables = Exact<{
  guid: Scalars['String']['input'];
}>;

export type UserVideoQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    guid: string;
    title: string;
    videoConfiguration: string;
    videoTemplateVariant: {
      __typename: 'VideoTemplateVariantRelayNode';
      slug: string;
      licensing?: string | null;
      variantOffer?: {
        __typename: 'VideoTemplateVariantOfferType';
        offer: { __typename: 'OfferType'; pk?: number | null };
      } | null;
      videoTemplate: {
        __typename: 'VideoTemplateNode';
        name: string;
        pk?: number | null;
        slug: string;
        isRetired: boolean;
        permissions?: string | null;
        hasFootage: boolean;
        imageAssetCount: number;
        exclusivePartner?: {
          __typename: 'AccountPartnerRelayNode';
          pk?: number | null;
          name: string;
          slug: string;
        } | null;
      };
      exclusivePartner?: {
        __typename: 'AccountPartnerRelayNode';
        pk?: number | null;
        name: string;
        slug: string;
      } | null;
    };
  } | null;
};

export type VideoAssetLibraryDetailQueryVariables = Exact<{
  pk?: InputMaybe<Scalars['Int']['input']>;
}>;

export type VideoAssetLibraryDetailQuery = {
  __typename: 'Query';
  videoLibraryByPk?: {
    __typename: 'VideoAssetLibraryNode';
    id: string;
    pk?: number | null;
    slug: string;
    displayName: string;
    videoAssets?: {
      __typename: 'VideoAssetLibraryVideoNodeConnection';
      edges: Array<{
        __typename: 'VideoAssetLibraryVideoNodeEdge';
        node?: {
          __typename: 'VideoAssetLibraryVideoNode';
          id: string;
          pk?: number | null;
          guid: string;
          uploadKey: string;
          displayName?: string | null;
          width?: number | null;
          height?: number | null;
          length?: number | null;
          order: number;
          updatedAt: string;
          removedAt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type AdminVideoAssetLibraryListQueryVariables = Exact<{ [key: string]: never }>;

export type AdminVideoAssetLibraryListQuery = {
  __typename: 'Query';
  videoLibraries?: Array<{
    __typename: 'VideoAssetLibraryNode';
    id: string;
    pk?: number | null;
    displayName: string;
    slug: string;
  }> | null;
};

export type VideoAssetLibraryVideoDetailQueryVariables = Exact<{
  pk?: InputMaybe<Scalars['Int']['input']>;
}>;

export type VideoAssetLibraryVideoDetailQuery = {
  __typename: 'Query';
  videoLibraryVideoByPk?: {
    __typename: 'VideoAssetLibraryVideoNode';
    id: string;
    pk?: number | null;
    guid: string;
    uploadKey: string;
    displayName?: string | null;
    width?: number | null;
    height?: number | null;
    length?: number | null;
    order: number;
    updatedAt: string;
    removedAt?: string | null;
  } | null;
};

export type AdminAutofillResultDataFragment = {
  __typename: 'UserVideoAutofillResultNode';
  id: string;
  requestGuid: string;
  vibe?: string | null;
  languageCode?: string | null;
  userInstructions?: string | null;
  videoDescriptor?: string | null;
  createdAt: string;
  business?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    businessName: string;
    logoImageUrl?: string | null;
    websiteUrl: string;
  } | null;
  videoTemplateVariant?: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    displayName: string;
    compoundSlug: string;
    aspectRatio: string;
  } | null;
  autofillFeedback: {
    __typename: 'AutofillFeedbackNodeConnection';
    edges: Array<{
      __typename: 'AutofillFeedbackNodeEdge';
      node?: {
        __typename: 'AutofillFeedbackNode';
        id: string;
        rating: string;
        feedbackText?: string | null;
      } | null;
    } | null>;
  };
};

export type AdminAutofillResultAccountDataFragment = {
  __typename: 'AccountRelayNode';
  id: string;
  pk?: number | null;
  guid: string;
  displayName?: string | null;
  emailAddress: string;
  partner?: {
    __typename: 'AccountPartnerRelayNode';
    id: string;
    name: string;
    slug: string;
  } | null;
};

export type AdminAutofillResultsUserVideoQueryVariables = Exact<{
  userVideoGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminAutofillResultsUserVideoQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    createdAt: string;
    lastEditedByUser?: string | null;
    account?: {
      __typename: 'AccountRelayNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      emailAddress: string;
      partner?: {
        __typename: 'AccountPartnerRelayNode';
        id: string;
        name: string;
        slug: string;
      } | null;
    } | null;
    autofillResults: {
      __typename: 'UserVideoAutofillResultNodeConnection';
      edges: Array<{
        __typename: 'UserVideoAutofillResultNodeEdge';
        node?: {
          __typename: 'UserVideoAutofillResultNode';
          id: string;
          requestGuid: string;
          vibe?: string | null;
          languageCode?: string | null;
          userInstructions?: string | null;
          videoDescriptor?: string | null;
          createdAt: string;
          business?: {
            __typename: 'BusinessRelayNode';
            id: string;
            guid: string;
            businessName: string;
            logoImageUrl?: string | null;
            websiteUrl: string;
          } | null;
          videoTemplateVariant?: {
            __typename: 'VideoTemplateVariantRelayNode';
            id: string;
            displayName: string;
            compoundSlug: string;
            aspectRatio: string;
          } | null;
          autofillFeedback: {
            __typename: 'AutofillFeedbackNodeConnection';
            edges: Array<{
              __typename: 'AutofillFeedbackNodeEdge';
              node?: {
                __typename: 'AutofillFeedbackNode';
                id: string;
                rating: string;
                feedbackText?: string | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type AdminAutofillResultQueryVariables = Exact<{
  autofillRequestGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminAutofillResultQuery = {
  __typename: 'Query';
  autofillResultByRequestGuid?: {
    __typename: 'UserVideoAutofillResultNode';
    id: string;
    requestGuid: string;
    vibe?: string | null;
    languageCode?: string | null;
    userInstructions?: string | null;
    videoDescriptor?: string | null;
    createdAt: string;
    account?: {
      __typename: 'AccountRelayNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      emailAddress: string;
      partner?: {
        __typename: 'AccountPartnerRelayNode';
        id: string;
        name: string;
        slug: string;
      } | null;
    } | null;
    business?: {
      __typename: 'BusinessRelayNode';
      id: string;
      guid: string;
      businessName: string;
      logoImageUrl?: string | null;
      websiteUrl: string;
    } | null;
    videoTemplateVariant?: {
      __typename: 'VideoTemplateVariantRelayNode';
      id: string;
      displayName: string;
      compoundSlug: string;
      aspectRatio: string;
    } | null;
    autofillFeedback: {
      __typename: 'AutofillFeedbackNodeConnection';
      edges: Array<{
        __typename: 'AutofillFeedbackNodeEdge';
        node?: {
          __typename: 'AutofillFeedbackNode';
          id: string;
          rating: string;
          feedbackText?: string | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type AdminAutofillResultsForAccountQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminAutofillResultsForAccountQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    pk?: number | null;
    guid: string;
    displayName?: string | null;
    emailAddress: string;
    autofillResults?: {
      __typename: 'UserVideoAutofillResultNodeConnection';
      edges: Array<{
        __typename: 'UserVideoAutofillResultNodeEdge';
        node?: {
          __typename: 'UserVideoAutofillResultNode';
          id: string;
          requestGuid: string;
          vibe?: string | null;
          languageCode?: string | null;
          userInstructions?: string | null;
          videoDescriptor?: string | null;
          createdAt: string;
          business?: {
            __typename: 'BusinessRelayNode';
            id: string;
            guid: string;
            businessName: string;
            logoImageUrl?: string | null;
            websiteUrl: string;
          } | null;
          videoTemplateVariant?: {
            __typename: 'VideoTemplateVariantRelayNode';
            id: string;
            displayName: string;
            compoundSlug: string;
            aspectRatio: string;
          } | null;
          autofillFeedback: {
            __typename: 'AutofillFeedbackNodeConnection';
            edges: Array<{
              __typename: 'AutofillFeedbackNodeEdge';
              node?: {
                __typename: 'AutofillFeedbackNode';
                id: string;
                rating: string;
                feedbackText?: string | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
    partner?: {
      __typename: 'AccountPartnerRelayNode';
      id: string;
      name: string;
      slug: string;
    } | null;
  } | null;
};

export type AccountPartnerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AccountPartnerQuery = {
  __typename: 'Query';
  partner?: {
    __typename: 'AccountPartnerRelayNode';
    activeUserCount?: number | null;
    currentMonthPurchasedVideoCount?: number | null;
    maxActiveUsers?: number | null;
    maxMonthlyVideos?: number | null;
  } | null;
};

export type GenerationResultUserVideoQueryVariables = Exact<{
  userVideoGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type GenerationResultUserVideoQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    title: string;
    createdAt: string;
    purchasedAt?: string | null;
    lastEditedByUser?: string | null;
    business?: {
      __typename: 'BusinessRelayNode';
      id: string;
      guid: string;
      businessName: string;
    } | null;
    videoTemplateVariant: {
      __typename: 'VideoTemplateVariantRelayNode';
      id: string;
      videoTemplate: { __typename: 'VideoTemplateNode'; id: string; name: string };
    };
  } | null;
};

export type TemplateVariantOmniSupportQueryVariables = Exact<{
  variantSlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type TemplateVariantOmniSupportQuery = {
  __typename: 'Query';
  videoTemplateVariantBySlug?: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    omniGroupSlug?: string | null;
    isOmni: boolean;
  } | null;
};

export type AccountOmniPermissionsQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountOmniPermissionsQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    guid: string;
    isOmniEnabled?: boolean | null;
  } | null;
};

export type VideoPreviewPageUserVideoQueryVariables = Exact<{
  guid: Scalars['String']['input'];
}>;

export type VideoPreviewPageUserVideoQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    title: string;
    purchasedAt?: string | null;
    hasBeenDeletedByUser: boolean;
    lastEditedByUser?: string | null;
    videoConfiguration: string;
    voiceOverProduct?: {
      __typename: 'VideoVoiceOverProduct';
      id: string;
      finalVideo: string;
    } | null;
    videoTemplateVariant: {
      __typename: 'VideoTemplateVariantRelayNode';
      id: string;
      guid: string;
      width: number;
      height: number;
      compoundSlug: string;
      isEnabled: boolean;
      videoTemplate: {
        __typename: 'VideoTemplateNode';
        id: string;
        isWaymarkAuthorTemplate: boolean;
        isRetired: boolean;
      };
    };
    account?: { __typename: 'AccountRelayNode'; id: string; guid: string } | null;
    currentConfiguredVideo?: {
      __typename: 'ConfiguredVideoNode';
      id: string;
      guid: string;
      renderStatus?: string | null;
      thumbnailUrl?: string | null;
      renderedVideos: {
        __typename: 'RenderedVideoNodeConnection';
        edges: Array<{
          __typename: 'RenderedVideoNodeEdge';
          node?: {
            __typename: 'RenderedVideoNode';
            id: string;
            guid: string;
            renderUrl?: string | null;
            renderFormat?: string | null;
            renderedAt?: string | null;
            renderStatus?: string | null;
            renderSize?: string | null;
            hasWatermark?: boolean | null;
            createdAt: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UserVideoShareModalDataQueryVariables = Exact<{
  userVideoGUID: Scalars['String']['input'];
}>;

export type UserVideoShareModalDataQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    title: string;
    purchasedAt?: string | null;
    feedback: {
      __typename: 'UserVideoFeedbackNodeConnection';
      edges: Array<{
        __typename: 'UserVideoFeedbackNodeEdge';
        node?: { __typename: 'UserVideoFeedbackNode'; id: string } | null;
      } | null>;
    };
  } | null;
};

export type AccountPartnerNameQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountPartnerNameQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    partner?: { __typename: 'AccountPartnerRelayNode'; id: string; name: string } | null;
  } | null;
};

export type CoreAccountFieldsFragment = {
  __typename: 'AccountRelayNode';
  id: string;
  pk?: number | null;
  createdAt: string;
  guid: string;
  displayName?: string | null;
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  role?: string | null;
  lastLoginDate?: string | null;
  externalId: string;
  isActive: boolean;
};

export type AccountAccountGroupFieldsFragment = {
  __typename: 'AccountRelayNode';
  accountGroups: {
    __typename: 'AccountGroupRelayNodeConnection';
    edges: Array<{
      __typename: 'AccountGroupRelayNodeEdge';
      node?: {
        __typename: 'AccountGroupRelayNode';
        id: string;
        guid: string;
        displayName: string;
      } | null;
    } | null>;
  };
};

export type ManagedAccountDetailsByGuidQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type ManagedAccountDetailsByGuidQuery = {
  __typename: 'Query';
  accounts?: {
    __typename: 'AccountRelayNodeConnection';
    edges: Array<{
      __typename: 'AccountRelayNodeEdge';
      node?: {
        __typename: 'AccountRelayNode';
        id: string;
        pk?: number | null;
        createdAt: string;
        guid: string;
        displayName?: string | null;
        firstName: string;
        lastName: string;
        companyName: string;
        emailAddress: string;
        role?: string | null;
        lastLoginDate?: string | null;
        externalId: string;
        isActive: boolean;
        accountGroups: {
          __typename: 'AccountGroupRelayNodeConnection';
          edges: Array<{
            __typename: 'AccountGroupRelayNodeEdge';
            node?: {
              __typename: 'AccountGroupRelayNode';
              id: string;
              guid: string;
              displayName: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type AccountGroupListQueryVariables = Exact<{
  partnerGuid?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountGroupListQuery = {
  __typename: 'Query';
  managedAccountGroups?: Array<{
    __typename: 'AccountGroupRelayNode';
    id: string;
    displayName: string;
    guid: string;
  }> | null;
};

export type UpdateAccountsByGuidMutationVariables = Exact<{
  input: UpdateAccountMutationInput;
}>;

export type UpdateAccountsByGuidMutation = {
  __typename: 'Mutation';
  updateAccount?: {
    __typename: 'UpdateAccountMutationPayload';
    updatedAccounts?: Array<{
      __typename: 'AccountRelayNode';
      id: string;
      pk?: number | null;
      createdAt: string;
      guid: string;
      displayName?: string | null;
      firstName: string;
      lastName: string;
      companyName: string;
      emailAddress: string;
      role?: string | null;
      lastLoginDate?: string | null;
      externalId: string;
      isActive: boolean;
      accountGroups: {
        __typename: 'AccountGroupRelayNodeConnection';
        edges: Array<{
          __typename: 'AccountGroupRelayNodeEdge';
          node?: {
            __typename: 'AccountGroupRelayNode';
            id: string;
            guid: string;
            displayName: string;
          } | null;
        } | null>;
      };
    }> | null;
  } | null;
};

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountMutationInput;
}>;

export type CreateAccountMutation = {
  __typename: 'Mutation';
  createAccount?: {
    __typename: 'CreateAccountMutationPayload';
    createdAccount?: {
      __typename: 'AccountRelayNode';
      id: string;
      pk?: number | null;
      createdAt: string;
      guid: string;
      displayName?: string | null;
      firstName: string;
      lastName: string;
      companyName: string;
      emailAddress: string;
      role?: string | null;
      lastLoginDate?: string | null;
      externalId: string;
      isActive: boolean;
      accountGroups: {
        __typename: 'AccountGroupRelayNodeConnection';
        edges: Array<{
          __typename: 'AccountGroupRelayNodeEdge';
          node?: {
            __typename: 'AccountGroupRelayNode';
            id: string;
            guid: string;
            displayName: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type TakenEmailsQueryVariables = Exact<{
  emailAddresses:
    | Array<InputMaybe<Scalars['String']['input']>>
    | InputMaybe<Scalars['String']['input']>;
}>;

export type TakenEmailsQuery = { __typename: 'Query'; takenEmails: Array<string> };

export type PasswordValidQueryVariables = Exact<{
  password?: InputMaybe<Scalars['String']['input']>;
}>;

export type PasswordValidQuery = { __typename: 'Query'; passwordValid: boolean };

export type CanPurchaseForAccountQueryVariables = Exact<{
  accountGuid?: InputMaybe<Scalars['String']['input']>;
}>;

export type CanPurchaseForAccountQuery = { __typename: 'Query'; canPurchaseForAccount: boolean };

export type ApproveUserVideoPurchaseMutationMutationVariables = Exact<{
  input: ApproveUserVideoPurchaseMutationInput;
}>;

export type ApproveUserVideoPurchaseMutationMutation = {
  __typename: 'Mutation';
  approveUserVideoPurchase?: {
    __typename: 'ApproveUserVideoPurchaseMutationPayload';
    userVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      purchasedAt?: string | null;
    } | null;
  } | null;
};

export type CollectionsForUserQueryVariables = Exact<{ [key: string]: never }>;

export type CollectionsForUserQuery = {
  __typename: 'Query';
  videoTemplateCollections?: Array<{
    __typename: 'VideoTemplateCollectionRelayNode';
    slug: string;
    displayNameShort: string;
  }> | null;
};

export type SelectedCollectionDetailsQueryVariables = Exact<{
  collectionSlug?: InputMaybe<Scalars['String']['input']>;
}>;

export type SelectedCollectionDetailsQuery = {
  __typename: 'Query';
  videoTemplateCollectionBySlug?: {
    __typename: 'VideoTemplateCollectionRelayNode';
    slug: string;
    displayNameShort: string;
    displayName: string;
    shortDescription: string;
  } | null;
};

export type AllTemplateBrowserFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type AllTemplateBrowserFiltersQuery = {
  __typename: 'Query';
  videoTemplateVariants?: {
    __typename: 'VideoTemplateVariantRelayNodeConnection';
    availableFilters?: {
      __typename: 'AvailableVideoTemplateVariantFilters';
      displayDuration?: Array<number> | null;
    } | null;
  } | null;
};

export type TemplateBrowserVariantFragmentFragment = {
  __typename: 'VideoTemplateVariantRelayNode';
  id: string;
  compoundSlug: string;
  omniGroupSlug?: string | null;
  displayName: string;
  displayDuration?: number | null;
  previewVideoUrl?: string | null;
  thumbnailImageUrl?: string | null;
  width: number;
  height: number;
  price?: number | null;
  licensing?: string | null;
  videoTemplate: { __typename: 'VideoTemplateNode'; id: string; permissions?: string | null };
};

export type TemplateBrowserVariantsQueryVariables = Exact<{
  collectionSlug?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  aspectRatio?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  hasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  maxImageAssetCount?: InputMaybe<Scalars['Float']['input']>;
  minImageAssetCount?: InputMaybe<Scalars['Float']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type TemplateBrowserVariantsQuery = {
  __typename: 'Query';
  videoTemplateVariants?: {
    __typename: 'VideoTemplateVariantRelayNodeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename: 'VideoTemplateVariantRelayNodeEdge';
      cursor: string;
      node?: {
        __typename: 'VideoTemplateVariantRelayNode';
        id: string;
        compoundSlug: string;
        omniGroupSlug?: string | null;
        displayName: string;
        displayDuration?: number | null;
        previewVideoUrl?: string | null;
        thumbnailImageUrl?: string | null;
        width: number;
        height: number;
        price?: number | null;
        licensing?: string | null;
        videoTemplate: { __typename: 'VideoTemplateNode'; id: string; permissions?: string | null };
      } | null;
    } | null>;
  } | null;
};

export type AccountVideoLibrariesDetailQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountVideoLibrariesDetailQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    videoLibraries: {
      __typename: 'VideoAssetLibraryNodeConnection';
      edges: Array<{
        __typename: 'VideoAssetLibraryNodeEdge';
        node?: {
          __typename: 'VideoAssetLibraryNode';
          id: string;
          pk?: number | null;
          slug: string;
          displayName: string;
          videoAssets?: {
            __typename: 'VideoAssetLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'VideoAssetLibraryVideoNodeEdge';
              node?: {
                __typename: 'VideoAssetLibraryVideoNode';
                id: string;
                pk?: number | null;
                guid: string;
                uploadKey: string;
                displayName?: string | null;
                width?: number | null;
                height?: number | null;
                length?: number | null;
                order: number;
                updatedAt: string;
                removedAt?: string | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    stockVideoLibraries: {
      __typename: 'StockVideoLibraryNodeConnection';
      edges: Array<{
        __typename: 'StockVideoLibraryNodeEdge';
        node?: {
          __typename: 'StockVideoLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          stockVideoLibraryVideos: {
            __typename: 'StockVideoLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'StockVideoLibraryVideoNodeEdge';
              node?: {
                __typename: 'StockVideoLibraryVideoNode';
                id: string;
                guid: string;
                removedAt?: string | null;
                updatedAt: string;
                stockAsset: {
                  __typename: 'StockVideoAssetNode';
                  id: string;
                  source?: string | null;
                  sourceAssetId: string;
                  width?: number | null;
                  height?: number | null;
                  length?: number | null;
                  vpsKey?: string | null;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
    accountGroups: {
      __typename: 'AccountGroupRelayNodeConnection';
      edges: Array<{
        __typename: 'AccountGroupRelayNodeEdge';
        node?: {
          __typename: 'AccountGroupRelayNode';
          id: string;
          videoAssetLibraries: {
            __typename: 'VideoAssetLibraryNodeConnection';
            edges: Array<{
              __typename: 'VideoAssetLibraryNodeEdge';
              node?: {
                __typename: 'VideoAssetLibraryNode';
                id: string;
                pk?: number | null;
                slug: string;
                displayName: string;
                videoAssets?: {
                  __typename: 'VideoAssetLibraryVideoNodeConnection';
                  edges: Array<{
                    __typename: 'VideoAssetLibraryVideoNodeEdge';
                    node?: {
                      __typename: 'VideoAssetLibraryVideoNode';
                      id: string;
                      pk?: number | null;
                      guid: string;
                      uploadKey: string;
                      displayName?: string | null;
                      width?: number | null;
                      height?: number | null;
                      length?: number | null;
                      order: number;
                      updatedAt: string;
                      removedAt?: string | null;
                    } | null;
                  } | null>;
                } | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type BusinessVideoDetailsByGuidQueryVariables = Exact<{
  businessGUID: Scalars['String']['input'];
}>;

export type BusinessVideoDetailsByGuidQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    videoLibraries: {
      __typename: 'VideoAssetLibraryNodeConnection';
      edges: Array<{
        __typename: 'VideoAssetLibraryNodeEdge';
        node?: {
          __typename: 'VideoAssetLibraryNode';
          id: string;
          pk?: number | null;
          slug: string;
          displayName: string;
          videoAssets?: {
            __typename: 'VideoAssetLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'VideoAssetLibraryVideoNodeEdge';
              node?: {
                __typename: 'VideoAssetLibraryVideoNode';
                id: string;
                pk?: number | null;
                guid: string;
                uploadKey: string;
                displayName?: string | null;
                width?: number | null;
                height?: number | null;
                length?: number | null;
                order: number;
                updatedAt: string;
                removedAt?: string | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    stockVideoLibraries: {
      __typename: 'StockVideoLibraryNodeConnection';
      edges: Array<{
        __typename: 'StockVideoLibraryNodeEdge';
        node?: {
          __typename: 'StockVideoLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          stockVideoLibraryVideos: {
            __typename: 'StockVideoLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'StockVideoLibraryVideoNodeEdge';
              node?: {
                __typename: 'StockVideoLibraryVideoNode';
                id: string;
                guid: string;
                removedAt?: string | null;
                updatedAt: string;
                stockAsset: {
                  __typename: 'StockVideoAssetNode';
                  id: string;
                  source?: string | null;
                  sourceAssetId: string;
                  width?: number | null;
                  height?: number | null;
                  length?: number | null;
                  vpsKey?: string | null;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type AccountStockVideoLibraryAssetKeysQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountStockVideoLibraryAssetKeysQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    stockVideoLibraries: {
      __typename: 'StockVideoLibraryNodeConnection';
      edges: Array<{
        __typename: 'StockVideoLibraryNodeEdge';
        node?: {
          __typename: 'StockVideoLibraryNode';
          stockVideoLibraryVideos: {
            __typename: 'StockVideoLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'StockVideoLibraryVideoNodeEdge';
              node?: {
                __typename: 'StockVideoLibraryVideoNode';
                stockAsset: {
                  __typename: 'StockVideoAssetNode';
                  id: string;
                  vpsKey?: string | null;
                  sourceAssetId: string;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type BusinessStockVideoLibraryAssetKeysQueryVariables = Exact<{
  businessGUID: Scalars['String']['input'];
}>;

export type BusinessStockVideoLibraryAssetKeysQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    stockVideoLibraries: {
      __typename: 'StockVideoLibraryNodeConnection';
      edges: Array<{
        __typename: 'StockVideoLibraryNodeEdge';
        node?: {
          __typename: 'StockVideoLibraryNode';
          stockVideoLibraryVideos: {
            __typename: 'StockVideoLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'StockVideoLibraryVideoNodeEdge';
              node?: {
                __typename: 'StockVideoLibraryVideoNode';
                stockAsset: {
                  __typename: 'StockVideoAssetNode';
                  id: string;
                  vpsKey?: string | null;
                  sourceAssetId: string;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type CreateAccountVideoLibraryVideoMutationVariables = Exact<{
  input: CreateAccountVideoLibraryVideoMutationInput;
}>;

export type CreateAccountVideoLibraryVideoMutation = {
  __typename: 'Mutation';
  createAccountVideoLibraryVideo?: {
    __typename: 'CreateAccountVideoLibraryVideoMutationPayload';
    videoLibraryVideo?: {
      __typename: 'VideoAssetLibraryVideoNode';
      id: string;
      pk?: number | null;
      guid: string;
      uploadKey: string;
      displayName?: string | null;
      width?: number | null;
      height?: number | null;
      length?: number | null;
      order: number;
      updatedAt: string;
      removedAt?: string | null;
    } | null;
  } | null;
};

export type CreateBusinessVideoLibraryVideoMutationVariables = Exact<{
  input: CreateBusinessVideoLibraryVideoMutationInput;
}>;

export type CreateBusinessVideoLibraryVideoMutation = {
  __typename: 'Mutation';
  createBusinessVideoLibraryVideo?: {
    __typename: 'CreateBusinessVideoLibraryVideoMutationPayload';
    videoLibraryVideo?: {
      __typename: 'VideoAssetLibraryVideoNode';
      id: string;
      pk?: number | null;
      guid: string;
      uploadKey: string;
      displayName?: string | null;
      width?: number | null;
      height?: number | null;
      length?: number | null;
      order: number;
      updatedAt: string;
      removedAt?: string | null;
    } | null;
  } | null;
};

export type UpdateVideoLibraryVideoMutationVariables = Exact<{
  input: UpdateVideoLibraryVideoMutationInput;
}>;

export type UpdateVideoLibraryVideoMutation = {
  __typename: 'Mutation';
  updateVideoLibraryVideo?: {
    __typename: 'UpdateVideoLibraryVideoMutationPayload';
    updatedVideo?: {
      __typename: 'VideoAssetLibraryVideoNode';
      id: string;
      pk?: number | null;
      guid: string;
      uploadKey: string;
      displayName?: string | null;
      width?: number | null;
      height?: number | null;
      length?: number | null;
      order: number;
      updatedAt: string;
      removedAt?: string | null;
    } | null;
  } | null;
};

export type AccountAudioAssetFieldsFragment = {
  __typename: 'AccountAudioAssetNode';
  id: string;
  guid: string;
  uploadKey: string;
  length?: number | null;
  displayName?: string | null;
  source?: string | null;
};

export type AccountAudioAssetForUploadKeyQueryVariables = Exact<{
  uploadKey: Scalars['String']['input'];
}>;

export type AccountAudioAssetForUploadKeyQuery = {
  __typename: 'Query';
  accountAudioAssetForUploadKey?: {
    __typename: 'AccountAudioAssetNode';
    id: string;
    guid: string;
    uploadKey: string;
    length?: number | null;
    displayName?: string | null;
    source?: string | null;
  } | null;
};

export type GetOrCreateDefaultAccountFontLibraryMutationVariables = Exact<{
  input: GetOrCreateDefaultAccountFontLibraryMutationInput;
}>;

export type GetOrCreateDefaultAccountFontLibraryMutation = {
  __typename: 'Mutation';
  getOrCreateDefaultAccountFontLibrary?: {
    __typename: 'GetOrCreateDefaultAccountFontLibraryMutationPayload';
    fontLibrary?: {
      __typename: 'FontLibraryNode';
      id: string;
      guid: string;
      displayName: string;
      fonts: {
        __typename: 'FontLibraryFontNodeConnection';
        edges: Array<{
          __typename: 'FontLibraryFontNodeEdge';
          node?: {
            __typename: 'FontLibraryFontNode';
            id: string;
            guid: string;
            displayName: string;
            order?: number | null;
            updatedAt: string;
            font: {
              __typename: 'TemplateFontNode';
              id: string;
              bfsUuid?: string | null;
              fontFamilyName: string;
              variants: Array<{
                __typename: 'BFSFontVariant';
                uuid: string;
                isItalic: boolean;
                weightIndex: number;
              }>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GetOrCreateDefaultBusinessFontLibraryMutationVariables = Exact<{
  input: GetOrCreateDefaultBusinessFontLibraryMutationInput;
}>;

export type GetOrCreateDefaultBusinessFontLibraryMutation = {
  __typename: 'Mutation';
  getOrCreateDefaultBusinessFontLibrary?: {
    __typename: 'GetOrCreateDefaultBusinessFontLibraryMutationPayload';
    fontLibrary?: {
      __typename: 'FontLibraryNode';
      id: string;
      guid: string;
      displayName: string;
      fonts: {
        __typename: 'FontLibraryFontNodeConnection';
        edges: Array<{
          __typename: 'FontLibraryFontNodeEdge';
          node?: {
            __typename: 'FontLibraryFontNode';
            id: string;
            guid: string;
            displayName: string;
            order?: number | null;
            updatedAt: string;
            font: {
              __typename: 'TemplateFontNode';
              id: string;
              bfsUuid?: string | null;
              fontFamilyName: string;
              variants: Array<{
                __typename: 'BFSFontVariant';
                uuid: string;
                isItalic: boolean;
                weightIndex: number;
              }>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AddFontToFontLibraryMutationVariables = Exact<{
  input: AddFontToFontLibraryMutationInput;
}>;

export type AddFontToFontLibraryMutation = {
  __typename: 'Mutation';
  addFontToFontLibrary?: {
    __typename: 'AddFontToFontLibraryMutationPayload';
    createdFontLibraryFont?: {
      __typename: 'FontLibraryFontNode';
      id: string;
      guid: string;
      displayName: string;
      order?: number | null;
      updatedAt: string;
      font: {
        __typename: 'TemplateFontNode';
        id: string;
        bfsUuid?: string | null;
        fontFamilyName: string;
        variants: Array<{
          __typename: 'BFSFontVariant';
          uuid: string;
          isItalic: boolean;
          weightIndex: number;
        }>;
      };
    } | null;
    updatedFontLibrary?: { __typename: 'FontLibraryNode'; id: string } | null;
  } | null;
};

export type RemoveFontFromFontLibraryMutationVariables = Exact<{
  input: RemoveFontFromFontLibraryMutationInput;
}>;

export type RemoveFontFromFontLibraryMutation = {
  __typename: 'Mutation';
  removeFontFromFontLibrary?: {
    __typename: 'RemoveFontFromFontLibraryMutationPayload';
    removedFontLibraryFontId?: string | null;
  } | null;
};

export type BfsFontVariantFieldsFragment = {
  __typename: 'BFSFontVariant';
  uuid: string;
  isItalic: boolean;
  weightIndex: number;
};

export type TemplateFontFieldsFragment = {
  __typename: 'TemplateFontNode';
  id: string;
  bfsUuid?: string | null;
  fontFamilyName: string;
  variants: Array<{
    __typename: 'BFSFontVariant';
    uuid: string;
    isItalic: boolean;
    weightIndex: number;
  }>;
};

export type FontLibraryFontFieldsFragment = {
  __typename: 'FontLibraryFontNode';
  id: string;
  guid: string;
  displayName: string;
  order?: number | null;
  updatedAt: string;
  font: {
    __typename: 'TemplateFontNode';
    id: string;
    bfsUuid?: string | null;
    fontFamilyName: string;
    variants: Array<{
      __typename: 'BFSFontVariant';
      uuid: string;
      isItalic: boolean;
      weightIndex: number;
    }>;
  };
};

export type FontLibraryFieldsFragment = {
  __typename: 'FontLibraryNode';
  id: string;
  guid: string;
  displayName: string;
  fonts: {
    __typename: 'FontLibraryFontNodeConnection';
    edges: Array<{
      __typename: 'FontLibraryFontNodeEdge';
      node?: {
        __typename: 'FontLibraryFontNode';
        id: string;
        guid: string;
        displayName: string;
        order?: number | null;
        updatedAt: string;
        font: {
          __typename: 'TemplateFontNode';
          id: string;
          bfsUuid?: string | null;
          fontFamilyName: string;
          variants: Array<{
            __typename: 'BFSFontVariant';
            uuid: string;
            isItalic: boolean;
            weightIndex: number;
          }>;
        };
      } | null;
    } | null>;
  };
};

export type FontLibrariesForBusinessQueryVariables = Exact<{
  businessGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type FontLibrariesForBusinessQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    businessName: string;
    guid: string;
    fontLibraries: {
      __typename: 'FontLibraryNodeConnection';
      edges: Array<{
        __typename: 'FontLibraryNodeEdge';
        node?: {
          __typename: 'FontLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          fonts: {
            __typename: 'FontLibraryFontNodeConnection';
            edges: Array<{
              __typename: 'FontLibraryFontNodeEdge';
              node?: {
                __typename: 'FontLibraryFontNode';
                id: string;
                guid: string;
                displayName: string;
                order?: number | null;
                updatedAt: string;
                font: {
                  __typename: 'TemplateFontNode';
                  id: string;
                  bfsUuid?: string | null;
                  fontFamilyName: string;
                  variants: Array<{
                    __typename: 'BFSFontVariant';
                    uuid: string;
                    isItalic: boolean;
                    weightIndex: number;
                  }>;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type FontLibrariesForAccountQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type FontLibrariesForAccountQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    displayName?: string | null;
    guid: string;
    fontLibraries: {
      __typename: 'FontLibraryNodeConnection';
      edges: Array<{
        __typename: 'FontLibraryNodeEdge';
        node?: {
          __typename: 'FontLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          fonts: {
            __typename: 'FontLibraryFontNodeConnection';
            edges: Array<{
              __typename: 'FontLibraryFontNodeEdge';
              node?: {
                __typename: 'FontLibraryFontNode';
                id: string;
                guid: string;
                displayName: string;
                order?: number | null;
                updatedAt: string;
                font: {
                  __typename: 'TemplateFontNode';
                  id: string;
                  bfsUuid?: string | null;
                  fontFamilyName: string;
                  variants: Array<{
                    __typename: 'BFSFontVariant';
                    uuid: string;
                    isItalic: boolean;
                    weightIndex: number;
                  }>;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
    accountGroups: {
      __typename: 'AccountGroupRelayNodeConnection';
      edges: Array<{
        __typename: 'AccountGroupRelayNodeEdge';
        node?: {
          __typename: 'AccountGroupRelayNode';
          id: string;
          displayName: string;
          fontLibraries: {
            __typename: 'FontLibraryNodeConnection';
            edges: Array<{
              __typename: 'FontLibraryNodeEdge';
              node?: {
                __typename: 'FontLibraryNode';
                id: string;
                guid: string;
                displayName: string;
                fonts: {
                  __typename: 'FontLibraryFontNodeConnection';
                  edges: Array<{
                    __typename: 'FontLibraryFontNodeEdge';
                    node?: {
                      __typename: 'FontLibraryFontNode';
                      id: string;
                      guid: string;
                      displayName: string;
                      order?: number | null;
                      updatedAt: string;
                      font: {
                        __typename: 'TemplateFontNode';
                        id: string;
                        bfsUuid?: string | null;
                        fontFamilyName: string;
                        variants: Array<{
                          __typename: 'BFSFontVariant';
                          uuid: string;
                          isItalic: boolean;
                          weightIndex: number;
                        }>;
                      };
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type GlobalFontLibrariesQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalFontLibrariesQuery = {
  __typename: 'Query';
  globalFontLibraries?: {
    __typename: 'FontLibraryNodeConnection';
    edges: Array<{
      __typename: 'FontLibraryNodeEdge';
      node?: {
        __typename: 'FontLibraryNode';
        id: string;
        guid: string;
        displayName: string;
        fonts: {
          __typename: 'FontLibraryFontNodeConnection';
          edges: Array<{
            __typename: 'FontLibraryFontNodeEdge';
            node?: {
              __typename: 'FontLibraryFontNode';
              id: string;
              guid: string;
              displayName: string;
              order?: number | null;
              updatedAt: string;
              font: {
                __typename: 'TemplateFontNode';
                id: string;
                bfsUuid?: string | null;
                fontFamilyName: string;
                variants: Array<{
                  __typename: 'BFSFontVariant';
                  uuid: string;
                  isItalic: boolean;
                  weightIndex: number;
                }>;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type BusinessImageDataQueryVariables = Exact<{
  businessGUID: Scalars['String']['input'];
}>;

export type BusinessImageDataQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    totalImageCount?: number | null;
    logoImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      guid: string;
      baseUrl?: string | null;
      updatedAt: string;
    } | null;
  } | null;
};

export type AccountImageLibrariesQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountImageLibrariesQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    guid: string;
    displayName?: string | null;
    imageLibraries: {
      __typename: 'ImageLibraryNodeConnection';
      edges: Array<{
        __typename: 'ImageLibraryNodeEdge';
        node?: {
          __typename: 'ImageLibraryNode';
          id: string;
          pk?: number | null;
          slug: string;
          displayName: string;
          images?: {
            __typename: 'ImageLibraryImageNodeConnection';
            edges: Array<{
              __typename: 'ImageLibraryImageNodeEdge';
              node?: {
                __typename: 'ImageLibraryImageNode';
                id: string;
                pk?: number | null;
                guid: string;
                displayName?: string | null;
                image: string;
                order: number;
                source?: string | null;
                imageType?: string | null;
                isFavorite: boolean;
                createdAt: string;
                removedAt?: string | null;
                updatedAt: string;
                lastUsedAt?: string | null;
                baseUrl?: string | null;
                imageWidth?: number | null;
                imageHeight?: number | null;
                upscaledImageUrl?: string | null;
                stockAssetId?: string | null;
                stockSearchId?: string | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
    accountGroups: {
      __typename: 'AccountGroupRelayNodeConnection';
      edges: Array<{
        __typename: 'AccountGroupRelayNodeEdge';
        node?: {
          __typename: 'AccountGroupRelayNode';
          id: string;
          displayName: string;
          imageLibraries: {
            __typename: 'ImageLibraryNodeConnection';
            edges: Array<{
              __typename: 'ImageLibraryNodeEdge';
              node?: {
                __typename: 'ImageLibraryNode';
                id: string;
                pk?: number | null;
                slug: string;
                displayName: string;
                images?: {
                  __typename: 'ImageLibraryImageNodeConnection';
                  edges: Array<{
                    __typename: 'ImageLibraryImageNodeEdge';
                    node?: {
                      __typename: 'ImageLibraryImageNode';
                      id: string;
                      pk?: number | null;
                      guid: string;
                      displayName?: string | null;
                      image: string;
                      order: number;
                      source?: string | null;
                      imageType?: string | null;
                      isFavorite: boolean;
                      createdAt: string;
                      removedAt?: string | null;
                      updatedAt: string;
                      lastUsedAt?: string | null;
                      baseUrl?: string | null;
                      imageWidth?: number | null;
                      imageHeight?: number | null;
                      upscaledImageUrl?: string | null;
                      stockAssetId?: string | null;
                      stockSearchId?: string | null;
                    } | null;
                  } | null>;
                } | null;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type BusinessImageLibrariesQueryVariables = Exact<{
  businessGUID: Scalars['String']['input'];
}>;

export type BusinessImageLibrariesQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    totalImageCount?: number | null;
    businessName: string;
    logoImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      guid: string;
      baseUrl?: string | null;
      updatedAt: string;
    } | null;
    imageLibraries: {
      __typename: 'ImageLibraryNodeConnection';
      edges: Array<{
        __typename: 'ImageLibraryNodeEdge';
        node?: {
          __typename: 'ImageLibraryNode';
          id: string;
          pk?: number | null;
          slug: string;
          displayName: string;
          images?: {
            __typename: 'ImageLibraryImageNodeConnection';
            edges: Array<{
              __typename: 'ImageLibraryImageNodeEdge';
              node?: {
                __typename: 'ImageLibraryImageNode';
                id: string;
                pk?: number | null;
                guid: string;
                displayName?: string | null;
                image: string;
                order: number;
                source?: string | null;
                imageType?: string | null;
                isFavorite: boolean;
                createdAt: string;
                removedAt?: string | null;
                updatedAt: string;
                lastUsedAt?: string | null;
                baseUrl?: string | null;
                imageWidth?: number | null;
                imageHeight?: number | null;
                upscaledImageUrl?: string | null;
                stockAssetId?: string | null;
                stockSearchId?: string | null;
              } | null;
            } | null>;
          } | null;
        } | null;
      } | null>;
    };
  } | null;
};

export type UpdateUserVideoMutationVariables = Exact<{
  input: UpdateUserVideoMutationInput;
}>;

export type UpdateUserVideoMutation = {
  __typename: 'Mutation';
  updateUserVideo?: {
    __typename: 'UpdateUserVideoMutationPayload';
    updatedUserVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      title: string;
      videoConfiguration: string;
      business?: {
        __typename: 'BusinessRelayNode';
        id: string;
        pk?: number | null;
        createdAt: string;
        guid: string;
        isVisible: boolean;
        businessName: string;
        businessAbout?: string | null;
        websiteUrl: string;
        streetAddress1: string;
        streetAddress2: string;
        city: string;
        state: string;
        postalCode: string;
        contactPhone: string;
        industryName?: string | null;
        totalImageCount?: number | null;
        totalVideoCount?: number | null;
        logoImage?: {
          __typename: 'ImageLibraryImageNode';
          id: string;
          pk?: number | null;
          guid: string;
          displayName?: string | null;
          image: string;
          order: number;
          source?: string | null;
          imageType?: string | null;
          isFavorite: boolean;
          createdAt: string;
          removedAt?: string | null;
          updatedAt: string;
          lastUsedAt?: string | null;
          baseUrl?: string | null;
          imageWidth?: number | null;
          imageHeight?: number | null;
          upscaledImageUrl?: string | null;
          stockAssetId?: string | null;
          stockSearchId?: string | null;
        } | null;
        colorLibraries: {
          __typename: 'ColorLibraryNodeConnection';
          edges: Array<{
            __typename: 'ColorLibraryNodeEdge';
            node?: {
              __typename: 'ColorLibraryNode';
              id: string;
              guid: string;
              displayName: string;
              colors: {
                __typename: 'ColorLibraryColorNodeConnection';
                edges: Array<{
                  __typename: 'ColorLibraryColorNodeEdge';
                  node?: {
                    __typename: 'ColorLibraryColorNode';
                    id: string;
                    order: number;
                    displayName?: string | null;
                    hexCode: string;
                    updatedAt: string;
                  } | null;
                } | null>;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null;
  } | null;
};

export type DeleteUserVideoMutationVariables = Exact<{
  input: DeleteUserVideoMutationInput;
}>;

export type DeleteUserVideoMutation = {
  __typename: 'Mutation';
  deleteUserVideo?: {
    __typename: 'DeleteUserVideoMutationPayload';
    deletedUserVideo?: { __typename: 'UserVideoNode'; id: string; guid: string } | null;
  } | null;
};

export type CreateUserVideoMutationVariables = Exact<{
  input: CreateUserVideoMutationInput;
}>;

export type CreateUserVideoMutation = {
  __typename: 'Mutation';
  createUserVideo?: {
    __typename: 'CreateUserVideoMutationPayload';
    userVideo?: { __typename: 'UserVideoNode'; id: string; guid: string } | null;
  } | null;
};

export type RenderUserVideoMutationVariables = Exact<{
  input: RenderUserVideoMutationInput;
}>;

export type RenderUserVideoMutation = {
  __typename: 'Mutation';
  renderUserVideo?: {
    __typename: 'RenderUserVideoMutationPayload';
    userVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      title: string;
      createdAt: string;
      updatedAt: string;
      purchasedAt?: string | null;
      lastEditedByUser?: string | null;
      lastEditableDate?: string | null;
      omniType?: string | null;
      business?: {
        __typename: 'BusinessRelayNode';
        id: string;
        guid: string;
        businessName: string;
      } | null;
      videoTemplateVariant: {
        __typename: 'VideoTemplateVariantRelayNode';
        id: string;
        guid: string;
        displayName: string;
        displayDuration?: number | null;
        width: number;
        height: number;
        isEnabled: boolean;
        videoTemplate: {
          __typename: 'VideoTemplateNode';
          id: string;
          isWaymarkAuthorTemplate: boolean;
          isRetired: boolean;
        };
      };
      currentConfiguredVideo?: {
        __typename: 'ConfiguredVideoNode';
        id: string;
        thumbnailUrl?: string | null;
      } | null;
      purchaseApproval?: {
        __typename: 'UserVideoPurchaseApprovalNode';
        id: string;
        guid: string;
        createdAt: string;
        approvedAt?: string | null;
      } | null;
      userVideoApprovals?: Array<{
        __typename: 'UserVideoApprovalNode';
        id: string;
        guid: string;
        createdAt: string;
        fullName: string;
        approvedAt?: string | null;
      }> | null;
      voiceOverProduct?: {
        __typename: 'VideoVoiceOverProduct';
        id: string;
        isDelivered: boolean;
      } | null;
      derivedUserVideos?: {
        __typename: 'UserVideoNodeConnection';
        totalCount?: number | null;
      } | null;
      originalUserVideo?: {
        __typename: 'UserVideoNode';
        id: string;
        guid: string;
        title: string;
      } | null;
    } | null;
  } | null;
};

export type CreateUserVideoApprovalRequestMutationVariables = Exact<{
  input: CreateUserVideoApprovalRequestMutationInput;
}>;

export type CreateUserVideoApprovalRequestMutation = {
  __typename: 'Mutation';
  createUserVideoApprovalRequest?: {
    __typename: 'CreateUserVideoApprovalRequestMutationPayload';
    userVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      userVideoApprovals?: Array<{
        __typename: 'UserVideoApprovalNode';
        id: string;
        guid: string;
        createdAt: string;
        fullName: string;
        approvedAt?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type ApproveUserVideoMutationVariables = Exact<{
  input: ApproveUserVideoMutationInput;
}>;

export type ApproveUserVideoMutation = {
  __typename: 'Mutation';
  approveUserVideo?: {
    __typename: 'ApproveUserVideoMutationPayload';
    userVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      userVideoApprovals?: Array<{
        __typename: 'UserVideoApprovalNode';
        id: string;
        guid: string;
        createdAt: string;
        fullName: string;
        approvedAt?: string | null;
      }> | null;
    } | null;
  } | null;
};

export type MostRecentlyEditedUserVideoInfoQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
}>;

export type MostRecentlyEditedUserVideoInfoQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    lastEditedUserVideo?: {
      __typename: 'UserVideoNodeConnection';
      edges: Array<{
        __typename: 'UserVideoNodeEdge';
        node?: {
          __typename: 'UserVideoNode';
          id: string;
          guid: string;
          lastEditedByUser?: string | null;
        } | null;
      } | null>;
    } | null;
    lastPurchasedUserVideo?: {
      __typename: 'UserVideoNodeConnection';
      edges: Array<{
        __typename: 'UserVideoNodeEdge';
        node?: {
          __typename: 'UserVideoNode';
          id: string;
          guid: string;
          purchasedAt?: string | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type UserVideoRenderedVideoFragment = {
  __typename: 'RenderedVideoNode';
  id: string;
  renderUrl?: string | null;
  renderFormat?: string | null;
  renderStatus?: string | null;
  renderSize?: string | null;
  hasWatermark?: boolean | null;
};

export type UserVideoRendersQueryVariables = Exact<{
  userVideoGUID: Scalars['String']['input'];
}>;

export type UserVideoRendersQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    currentConfiguredVideo?: {
      __typename: 'ConfiguredVideoNode';
      id: string;
      renderStatus?: string | null;
      renderedVideos: {
        __typename: 'RenderedVideoNodeConnection';
        edges: Array<{
          __typename: 'RenderedVideoNodeEdge';
          node?: {
            __typename: 'RenderedVideoNode';
            id: string;
            renderUrl?: string | null;
            renderFormat?: string | null;
            renderStatus?: string | null;
            renderSize?: string | null;
            hasWatermark?: boolean | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AccountPartnersQueryVariables = Exact<{
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type AccountPartnersQuery = {
  __typename: 'Query';
  partners?: {
    __typename: 'AccountPartnerRelayNodeConnection';
    edges: Array<{
      __typename: 'AccountPartnerRelayNodeEdge';
      node?: {
        __typename: 'AccountPartnerRelayNode';
        id: string;
        guid: string;
        name: string;
        slug: string;
      } | null;
    } | null>;
  } | null;
};

export type SelectedAccountPartnerQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
}>;

export type SelectedAccountPartnerQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    partner?: {
      __typename: 'AccountPartnerRelayNode';
      id: string;
      guid: string;
      name: string;
      slug: string;
    } | null;
  } | null;
};

export type ManagedAccountListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  accountGroupGUID?: InputMaybe<Scalars['String']['input']>;
  hasNoAccountGroup?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  partnerGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type ManagedAccountListQuery = {
  __typename: 'Query';
  accounts?: {
    __typename: 'AccountRelayNodeConnection';
    totalCount?: number | null;
    pageInfo: { __typename: 'PageInfo'; endCursor?: string | null };
    edges: Array<{
      __typename: 'AccountRelayNodeEdge';
      node?: {
        __typename: 'AccountRelayNode';
        totalVideosCreated?: number | null;
        totalVideosCompleted?: number | null;
        id: string;
        pk?: number | null;
        createdAt: string;
        guid: string;
        displayName?: string | null;
        firstName: string;
        lastName: string;
        companyName: string;
        emailAddress: string;
        role?: string | null;
        lastLoginDate?: string | null;
        externalId: string;
        isActive: boolean;
        accountGroups: {
          __typename: 'AccountGroupRelayNodeConnection';
          edges: Array<{
            __typename: 'AccountGroupRelayNodeEdge';
            node?: {
              __typename: 'AccountGroupRelayNode';
              id: string;
              guid: string;
              displayName: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type RefreshBusinessStockVideoLibraryQueryVariables = Exact<{
  businessGUID: Scalars['String']['input'];
}>;

export type RefreshBusinessStockVideoLibraryQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    totalVideoCount?: number | null;
    stockVideoLibraries: {
      __typename: 'StockVideoLibraryNodeConnection';
      edges: Array<{
        __typename: 'StockVideoLibraryNodeEdge';
        node?: {
          __typename: 'StockVideoLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          stockVideoLibraryVideos: {
            __typename: 'StockVideoLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'StockVideoLibraryVideoNodeEdge';
              node?: {
                __typename: 'StockVideoLibraryVideoNode';
                id: string;
                guid: string;
                removedAt?: string | null;
                updatedAt: string;
                stockAsset: {
                  __typename: 'StockVideoAssetNode';
                  id: string;
                  source?: string | null;
                  sourceAssetId: string;
                  width?: number | null;
                  height?: number | null;
                  length?: number | null;
                  vpsKey?: string | null;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type RefreshAccountStockVideoLibraryQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
}>;

export type RefreshAccountStockVideoLibraryQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    guid: string;
    stockVideoLibraries: {
      __typename: 'StockVideoLibraryNodeConnection';
      edges: Array<{
        __typename: 'StockVideoLibraryNodeEdge';
        node?: {
          __typename: 'StockVideoLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          stockVideoLibraryVideos: {
            __typename: 'StockVideoLibraryVideoNodeConnection';
            edges: Array<{
              __typename: 'StockVideoLibraryVideoNodeEdge';
              node?: {
                __typename: 'StockVideoLibraryVideoNode';
                id: string;
                guid: string;
                removedAt?: string | null;
                updatedAt: string;
                stockAsset: {
                  __typename: 'StockVideoAssetNode';
                  id: string;
                  source?: string | null;
                  sourceAssetId: string;
                  width?: number | null;
                  height?: number | null;
                  length?: number | null;
                  vpsKey?: string | null;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type AccountIntercomInfoQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
}>;

export type AccountIntercomInfoQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    guid: string;
    displayName?: string | null;
    emailAddress: string;
    partner?: {
      __typename: 'AccountPartnerRelayNode';
      id: string;
      slug: string;
      name: string;
    } | null;
    subscriptions: {
      __typename: 'SubscriptionRelayNodeConnection';
      edges: Array<{
        __typename: 'SubscriptionRelayNodeEdge';
        node?: {
          __typename: 'SubscriptionRelayNode';
          id: string;
          status?: string | null;
          subscriptionPlan: { __typename: 'SubscriptionPlan'; id: string; slug: string };
        } | null;
      } | null>;
    };
  } | null;
};

export type EditorTemplateAudioTrackFragmentFragment = {
  __typename: 'VideoTemplateAudioNode';
  id: string;
  pk: number;
  displayName: string;
  audioUrl: string;
  audioPath: string;
};

export type MusicTabTemplateAudioTracksQueryVariables = Exact<{
  variantGUID: Scalars['String']['input'];
}>;

export type MusicTabTemplateAudioTracksQuery = {
  __typename: 'Query';
  videoTemplateAudioForVariantGuid?: Array<{
    __typename: 'VideoTemplateAudioNode';
    id: string;
    pk: number;
    displayName: string;
    audioUrl: string;
    audioPath: string;
  } | null> | null;
};

export type MusicTabGlobalAudioLibrariesQueryVariables = Exact<{
  duration?: InputMaybe<Scalars['Float']['input']>;
}>;

export type MusicTabGlobalAudioLibrariesQuery = {
  __typename: 'Query';
  globalAudioLibraries?: {
    __typename: 'VideoTemplateGlobalAudioLibraryNodeConnection';
    edges: Array<{
      __typename: 'VideoTemplateGlobalAudioLibraryNodeEdge';
      node?: {
        __typename: 'VideoTemplateGlobalAudioLibraryNode';
        id: string;
        slug: string;
        displayName: string;
        globalAudio: {
          __typename: 'VideoTemplateGlobalAudioNodeConnection';
          edges: Array<{
            __typename: 'VideoTemplateGlobalAudioNodeEdge';
            node?: {
              __typename: 'VideoTemplateGlobalAudioNode';
              videoTemplateAudio: {
                __typename: 'VideoTemplateAudioNode';
                id: string;
                pk: number;
                displayName: string;
                audioUrl: string;
                audioPath: string;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type UpscaledImageDataRefetchQueryVariables = Exact<{
  imageLibraryImageGUID: Scalars['String']['input'];
}>;

export type UpscaledImageDataRefetchQuery = {
  __typename: 'Query';
  imageLibraryImageByGuid?: {
    __typename: 'ImageLibraryImageNode';
    id: string;
    guid: string;
    upscaledImageUrl?: string | null;
  } | null;
};

export type PartnersQueryVariables = Exact<{ [key: string]: never }>;

export type PartnersQuery = {
  __typename: 'Query';
  partners?: {
    __typename: 'AccountPartnerRelayNodeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename: 'AccountPartnerRelayNodeEdge';
      node?: {
        __typename: 'AccountPartnerRelayNode';
        id: string;
        name: string;
        slug: string;
        emailDomain?: string | null;
        canUsersPurchase: boolean;
        hasVoiceOver: boolean;
        maxActiveUsers?: number | null;
        maxMonthlyVideos?: number | null;
        hasUnlimited: boolean;
        shouldHideBusinessPersonalization: boolean;
        isTvOnlyIntegration: boolean;
        accountGroups: {
          __typename: 'AccountGroupRelayNodeConnection';
          edges: Array<{
            __typename: 'AccountGroupRelayNodeEdge';
            node?: {
              __typename: 'AccountGroupRelayNode';
              id: string;
              guid: string;
              displayName: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type PartnerStatsBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;

export type PartnerStatsBySlugQuery = {
  __typename: 'Query';
  partners?: {
    __typename: 'AccountPartnerRelayNodeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename: 'AccountPartnerRelayNodeEdge';
      node?: {
        __typename: 'AccountPartnerRelayNode';
        id: string;
        visitCount?: number | null;
        draftVideoCount?: number | null;
        generatedVideoCount?: number | null;
        purchasedVideoCount?: number | null;
        renderedVideoCount?: number | null;
      } | null;
    } | null>;
  } | null;
};

export type PartnerAccountGroupStatsBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  accountGroupGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type PartnerAccountGroupStatsBySlugQuery = {
  __typename: 'Query';
  partners?: {
    __typename: 'AccountPartnerRelayNodeConnection';
    totalCount?: number | null;
    edges: Array<{
      __typename: 'AccountPartnerRelayNodeEdge';
      node?: {
        __typename: 'AccountPartnerRelayNode';
        id: string;
        accountGroups: {
          __typename: 'AccountGroupRelayNodeConnection';
          edges: Array<{
            __typename: 'AccountGroupRelayNodeEdge';
            node?: {
              __typename: 'AccountGroupRelayNode';
              id: string;
              visitCount?: number | null;
              draftVideoCount?: number | null;
              generatedVideoCount?: number | null;
              purchasedVideoCount?: number | null;
              renderedVideoCount?: number | null;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type PartnerUsageByAccountQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  accountGroupGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type PartnerUsageByAccountQuery = {
  __typename: 'Query';
  partnerUsageByAccount?: Array<{
    __typename: 'AccountUsage';
    id?: number | null;
    emailAddress?: string | null;
    displayName?: string | null;
    visitCount?: number | null;
    draftVideoCount?: number | null;
    purchasedVideoCount?: number | null;
    generatedVideoCount?: number | null;
    renderedVideoCount?: number | null;
  } | null> | null;
};

export type PartnerUsageByAccountGroupQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;

export type PartnerUsageByAccountGroupQuery = {
  __typename: 'Query';
  partnerUsageByAccountGroup?: Array<{
    __typename: 'AccountGroupUsage';
    groupName?: string | null;
    parentName?: string | null;
    visitCount?: number | null;
    draftVideoCount?: number | null;
    purchasedVideoCount?: number | null;
  } | null> | null;
};

export type PartnerVideoDetailsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
  accountGroupGUID?: InputMaybe<Scalars['String']['input']>;
  isPurchased: Scalars['Boolean']['input'];
}>;

export type PartnerVideoDetailsQuery = {
  __typename: 'Query';
  partnerVideosByAccount?: Array<{
    __typename: 'AccountVideoDetail';
    accountId?: number | null;
    accountEmailAddress?: string | null;
    accountDisplayName?: string | null;
    videoCreatedAt?: string | null;
    videoPurchasedAt?: string | null;
    videoTitle?: string | null;
    variantName?: string | null;
    brandName?: string | null;
    aspectRatio?: string | null;
    duration?: number | null;
    licensing?: string | null;
    videoGuid?: string | null;
  } | null> | null;
};

export type UpdateAutofillResultVideoDescriptorMutationVariables = Exact<{
  requestGUID: Scalars['String']['input'];
  videoDescriptorJSON: Scalars['String']['input'];
}>;

export type UpdateAutofillResultVideoDescriptorMutation = {
  __typename: 'Mutation';
  updateAutofillResult?: {
    __typename: 'UpdateAutofillResultMutationPayload';
    updatedAutofillResult?: {
      __typename: 'UserVideoAutofillResultNode';
      id: string;
      videoDescriptor?: string | null;
    } | null;
  } | null;
};

export type CreateAutofillFeedbackMutationVariables = Exact<{
  requestGUID: Scalars['String']['input'];
  rating: Scalars['String']['input'];
  feedbackText: Scalars['String']['input'];
  feedbackChipGUIDs?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type CreateAutofillFeedbackMutation = {
  __typename: 'Mutation';
  createAutofillFeedback?: {
    __typename: 'CreateAutofillFeedbackMutationPayload';
    createdAutofillFeedback?: {
      __typename: 'AutofillFeedbackNode';
      guid: string;
      rating: string;
      feedbackText?: string | null;
      feedbackChips: {
        __typename: 'AutofillFeedbackChipNodeConnection';
        edges: Array<{
          __typename: 'AutofillFeedbackChipNodeEdge';
          node?: {
            __typename: 'AutofillFeedbackChipNode';
            guid: string;
            text: string;
            suggestion: string;
            suggestionTitle: string;
            category: {
              __typename: 'AutofillFeedbackChipCategoryNode';
              guid: string;
              name: string;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateAutofillFeedbackMutationVariables = Exact<{
  requestGUID: Scalars['String']['input'];
  rating: Scalars['String']['input'];
  feedbackText: Scalars['String']['input'];
  feedbackChipGUIDs?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type UpdateAutofillFeedbackMutation = {
  __typename: 'Mutation';
  updateAutofillFeedback?: {
    __typename: 'UpdateAutofillFeedbackMutationPayload';
    updatedAutofillFeedback?: {
      __typename: 'AutofillFeedbackNode';
      guid: string;
      rating: string;
      feedbackText?: string | null;
      feedbackChips: {
        __typename: 'AutofillFeedbackChipNodeConnection';
        edges: Array<{
          __typename: 'AutofillFeedbackChipNodeEdge';
          node?: {
            __typename: 'AutofillFeedbackChipNode';
            guid: string;
            text: string;
            suggestion: string;
            suggestionTitle: string;
            category: {
              __typename: 'AutofillFeedbackChipCategoryNode';
              guid: string;
              name: string;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type AutofillResultFieldsFragment = {
  __typename: 'UserVideoAutofillResultNode';
  id: string;
  pk?: number | null;
  createdAt: string;
  completedAt?: string | null;
  error?: string | null;
  isSpeakerLocked: boolean;
  isTemplateLocked: boolean;
  isVoiceOverLocked: boolean;
  languageCode?: string | null;
  requestGuid: string;
  requestSource?: string | null;
  userInstructions?: string | null;
  vibe?: string | null;
  videoDescriptor?: string | null;
  voiceover?: string | null;
  userVideo?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    title: string;
    videoConfiguration: string;
    createdAt: string;
    updatedAt: string;
    purchasedAt?: string | null;
  } | null;
  videoTemplateVariant?: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    guid: string;
    compoundSlug: string;
    displayDuration?: number | null;
    displayName: string;
    height: number;
    width: number;
    aspectRatio: string;
  } | null;
  business?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    businessName: string;
    industryName?: string | null;
    logoImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      image: string;
      order: number;
      source?: string | null;
      imageType?: string | null;
      isFavorite: boolean;
      createdAt: string;
      removedAt?: string | null;
      updatedAt: string;
      lastUsedAt?: string | null;
      baseUrl?: string | null;
      imageWidth?: number | null;
      imageHeight?: number | null;
      upscaledImageUrl?: string | null;
      stockAssetId?: string | null;
      stockSearchId?: string | null;
    } | null;
  } | null;
};

export type AutofillResultsForAccountQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
  maxAgeDays?: InputMaybe<Scalars['Float']['input']>;
  requestSource?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;

export type AutofillResultsForAccountQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    autofillResults?: {
      __typename: 'UserVideoAutofillResultNodeConnection';
      totalCount?: number | null;
      edges: Array<{
        __typename: 'UserVideoAutofillResultNodeEdge';
        cursor: string;
        node?: {
          __typename: 'UserVideoAutofillResultNode';
          id: string;
          pk?: number | null;
          createdAt: string;
          completedAt?: string | null;
          error?: string | null;
          isSpeakerLocked: boolean;
          isTemplateLocked: boolean;
          isVoiceOverLocked: boolean;
          languageCode?: string | null;
          requestGuid: string;
          requestSource?: string | null;
          userInstructions?: string | null;
          vibe?: string | null;
          videoDescriptor?: string | null;
          voiceover?: string | null;
          userVideo?: {
            __typename: 'UserVideoNode';
            id: string;
            guid: string;
            title: string;
            videoConfiguration: string;
            createdAt: string;
            updatedAt: string;
            purchasedAt?: string | null;
          } | null;
          videoTemplateVariant?: {
            __typename: 'VideoTemplateVariantRelayNode';
            id: string;
            guid: string;
            compoundSlug: string;
            displayDuration?: number | null;
            displayName: string;
            height: number;
            width: number;
            aspectRatio: string;
          } | null;
          business?: {
            __typename: 'BusinessRelayNode';
            id: string;
            guid: string;
            businessName: string;
            industryName?: string | null;
            logoImage?: {
              __typename: 'ImageLibraryImageNode';
              id: string;
              pk?: number | null;
              guid: string;
              displayName?: string | null;
              image: string;
              order: number;
              source?: string | null;
              imageType?: string | null;
              isFavorite: boolean;
              createdAt: string;
              removedAt?: string | null;
              updatedAt: string;
              lastUsedAt?: string | null;
              baseUrl?: string | null;
              imageWidth?: number | null;
              imageHeight?: number | null;
              upscaledImageUrl?: string | null;
              stockAssetId?: string | null;
              stockSearchId?: string | null;
            } | null;
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type AutofillResultByRequestGuidQueryVariables = Exact<{
  requestGUID: Scalars['String']['input'];
}>;

export type AutofillResultByRequestGuidQuery = {
  __typename: 'Query';
  autofillResultByRequestGuid?: {
    __typename: 'UserVideoAutofillResultNode';
    id: string;
    pk?: number | null;
    createdAt: string;
    completedAt?: string | null;
    error?: string | null;
    isSpeakerLocked: boolean;
    isTemplateLocked: boolean;
    isVoiceOverLocked: boolean;
    languageCode?: string | null;
    requestGuid: string;
    requestSource?: string | null;
    userInstructions?: string | null;
    vibe?: string | null;
    videoDescriptor?: string | null;
    voiceover?: string | null;
    userVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      title: string;
      videoConfiguration: string;
      createdAt: string;
      updatedAt: string;
      purchasedAt?: string | null;
    } | null;
    videoTemplateVariant?: {
      __typename: 'VideoTemplateVariantRelayNode';
      id: string;
      guid: string;
      compoundSlug: string;
      displayDuration?: number | null;
      displayName: string;
      height: number;
      width: number;
      aspectRatio: string;
    } | null;
    business?: {
      __typename: 'BusinessRelayNode';
      id: string;
      guid: string;
      businessName: string;
      industryName?: string | null;
      logoImage?: {
        __typename: 'ImageLibraryImageNode';
        id: string;
        pk?: number | null;
        guid: string;
        displayName?: string | null;
        image: string;
        order: number;
        source?: string | null;
        imageType?: string | null;
        isFavorite: boolean;
        createdAt: string;
        removedAt?: string | null;
        updatedAt: string;
        lastUsedAt?: string | null;
        baseUrl?: string | null;
        imageWidth?: number | null;
        imageHeight?: number | null;
        upscaledImageUrl?: string | null;
        stockAssetId?: string | null;
        stockSearchId?: string | null;
      } | null;
    } | null;
  } | null;
};

export type AutofillResultFeedbackQueryVariables = Exact<{
  requestGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AutofillResultFeedbackQuery = {
  __typename: 'Query';
  autofillResultByRequestGuid?: {
    __typename: 'UserVideoAutofillResultNode';
    requestGuid: string;
    autofillFeedback: {
      __typename: 'AutofillFeedbackNodeConnection';
      edges: Array<{
        __typename: 'AutofillFeedbackNodeEdge';
        node?: {
          __typename: 'AutofillFeedbackNode';
          guid: string;
          rating: string;
          feedbackText?: string | null;
          feedbackChips: {
            __typename: 'AutofillFeedbackChipNodeConnection';
            edges: Array<{
              __typename: 'AutofillFeedbackChipNodeEdge';
              node?: {
                __typename: 'AutofillFeedbackChipNode';
                guid: string;
                text: string;
                suggestion: string;
                suggestionTitle: string;
                category: {
                  __typename: 'AutofillFeedbackChipCategoryNode';
                  guid: string;
                  name: string;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type AutofillFeedbackChipsQueryVariables = Exact<{ [key: string]: never }>;

export type AutofillFeedbackChipsQuery = {
  __typename: 'Query';
  autofillFeedbackChipCategories?: Array<{
    __typename: 'AutofillFeedbackChipCategoryNode';
    guid: string;
    name: string;
    feedbackChips: {
      __typename: 'AutofillFeedbackChipNodeConnection';
      edges: Array<{
        __typename: 'AutofillFeedbackChipNodeEdge';
        node?: {
          __typename: 'AutofillFeedbackChipNode';
          guid: string;
          text: string;
          suggestion: string;
          suggestionTitle: string;
        } | null;
      } | null>;
    };
  } | null> | null;
};

export type BusinessSearchBusinessDataUpdateFragment = {
  __typename: 'BusinessRelayNode';
  id: string;
  guid: string;
  pk?: number | null;
  businessName: string;
  contactPhone: string;
  streetAddress1: string;
  city: string;
  state: string;
  postalCode: string;
  businessAbout?: string | null;
  industryName?: string | null;
  websiteUrl: string;
};

export type BusinessSearchColorsCreatedFragment = {
  __typename: 'ColorLibraryColorNode';
  id: string;
  order: number;
  displayName?: string | null;
  hexCode: string;
  updatedAt: string;
  colorLibrary: { __typename: 'ColorLibraryNode'; id: string; guid: string };
};

export type BusinessSearchImagesCreatedFragment = {
  __typename: 'ImageLibraryImageNode';
  id: string;
  pk?: number | null;
  guid: string;
  displayName?: string | null;
  order: number;
  source?: string | null;
  imageType?: string | null;
  isFavorite: boolean;
  removedAt?: string | null;
  updatedAt: string;
  baseUrl?: string | null;
  imageWidth?: number | null;
  imageHeight?: number | null;
  upscaledImageUrl?: string | null;
  imageLibrary: { __typename: 'ImageLibraryNode'; id: string; pk?: number | null };
};

export type CoreBusinessDetailsFragmentFragment = {
  __typename: 'BusinessRelayNode';
  id: string;
  pk?: number | null;
  createdAt: string;
  guid: string;
  isVisible: boolean;
  businessName: string;
  businessAbout?: string | null;
  websiteUrl: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  postalCode: string;
  contactPhone: string;
  industryName?: string | null;
  totalImageCount?: number | null;
  totalVideoCount?: number | null;
  logoImage?: {
    __typename: 'ImageLibraryImageNode';
    id: string;
    pk?: number | null;
    guid: string;
    displayName?: string | null;
    image: string;
    order: number;
    source?: string | null;
    imageType?: string | null;
    isFavorite: boolean;
    createdAt: string;
    removedAt?: string | null;
    updatedAt: string;
    lastUsedAt?: string | null;
    baseUrl?: string | null;
    imageWidth?: number | null;
    imageHeight?: number | null;
    upscaledImageUrl?: string | null;
    stockAssetId?: string | null;
    stockSearchId?: string | null;
  } | null;
  colorLibraries: {
    __typename: 'ColorLibraryNodeConnection';
    edges: Array<{
      __typename: 'ColorLibraryNodeEdge';
      node?: {
        __typename: 'ColorLibraryNode';
        id: string;
        guid: string;
        displayName: string;
        colors: {
          __typename: 'ColorLibraryColorNodeConnection';
          edges: Array<{
            __typename: 'ColorLibraryColorNodeEdge';
            node?: {
              __typename: 'ColorLibraryColorNode';
              id: string;
              order: number;
              displayName?: string | null;
              hexCode: string;
              updatedAt: string;
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type CreateBusinessMutationVariables = Exact<{
  input: CreateBusinessMutationInput;
}>;

export type CreateBusinessMutation = {
  __typename: 'Mutation';
  createBusiness?: {
    __typename: 'CreateBusinessMutationPayload';
    createdBusiness?: {
      __typename: 'BusinessRelayNode';
      id: string;
      pk?: number | null;
      createdAt: string;
      guid: string;
      isVisible: boolean;
      businessName: string;
      businessAbout?: string | null;
      websiteUrl: string;
      streetAddress1: string;
      streetAddress2: string;
      city: string;
      state: string;
      postalCode: string;
      contactPhone: string;
      industryName?: string | null;
      totalImageCount?: number | null;
      totalVideoCount?: number | null;
      logoImage?: {
        __typename: 'ImageLibraryImageNode';
        id: string;
        pk?: number | null;
        guid: string;
        displayName?: string | null;
        image: string;
        order: number;
        source?: string | null;
        imageType?: string | null;
        isFavorite: boolean;
        createdAt: string;
        removedAt?: string | null;
        updatedAt: string;
        lastUsedAt?: string | null;
        baseUrl?: string | null;
        imageWidth?: number | null;
        imageHeight?: number | null;
        upscaledImageUrl?: string | null;
        stockAssetId?: string | null;
        stockSearchId?: string | null;
      } | null;
      colorLibraries: {
        __typename: 'ColorLibraryNodeConnection';
        edges: Array<{
          __typename: 'ColorLibraryNodeEdge';
          node?: {
            __typename: 'ColorLibraryNode';
            id: string;
            guid: string;
            displayName: string;
            colors: {
              __typename: 'ColorLibraryColorNodeConnection';
              edges: Array<{
                __typename: 'ColorLibraryColorNodeEdge';
                node?: {
                  __typename: 'ColorLibraryColorNode';
                  id: string;
                  order: number;
                  displayName?: string | null;
                  hexCode: string;
                  updatedAt: string;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UpdateBusinessMutationVariables = Exact<{
  input: UpdateBusinessMutationInput;
}>;

export type UpdateBusinessMutation = {
  __typename: 'Mutation';
  updateBusiness?: {
    __typename: 'UpdateBusinessMutationPayload';
    updatedBusiness?: {
      __typename: 'BusinessRelayNode';
      id: string;
      pk?: number | null;
      createdAt: string;
      guid: string;
      isVisible: boolean;
      businessName: string;
      businessAbout?: string | null;
      websiteUrl: string;
      streetAddress1: string;
      streetAddress2: string;
      city: string;
      state: string;
      postalCode: string;
      contactPhone: string;
      industryName?: string | null;
      totalImageCount?: number | null;
      totalVideoCount?: number | null;
      logoImage?: {
        __typename: 'ImageLibraryImageNode';
        id: string;
        pk?: number | null;
        guid: string;
        displayName?: string | null;
        image: string;
        order: number;
        source?: string | null;
        imageType?: string | null;
        isFavorite: boolean;
        createdAt: string;
        removedAt?: string | null;
        updatedAt: string;
        lastUsedAt?: string | null;
        baseUrl?: string | null;
        imageWidth?: number | null;
        imageHeight?: number | null;
        upscaledImageUrl?: string | null;
        stockAssetId?: string | null;
        stockSearchId?: string | null;
      } | null;
      colorLibraries: {
        __typename: 'ColorLibraryNodeConnection';
        edges: Array<{
          __typename: 'ColorLibraryNodeEdge';
          node?: {
            __typename: 'ColorLibraryNode';
            id: string;
            guid: string;
            displayName: string;
            colors: {
              __typename: 'ColorLibraryColorNodeConnection';
              edges: Array<{
                __typename: 'ColorLibraryColorNodeEdge';
                node?: {
                  __typename: 'ColorLibraryColorNode';
                  id: string;
                  order: number;
                  displayName?: string | null;
                  hexCode: string;
                  updatedAt: string;
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteBusinessMutationVariables = Exact<{
  input: DeleteBusinessMutationInput;
}>;

export type DeleteBusinessMutation = {
  __typename: 'Mutation';
  deleteBusiness?: {
    __typename: 'DeleteBusinessMutationPayload';
    deletedBusinessId?: string | null;
  } | null;
};

export type BusinessDetailsByGuidQueryVariables = Exact<{
  businessGUID: Scalars['String']['input'];
}>;

export type BusinessDetailsByGuidQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    pk?: number | null;
    createdAt: string;
    guid: string;
    isVisible: boolean;
    businessName: string;
    businessAbout?: string | null;
    websiteUrl: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    postalCode: string;
    contactPhone: string;
    industryName?: string | null;
    totalImageCount?: number | null;
    totalVideoCount?: number | null;
    logoImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      image: string;
      order: number;
      source?: string | null;
      imageType?: string | null;
      isFavorite: boolean;
      createdAt: string;
      removedAt?: string | null;
      updatedAt: string;
      lastUsedAt?: string | null;
      baseUrl?: string | null;
      imageWidth?: number | null;
      imageHeight?: number | null;
      upscaledImageUrl?: string | null;
      stockAssetId?: string | null;
      stockSearchId?: string | null;
    } | null;
    colorLibraries: {
      __typename: 'ColorLibraryNodeConnection';
      edges: Array<{
        __typename: 'ColorLibraryNodeEdge';
        node?: {
          __typename: 'ColorLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          colors: {
            __typename: 'ColorLibraryColorNodeConnection';
            edges: Array<{
              __typename: 'ColorLibraryColorNodeEdge';
              node?: {
                __typename: 'ColorLibraryColorNode';
                id: string;
                order: number;
                displayName?: string | null;
                hexCode: string;
                updatedAt: string;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type ListAllBusinessProfilesForAccountQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type ListAllBusinessProfilesForAccountQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    businesses?: {
      __typename: 'BusinessRelayNodeConnection';
      edges: Array<{
        __typename: 'BusinessRelayNodeEdge';
        node?: {
          __typename: 'BusinessRelayNode';
          id: string;
          pk?: number | null;
          createdAt: string;
          guid: string;
          isVisible: boolean;
          businessName: string;
          businessAbout?: string | null;
          websiteUrl: string;
          streetAddress1: string;
          streetAddress2: string;
          city: string;
          state: string;
          postalCode: string;
          contactPhone: string;
          industryName?: string | null;
          totalImageCount?: number | null;
          totalVideoCount?: number | null;
          logoImage?: {
            __typename: 'ImageLibraryImageNode';
            id: string;
            pk?: number | null;
            guid: string;
            displayName?: string | null;
            image: string;
            order: number;
            source?: string | null;
            imageType?: string | null;
            isFavorite: boolean;
            createdAt: string;
            removedAt?: string | null;
            updatedAt: string;
            lastUsedAt?: string | null;
            baseUrl?: string | null;
            imageWidth?: number | null;
            imageHeight?: number | null;
            upscaledImageUrl?: string | null;
            stockAssetId?: string | null;
            stockSearchId?: string | null;
          } | null;
          colorLibraries: {
            __typename: 'ColorLibraryNodeConnection';
            edges: Array<{
              __typename: 'ColorLibraryNodeEdge';
              node?: {
                __typename: 'ColorLibraryNode';
                id: string;
                guid: string;
                displayName: string;
                colors: {
                  __typename: 'ColorLibraryColorNodeConnection';
                  edges: Array<{
                    __typename: 'ColorLibraryColorNodeEdge';
                    node?: {
                      __typename: 'ColorLibraryColorNode';
                      id: string;
                      order: number;
                      displayName?: string | null;
                      hexCode: string;
                      updatedAt: string;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type AccountBusinessCountQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountBusinessCountQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    businesses?: { __typename: 'BusinessRelayNodeConnection'; totalCount?: number | null } | null;
  } | null;
};

export type BusinessVideoCountQueryVariables = Exact<{
  businessGUID: Scalars['String']['input'];
}>;

export type BusinessVideoCountQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    totalVideoCount?: number | null;
  } | null;
};

export type ColorLibraryColorFragmentFragment = {
  __typename: 'ColorLibraryColorNode';
  id: string;
  order: number;
  displayName?: string | null;
  hexCode: string;
  updatedAt: string;
};

export type ColorLibraryFragmentFragment = {
  __typename: 'ColorLibraryNode';
  id: string;
  guid: string;
  displayName: string;
  colors: {
    __typename: 'ColorLibraryColorNodeConnection';
    edges: Array<{
      __typename: 'ColorLibraryColorNodeEdge';
      node?: {
        __typename: 'ColorLibraryColorNode';
        id: string;
        order: number;
        displayName?: string | null;
        hexCode: string;
        updatedAt: string;
      } | null;
    } | null>;
  };
};

export type UpdateOrCreateColorLibraryColorMutationVariables = Exact<{
  input: UpdateOrCreateColorLibraryColorMutationInput;
}>;

export type UpdateOrCreateColorLibraryColorMutation = {
  __typename: 'Mutation';
  updateOrCreateColorLibraryColor?: {
    __typename: 'UpdateOrCreateColorLibraryColorMutationPayload';
    updatedColorLibrary?: {
      __typename: 'ColorLibraryNode';
      id: string;
      guid: string;
      displayName: string;
      colors: {
        __typename: 'ColorLibraryColorNodeConnection';
        edges: Array<{
          __typename: 'ColorLibraryColorNodeEdge';
          node?: {
            __typename: 'ColorLibraryColorNode';
            id: string;
            order: number;
            displayName?: string | null;
            hexCode: string;
            updatedAt: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteColorLibraryColorMutationVariables = Exact<{
  input: DeleteColorLibraryColorMutationInput;
}>;

export type DeleteColorLibraryColorMutation = {
  __typename: 'Mutation';
  deleteColorLibraryColor?: {
    __typename: 'DeleteColorLibraryColorMutationPayload';
    removedColorId?: string | null;
  } | null;
};

export type GetOrCreateAccountColorLibraryMutationVariables = Exact<{
  input: GetOrCreateAccountColorLibraryMutationInput;
}>;

export type GetOrCreateAccountColorLibraryMutation = {
  __typename: 'Mutation';
  getOrCreateAccountColorLibrary?: {
    __typename: 'GetOrCreateAccountColorLibraryMutationPayload';
    didCreate?: boolean | null;
    colorLibrary?: {
      __typename: 'ColorLibraryNode';
      id: string;
      guid: string;
      displayName: string;
      colors: {
        __typename: 'ColorLibraryColorNodeConnection';
        edges: Array<{
          __typename: 'ColorLibraryColorNodeEdge';
          node?: {
            __typename: 'ColorLibraryColorNode';
            id: string;
            order: number;
            displayName?: string | null;
            hexCode: string;
            updatedAt: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GetOrCreateBusinessColorLibraryMutationVariables = Exact<{
  input: GetOrCreateBusinessColorLibraryMutationInput;
}>;

export type GetOrCreateBusinessColorLibraryMutation = {
  __typename: 'Mutation';
  getOrCreateBusinessColorLibrary?: {
    __typename: 'GetOrCreateBusinessColorLibraryMutationPayload';
    didCreate?: boolean | null;
    colorLibrary?: {
      __typename: 'ColorLibraryNode';
      id: string;
      guid: string;
      displayName: string;
      colors: {
        __typename: 'ColorLibraryColorNodeConnection';
        edges: Array<{
          __typename: 'ColorLibraryColorNodeEdge';
          node?: {
            __typename: 'ColorLibraryColorNode';
            id: string;
            order: number;
            displayName?: string | null;
            hexCode: string;
            updatedAt: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ColorLibrariesForAccountQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type ColorLibrariesForAccountQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    guid: string;
    colorLibraries: {
      __typename: 'ColorLibraryNodeConnection';
      edges: Array<{
        __typename: 'ColorLibraryNodeEdge';
        node?: {
          __typename: 'ColorLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          colors: {
            __typename: 'ColorLibraryColorNodeConnection';
            edges: Array<{
              __typename: 'ColorLibraryColorNodeEdge';
              node?: {
                __typename: 'ColorLibraryColorNode';
                id: string;
                order: number;
                displayName?: string | null;
                hexCode: string;
                updatedAt: string;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
    accountGroups: {
      __typename: 'AccountGroupRelayNodeConnection';
      edges: Array<{
        __typename: 'AccountGroupRelayNodeEdge';
        node?: {
          __typename: 'AccountGroupRelayNode';
          id: string;
          displayName: string;
          colorLibraries: {
            __typename: 'ColorLibraryNodeConnection';
            edges: Array<{
              __typename: 'ColorLibraryNodeEdge';
              node?: {
                __typename: 'ColorLibraryNode';
                id: string;
                guid: string;
                displayName: string;
                colors: {
                  __typename: 'ColorLibraryColorNodeConnection';
                  edges: Array<{
                    __typename: 'ColorLibraryColorNodeEdge';
                    node?: {
                      __typename: 'ColorLibraryColorNode';
                      id: string;
                      order: number;
                      displayName?: string | null;
                      hexCode: string;
                      updatedAt: string;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type ColorLibrariesForBusinessQueryVariables = Exact<{
  businessGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type ColorLibrariesForBusinessQuery = {
  __typename: 'Query';
  businessByGuid?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    businessName: string;
    colorLibraries: {
      __typename: 'ColorLibraryNodeConnection';
      edges: Array<{
        __typename: 'ColorLibraryNodeEdge';
        node?: {
          __typename: 'ColorLibraryNode';
          id: string;
          guid: string;
          displayName: string;
          colors: {
            __typename: 'ColorLibraryColorNodeConnection';
            edges: Array<{
              __typename: 'ColorLibraryColorNodeEdge';
              node?: {
                __typename: 'ColorLibraryColorNode';
                id: string;
                order: number;
                displayName?: string | null;
                hexCode: string;
                updatedAt: string;
              } | null;
            } | null>;
          };
        } | null;
      } | null>;
    };
  } | null;
};

export type ImageLibraryImageFragmentFragment = {
  __typename: 'ImageLibraryImageNode';
  id: string;
  pk?: number | null;
  guid: string;
  displayName?: string | null;
  image: string;
  order: number;
  source?: string | null;
  imageType?: string | null;
  isFavorite: boolean;
  createdAt: string;
  removedAt?: string | null;
  updatedAt: string;
  lastUsedAt?: string | null;
  baseUrl?: string | null;
  imageWidth?: number | null;
  imageHeight?: number | null;
  upscaledImageUrl?: string | null;
  stockAssetId?: string | null;
  stockSearchId?: string | null;
};

export type ImageLibraryFragmentFragment = {
  __typename: 'ImageLibraryNode';
  id: string;
  pk?: number | null;
  slug: string;
  displayName: string;
  images?: {
    __typename: 'ImageLibraryImageNodeConnection';
    edges: Array<{
      __typename: 'ImageLibraryImageNodeEdge';
      node?: {
        __typename: 'ImageLibraryImageNode';
        id: string;
        pk?: number | null;
        guid: string;
        displayName?: string | null;
        image: string;
        order: number;
        source?: string | null;
        imageType?: string | null;
        isFavorite: boolean;
        createdAt: string;
        removedAt?: string | null;
        updatedAt: string;
        lastUsedAt?: string | null;
        baseUrl?: string | null;
        imageWidth?: number | null;
        imageHeight?: number | null;
        upscaledImageUrl?: string | null;
        stockAssetId?: string | null;
        stockSearchId?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetOrCreateDefaultAccountImageLibraryMutationVariables = Exact<{
  input: GetOrCreateDefaultAccountImageLibraryMutationInput;
}>;

export type GetOrCreateDefaultAccountImageLibraryMutation = {
  __typename: 'Mutation';
  getOrCreateDefaultAccountImageLibrary?: {
    __typename: 'GetOrCreateDefaultAccountImageLibraryMutationPayload';
    imageLibrary?: {
      __typename: 'ImageLibraryNode';
      id: string;
      pk?: number | null;
      slug: string;
      displayName: string;
      images?: {
        __typename: 'ImageLibraryImageNodeConnection';
        edges: Array<{
          __typename: 'ImageLibraryImageNodeEdge';
          node?: {
            __typename: 'ImageLibraryImageNode';
            id: string;
            pk?: number | null;
            guid: string;
            displayName?: string | null;
            image: string;
            order: number;
            source?: string | null;
            imageType?: string | null;
            isFavorite: boolean;
            createdAt: string;
            removedAt?: string | null;
            updatedAt: string;
            lastUsedAt?: string | null;
            baseUrl?: string | null;
            imageWidth?: number | null;
            imageHeight?: number | null;
            upscaledImageUrl?: string | null;
            stockAssetId?: string | null;
            stockSearchId?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type GetOrCreateDefaultBusinessImageLibraryMutationVariables = Exact<{
  input: GetOrCreateDefaultBusinessImageLibraryMutationInput;
}>;

export type GetOrCreateDefaultBusinessImageLibraryMutation = {
  __typename: 'Mutation';
  getOrCreateDefaultBusinessImageLibrary?: {
    __typename: 'GetOrCreateDefaultBusinessImageLibraryMutationPayload';
    imageLibrary?: {
      __typename: 'ImageLibraryNode';
      id: string;
      pk?: number | null;
      slug: string;
      displayName: string;
      images?: {
        __typename: 'ImageLibraryImageNodeConnection';
        edges: Array<{
          __typename: 'ImageLibraryImageNodeEdge';
          node?: {
            __typename: 'ImageLibraryImageNode';
            id: string;
            pk?: number | null;
            guid: string;
            displayName?: string | null;
            image: string;
            order: number;
            source?: string | null;
            imageType?: string | null;
            isFavorite: boolean;
            createdAt: string;
            removedAt?: string | null;
            updatedAt: string;
            lastUsedAt?: string | null;
            baseUrl?: string | null;
            imageWidth?: number | null;
            imageHeight?: number | null;
            upscaledImageUrl?: string | null;
            stockAssetId?: string | null;
            stockSearchId?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type AddImageToImageLibraryMutationMutationVariables = Exact<{
  input: AddImageToImageLibraryMutationInput;
}>;

export type AddImageToImageLibraryMutationMutation = {
  __typename: 'Mutation';
  addImageToImageLibrary?: {
    __typename: 'AddImageToImageLibraryMutationPayload';
    createdImageLibraryImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      image: string;
      order: number;
      source?: string | null;
      imageType?: string | null;
      isFavorite: boolean;
      createdAt: string;
      removedAt?: string | null;
      updatedAt: string;
      lastUsedAt?: string | null;
      baseUrl?: string | null;
      imageWidth?: number | null;
      imageHeight?: number | null;
      upscaledImageUrl?: string | null;
      stockAssetId?: string | null;
      stockSearchId?: string | null;
    } | null;
    updatedImageLibrary?: {
      __typename: 'ImageLibraryNode';
      id: string;
      images?: {
        __typename: 'ImageLibraryImageNodeConnection';
        edges: Array<{
          __typename: 'ImageLibraryImageNodeEdge';
          node?: {
            __typename: 'ImageLibraryImageNode';
            id: string;
            pk?: number | null;
            guid: string;
            displayName?: string | null;
            image: string;
            order: number;
            source?: string | null;
            imageType?: string | null;
            isFavorite: boolean;
            createdAt: string;
            removedAt?: string | null;
            updatedAt: string;
            lastUsedAt?: string | null;
            baseUrl?: string | null;
            imageWidth?: number | null;
            imageHeight?: number | null;
            upscaledImageUrl?: string | null;
            stockAssetId?: string | null;
            stockSearchId?: string | null;
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type UpdateImageLibraryImageMutationVariables = Exact<{
  input: UpdateImageLibraryImageMutationInput;
}>;

export type UpdateImageLibraryImageMutation = {
  __typename: 'Mutation';
  updateImageLibraryImage?: {
    __typename: 'UpdateImageLibraryImageMutationPayload';
    updatedImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      image: string;
      order: number;
      source?: string | null;
      imageType?: string | null;
      isFavorite: boolean;
      createdAt: string;
      removedAt?: string | null;
      updatedAt: string;
      lastUsedAt?: string | null;
      baseUrl?: string | null;
      imageWidth?: number | null;
      imageHeight?: number | null;
      upscaledImageUrl?: string | null;
      stockAssetId?: string | null;
      stockSearchId?: string | null;
    } | null;
  } | null;
};

export type UpdateImageLibraryImageLastUsedAtMutationVariables = Exact<{
  input: UpdateImageLibraryImageLastUsedAtMutationInput;
}>;

export type UpdateImageLibraryImageLastUsedAtMutation = {
  __typename: 'Mutation';
  updateImageLibraryImageLastUsedAt?: {
    __typename: 'UpdateImageLibraryImageLastUsedAtMutationPayload';
    updatedImage?: {
      __typename: 'ImageLibraryImageNode';
      id: string;
      pk?: number | null;
      guid: string;
      displayName?: string | null;
      image: string;
      order: number;
      source?: string | null;
      imageType?: string | null;
      isFavorite: boolean;
      createdAt: string;
      removedAt?: string | null;
      updatedAt: string;
      lastUsedAt?: string | null;
      baseUrl?: string | null;
      imageWidth?: number | null;
      imageHeight?: number | null;
      upscaledImageUrl?: string | null;
      stockAssetId?: string | null;
      stockSearchId?: string | null;
    } | null;
  } | null;
};

export type StockImagesForImageKeysQueryVariables = Exact<{
  imageKeys: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type StockImagesForImageKeysQuery = {
  __typename: 'Query';
  stockImagesForImageKeys?: Array<{
    __typename: 'ImageLibraryImageNode';
    id: string;
    stockAssetId?: string | null;
    stockSearchId?: string | null;
  }> | null;
};

export type ImageLibraryImageByImageKeyQueryVariables = Exact<{
  imageKey: Scalars['String']['input'];
}>;

export type ImageLibraryImageByImageKeyQuery = {
  __typename: 'Query';
  imageLibraryImageByImageKey?: {
    __typename: 'ImageLibraryImageNode';
    id: string;
    guid: string;
    baseUrl?: string | null;
    upscaledImageUrl?: string | null;
    imageType?: string | null;
    imageWidth?: number | null;
    imageHeight?: number | null;
  } | null;
};

export type StockVideoAssetFragmentFragment = {
  __typename: 'StockVideoAssetNode';
  id: string;
  source?: string | null;
  sourceAssetId: string;
  width?: number | null;
  height?: number | null;
  length?: number | null;
  vpsKey?: string | null;
};

export type StockVideoLibraryVideoFragmentFragment = {
  __typename: 'StockVideoLibraryVideoNode';
  id: string;
  guid: string;
  removedAt?: string | null;
  updatedAt: string;
  stockAsset: {
    __typename: 'StockVideoAssetNode';
    id: string;
    source?: string | null;
    sourceAssetId: string;
    width?: number | null;
    height?: number | null;
    length?: number | null;
    vpsKey?: string | null;
  };
};

export type StockVideoLibraryFragmentFragment = {
  __typename: 'StockVideoLibraryNode';
  id: string;
  guid: string;
  displayName: string;
  stockVideoLibraryVideos: {
    __typename: 'StockVideoLibraryVideoNodeConnection';
    edges: Array<{
      __typename: 'StockVideoLibraryVideoNodeEdge';
      node?: {
        __typename: 'StockVideoLibraryVideoNode';
        id: string;
        guid: string;
        removedAt?: string | null;
        updatedAt: string;
        stockAsset: {
          __typename: 'StockVideoAssetNode';
          id: string;
          source?: string | null;
          sourceAssetId: string;
          width?: number | null;
          height?: number | null;
          length?: number | null;
          vpsKey?: string | null;
        };
      } | null;
    } | null>;
  };
};

export type AddStockVideoToLibraryMutationVariables = Exact<{
  input: AddStockVideoToLibraryMutationInput;
}>;

export type AddStockVideoToLibraryMutation = {
  __typename: 'Mutation';
  addStockVideoToLibrary?: {
    __typename: 'AddStockVideoToLibraryMutationPayload';
    createdStockVideoLibraryVideo?: {
      __typename: 'StockVideoLibraryVideoNode';
      id: string;
      guid: string;
      removedAt?: string | null;
      updatedAt: string;
      stockAsset: {
        __typename: 'StockVideoAssetNode';
        id: string;
        source?: string | null;
        sourceAssetId: string;
        width?: number | null;
        height?: number | null;
        length?: number | null;
        vpsKey?: string | null;
      };
    } | null;
    createdStockVideoLibrary?: {
      __typename: 'StockVideoLibraryNode';
      id: string;
      guid: string;
      displayName: string;
    } | null;
  } | null;
};

export type RemoveStockVideoFromLibraryMutationVariables = Exact<{
  input: RemoveStockVideoFromLibraryMutationInput;
}>;

export type RemoveStockVideoFromLibraryMutation = {
  __typename: 'Mutation';
  removeStockVideoFromLibrary?: {
    __typename: 'RemoveStockVideoFromLibraryMutationPayload';
    updatedStockVideoLibraryVideo?: {
      __typename: 'StockVideoLibraryVideoNode';
      id: string;
      guid: string;
      removedAt?: string | null;
      updatedAt: string;
      stockAsset: {
        __typename: 'StockVideoAssetNode';
        id: string;
        source?: string | null;
        sourceAssetId: string;
        width?: number | null;
        height?: number | null;
        length?: number | null;
        vpsKey?: string | null;
      };
    } | null;
  } | null;
};

export type RestoreRemovedStockVideoMutationVariables = Exact<{
  input: RestoreRemovedStockVideoToLibraryMutationInput;
}>;

export type RestoreRemovedStockVideoMutation = {
  __typename: 'Mutation';
  restoreRemovedStockVideoToLibrary?: {
    __typename: 'RestoreRemovedStockVideoToLibraryMutationPayload';
    updatedStockVideoLibraryVideo?: {
      __typename: 'StockVideoLibraryVideoNode';
      id: string;
      guid: string;
      removedAt?: string | null;
      updatedAt: string;
      stockAsset: {
        __typename: 'StockVideoAssetNode';
        id: string;
        source?: string | null;
        sourceAssetId: string;
        width?: number | null;
        height?: number | null;
        length?: number | null;
        vpsKey?: string | null;
      };
    } | null;
  } | null;
};

export type CoreUserVideoFragmentFragment = {
  __typename: 'UserVideoNode';
  id: string;
  guid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  purchasedAt?: string | null;
  lastEditedByUser?: string | null;
  lastEditableDate?: string | null;
  business?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    businessName: string;
  } | null;
  videoTemplateVariant: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    guid: string;
    displayName: string;
    displayDuration?: number | null;
    width: number;
    height: number;
    isEnabled: boolean;
    videoTemplate: {
      __typename: 'VideoTemplateNode';
      id: string;
      isWaymarkAuthorTemplate: boolean;
      isRetired: boolean;
    };
  };
};

export type UserVideoApprovalFragmentFragment = {
  __typename: 'UserVideoApprovalNode';
  id: string;
  guid: string;
  createdAt: string;
  fullName: string;
  approvedAt?: string | null;
};

export type UserVideoAccountPageFieldsFragmentFragment = {
  __typename: 'UserVideoNode';
  omniType?: string | null;
  id: string;
  guid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  purchasedAt?: string | null;
  lastEditedByUser?: string | null;
  lastEditableDate?: string | null;
  currentConfiguredVideo?: {
    __typename: 'ConfiguredVideoNode';
    id: string;
    thumbnailUrl?: string | null;
  } | null;
  purchaseApproval?: {
    __typename: 'UserVideoPurchaseApprovalNode';
    id: string;
    guid: string;
    createdAt: string;
    approvedAt?: string | null;
  } | null;
  userVideoApprovals?: Array<{
    __typename: 'UserVideoApprovalNode';
    id: string;
    guid: string;
    createdAt: string;
    fullName: string;
    approvedAt?: string | null;
  }> | null;
  voiceOverProduct?: {
    __typename: 'VideoVoiceOverProduct';
    id: string;
    isDelivered: boolean;
  } | null;
  derivedUserVideos?: { __typename: 'UserVideoNodeConnection'; totalCount?: number | null } | null;
  originalUserVideo?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    title: string;
  } | null;
  business?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    businessName: string;
  } | null;
  videoTemplateVariant: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    guid: string;
    displayName: string;
    displayDuration?: number | null;
    width: number;
    height: number;
    isEnabled: boolean;
    videoTemplate: {
      __typename: 'VideoTemplateNode';
      id: string;
      isWaymarkAuthorTemplate: boolean;
      isRetired: boolean;
    };
  };
};

export type UserVideoRendersFragmentFragment = {
  __typename: 'UserVideoNode';
  id: string;
  currentConfiguredVideo?: {
    __typename: 'ConfiguredVideoNode';
    id: string;
    guid: string;
    renderStatus?: string | null;
    thumbnailUrl?: string | null;
    renderedVideos: {
      __typename: 'RenderedVideoNodeConnection';
      edges: Array<{
        __typename: 'RenderedVideoNodeEdge';
        node?: {
          __typename: 'RenderedVideoNode';
          id: string;
          guid: string;
          renderUrl?: string | null;
          renderFormat?: string | null;
          renderedAt?: string | null;
          renderStatus?: string | null;
          renderSize?: string | null;
          hasWatermark?: boolean | null;
          createdAt: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type UserVideoFeedbackFragmentFragment = {
  __typename: 'UserVideoFeedbackNode';
  id: string;
  guid: string;
  rating: string;
  feedbackText: string;
  timeToCreate: string;
};

export type EditorUserVideoFragmentFragment = {
  __typename: 'UserVideoNode';
  id: string;
  guid: string;
  title: string;
  videoConfiguration: string;
  purchasedAt?: string | null;
  createdAt: string;
  updatedAt: string;
  lastEditedByUser?: string | null;
  voiceOverText: string;
  voiceOverSpeakerGuid?: string | null;
  voiceOverTiming?: string | null;
  videoTemplateVariant: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    guid: string;
    slug: string;
    displayName: string;
    displayDuration?: number | null;
    width: number;
    height: number;
    videoDescriptor?: string | null;
    shouldIncludeGlobalAudio: boolean;
    compoundSlug: string;
    vibes: {
      __typename: 'VibeNodeConnection';
      edges: Array<{
        __typename: 'VibeNodeEdge';
        node?: { __typename: 'VibeNode'; id: string; name: string } | null;
      } | null>;
    };
    videoTemplate: {
      __typename: 'VideoTemplateNode';
      id: string;
      slug: string;
      isWaymarkAuthorTemplate: boolean;
    };
  };
  business?: { __typename: 'BusinessRelayNode'; id: string; guid: string } | null;
};

export type CreateUserVideoFeedbackMutationVariables = Exact<{
  input: CreateUserVideoFeedbackMutationInput;
}>;

export type CreateUserVideoFeedbackMutation = {
  __typename: 'Mutation';
  createUserVideoFeedback?: {
    __typename: 'CreateUserVideoFeedbackMutationPayload';
    updatedUserVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      feedback: {
        __typename: 'UserVideoFeedbackNodeConnection';
        edges: Array<{
          __typename: 'UserVideoFeedbackNodeEdge';
          node?: {
            __typename: 'UserVideoFeedbackNode';
            id: string;
            guid: string;
            rating: string;
            feedbackText: string;
            timeToCreate: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type CreateEditorUserVideoMutationVariables = Exact<{
  input: CreateUserVideoMutationInput;
}>;

export type CreateEditorUserVideoMutation = {
  __typename: 'Mutation';
  createUserVideo?: {
    __typename: 'CreateUserVideoMutationPayload';
    userVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      title: string;
      videoConfiguration: string;
      purchasedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      lastEditedByUser?: string | null;
      voiceOverText: string;
      voiceOverSpeakerGuid?: string | null;
      voiceOverTiming?: string | null;
      videoTemplateVariant: {
        __typename: 'VideoTemplateVariantRelayNode';
        id: string;
        guid: string;
        slug: string;
        displayName: string;
        displayDuration?: number | null;
        width: number;
        height: number;
        videoDescriptor?: string | null;
        shouldIncludeGlobalAudio: boolean;
        compoundSlug: string;
        vibes: {
          __typename: 'VibeNodeConnection';
          edges: Array<{
            __typename: 'VibeNodeEdge';
            node?: { __typename: 'VibeNode'; id: string; name: string } | null;
          } | null>;
        };
        videoTemplate: {
          __typename: 'VideoTemplateNode';
          id: string;
          slug: string;
          isWaymarkAuthorTemplate: boolean;
        };
      };
      business?: { __typename: 'BusinessRelayNode'; id: string; guid: string } | null;
    } | null;
  } | null;
};

export type UpdateUserVideoTitleMutationVariables = Exact<{
  input: UpdateUserVideoMutationInput;
}>;

export type UpdateUserVideoTitleMutation = {
  __typename: 'Mutation';
  updateUserVideo?: {
    __typename: 'UpdateUserVideoMutationPayload';
    updatedUserVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      title: string;
    } | null;
  } | null;
};

export type UpdateUserVideoBusinessMutationVariables = Exact<{
  input: UpdateUserVideoMutationInput;
}>;

export type UpdateUserVideoBusinessMutation = {
  __typename: 'Mutation';
  updateUserVideo?: {
    __typename: 'UpdateUserVideoMutationPayload';
    updatedUserVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      business?: { __typename: 'BusinessRelayNode'; id: string; guid: string } | null;
    } | null;
  } | null;
};

export type UpdateUserVideoVoiceOverMutationVariables = Exact<{
  input: UpdateUserVideoMutationInput;
}>;

export type UpdateUserVideoVoiceOverMutation = {
  __typename: 'Mutation';
  updateUserVideo?: {
    __typename: 'UpdateUserVideoMutationPayload';
    updatedUserVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      voiceOverText: string;
      voiceOverSpeakerGuid?: string | null;
      voiceOverTiming?: string | null;
    } | null;
  } | null;
};

export type UpdateUserVideoConfigurationMutationVariables = Exact<{
  input: UpdateUserVideoMutationInput;
}>;

export type UpdateUserVideoConfigurationMutation = {
  __typename: 'Mutation';
  updateUserVideo?: {
    __typename: 'UpdateUserVideoMutationPayload';
    updatedUserVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      videoConfiguration: string;
    } | null;
  } | null;
};

export type AccountUserVideoCountQueryVariables = Exact<{
  accountGUID?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountUserVideoCountQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    userVideos?: { __typename: 'UserVideoNodeConnection'; totalCount?: number | null } | null;
  } | null;
};

export type AccountPageUserVideosQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  isPurchased?: InputMaybe<Scalars['Boolean']['input']>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountPageUserVideosQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    unfilteredUserVideos?: {
      __typename: 'UserVideoNodeConnection';
      totalCount?: number | null;
    } | null;
    userVideos?: {
      __typename: 'UserVideoNodeConnection';
      totalCount?: number | null;
      edges: Array<{
        __typename: 'UserVideoNodeEdge';
        cursor: string;
        node?: {
          __typename: 'UserVideoNode';
          omniType?: string | null;
          id: string;
          guid: string;
          title: string;
          createdAt: string;
          updatedAt: string;
          purchasedAt?: string | null;
          lastEditedByUser?: string | null;
          lastEditableDate?: string | null;
          currentConfiguredVideo?: {
            __typename: 'ConfiguredVideoNode';
            id: string;
            thumbnailUrl?: string | null;
          } | null;
          purchaseApproval?: {
            __typename: 'UserVideoPurchaseApprovalNode';
            id: string;
            guid: string;
            createdAt: string;
            approvedAt?: string | null;
          } | null;
          userVideoApprovals?: Array<{
            __typename: 'UserVideoApprovalNode';
            id: string;
            guid: string;
            createdAt: string;
            fullName: string;
            approvedAt?: string | null;
          }> | null;
          voiceOverProduct?: {
            __typename: 'VideoVoiceOverProduct';
            id: string;
            isDelivered: boolean;
          } | null;
          derivedUserVideos?: {
            __typename: 'UserVideoNodeConnection';
            totalCount?: number | null;
          } | null;
          originalUserVideo?: {
            __typename: 'UserVideoNode';
            id: string;
            guid: string;
            title: string;
          } | null;
          business?: {
            __typename: 'BusinessRelayNode';
            id: string;
            guid: string;
            businessName: string;
          } | null;
          videoTemplateVariant: {
            __typename: 'VideoTemplateVariantRelayNode';
            id: string;
            guid: string;
            displayName: string;
            displayDuration?: number | null;
            width: number;
            height: number;
            isEnabled: boolean;
            videoTemplate: {
              __typename: 'VideoTemplateNode';
              id: string;
              isWaymarkAuthorTemplate: boolean;
              isRetired: boolean;
            };
          };
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type AccountPageUserVideoAndDerivedVideosQueryVariables = Exact<{
  userVideoGUID?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountPageUserVideoAndDerivedVideosQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    omniType?: string | null;
    id: string;
    guid: string;
    title: string;
    createdAt: string;
    updatedAt: string;
    purchasedAt?: string | null;
    lastEditedByUser?: string | null;
    lastEditableDate?: string | null;
    derivedAccountPageUserVideos?: {
      __typename: 'UserVideoNodeConnection';
      totalCount?: number | null;
      edges: Array<{
        __typename: 'UserVideoNodeEdge';
        node?: {
          __typename: 'UserVideoNode';
          omniType?: string | null;
          id: string;
          guid: string;
          title: string;
          createdAt: string;
          updatedAt: string;
          purchasedAt?: string | null;
          lastEditedByUser?: string | null;
          lastEditableDate?: string | null;
          currentConfiguredVideo?: {
            __typename: 'ConfiguredVideoNode';
            id: string;
            thumbnailUrl?: string | null;
          } | null;
          purchaseApproval?: {
            __typename: 'UserVideoPurchaseApprovalNode';
            id: string;
            guid: string;
            createdAt: string;
            approvedAt?: string | null;
          } | null;
          userVideoApprovals?: Array<{
            __typename: 'UserVideoApprovalNode';
            id: string;
            guid: string;
            createdAt: string;
            fullName: string;
            approvedAt?: string | null;
          }> | null;
          voiceOverProduct?: {
            __typename: 'VideoVoiceOverProduct';
            id: string;
            isDelivered: boolean;
          } | null;
          derivedUserVideos?: {
            __typename: 'UserVideoNodeConnection';
            totalCount?: number | null;
          } | null;
          originalUserVideo?: {
            __typename: 'UserVideoNode';
            id: string;
            guid: string;
            title: string;
          } | null;
          business?: {
            __typename: 'BusinessRelayNode';
            id: string;
            guid: string;
            businessName: string;
          } | null;
          videoTemplateVariant: {
            __typename: 'VideoTemplateVariantRelayNode';
            id: string;
            guid: string;
            displayName: string;
            displayDuration?: number | null;
            width: number;
            height: number;
            isEnabled: boolean;
            videoTemplate: {
              __typename: 'VideoTemplateNode';
              id: string;
              isWaymarkAuthorTemplate: boolean;
              isRetired: boolean;
            };
          };
        } | null;
      } | null>;
    } | null;
    currentConfiguredVideo?: {
      __typename: 'ConfiguredVideoNode';
      id: string;
      thumbnailUrl?: string | null;
    } | null;
    purchaseApproval?: {
      __typename: 'UserVideoPurchaseApprovalNode';
      id: string;
      guid: string;
      createdAt: string;
      approvedAt?: string | null;
    } | null;
    userVideoApprovals?: Array<{
      __typename: 'UserVideoApprovalNode';
      id: string;
      guid: string;
      createdAt: string;
      fullName: string;
      approvedAt?: string | null;
    }> | null;
    voiceOverProduct?: {
      __typename: 'VideoVoiceOverProduct';
      id: string;
      isDelivered: boolean;
    } | null;
    derivedUserVideos?: {
      __typename: 'UserVideoNodeConnection';
      totalCount?: number | null;
    } | null;
    originalUserVideo?: {
      __typename: 'UserVideoNode';
      id: string;
      guid: string;
      title: string;
    } | null;
    business?: {
      __typename: 'BusinessRelayNode';
      id: string;
      guid: string;
      businessName: string;
    } | null;
    videoTemplateVariant: {
      __typename: 'VideoTemplateVariantRelayNode';
      id: string;
      guid: string;
      displayName: string;
      displayDuration?: number | null;
      width: number;
      height: number;
      isEnabled: boolean;
      videoTemplate: {
        __typename: 'VideoTemplateNode';
        id: string;
        isWaymarkAuthorTemplate: boolean;
        isRetired: boolean;
      };
    };
  } | null;
};

export type SdkUserVideoFragment = {
  __typename: 'UserVideoNode';
  id: string;
  guid: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  purchasedAt?: string | null;
  lastEditedByUser?: string | null;
  lastEditableDate?: string | null;
  business?: {
    __typename: 'BusinessRelayNode';
    id: string;
    guid: string;
    businessName: string;
  } | null;
  videoTemplateVariant: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    guid: string;
    displayName: string;
    displayDuration?: number | null;
    width: number;
    height: number;
    isEnabled: boolean;
    videoTemplate: {
      __typename: 'VideoTemplateNode';
      id: string;
      isWaymarkAuthorTemplate: boolean;
      isRetired: boolean;
    };
  };
  currentConfiguredVideo?: {
    __typename: 'ConfiguredVideoNode';
    id: string;
    guid: string;
    renderStatus?: string | null;
    thumbnailUrl?: string | null;
    renderedVideos: {
      __typename: 'RenderedVideoNodeConnection';
      edges: Array<{
        __typename: 'RenderedVideoNodeEdge';
        node?: {
          __typename: 'RenderedVideoNode';
          id: string;
          guid: string;
          renderUrl?: string | null;
          renderFormat?: string | null;
          renderedAt?: string | null;
          renderStatus?: string | null;
          renderSize?: string | null;
          hasWatermark?: boolean | null;
          createdAt: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type SdkAllAccountUserVideosQueryVariables = Exact<{
  accountGUID: Scalars['String']['input'];
}>;

export type SdkAllAccountUserVideosQuery = {
  __typename: 'Query';
  accountByGuid?: {
    __typename: 'AccountRelayNode';
    id: string;
    userVideos?: {
      __typename: 'UserVideoNodeConnection';
      edges: Array<{
        __typename: 'UserVideoNodeEdge';
        node?: {
          __typename: 'UserVideoNode';
          id: string;
          guid: string;
          title: string;
          createdAt: string;
          updatedAt: string;
          purchasedAt?: string | null;
          lastEditedByUser?: string | null;
          lastEditableDate?: string | null;
          business?: {
            __typename: 'BusinessRelayNode';
            id: string;
            guid: string;
            businessName: string;
          } | null;
          videoTemplateVariant: {
            __typename: 'VideoTemplateVariantRelayNode';
            id: string;
            guid: string;
            displayName: string;
            displayDuration?: number | null;
            width: number;
            height: number;
            isEnabled: boolean;
            videoTemplate: {
              __typename: 'VideoTemplateNode';
              id: string;
              isWaymarkAuthorTemplate: boolean;
              isRetired: boolean;
            };
          };
          currentConfiguredVideo?: {
            __typename: 'ConfiguredVideoNode';
            id: string;
            guid: string;
            renderStatus?: string | null;
            thumbnailUrl?: string | null;
            renderedVideos: {
              __typename: 'RenderedVideoNodeConnection';
              edges: Array<{
                __typename: 'RenderedVideoNodeEdge';
                node?: {
                  __typename: 'RenderedVideoNode';
                  id: string;
                  guid: string;
                  renderUrl?: string | null;
                  renderFormat?: string | null;
                  renderedAt?: string | null;
                  renderStatus?: string | null;
                  renderSize?: string | null;
                  hasWatermark?: boolean | null;
                  createdAt: string;
                } | null;
              } | null>;
            };
          } | null;
        } | null;
      } | null>;
    } | null;
  } | null;
};

export type UserVideoByGuidQueryVariables = Exact<{
  userVideoGUID: Scalars['String']['input'];
}>;

export type UserVideoByGuidQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    title: string;
    createdAt: string;
    updatedAt: string;
    purchasedAt?: string | null;
    lastEditedByUser?: string | null;
    lastEditableDate?: string | null;
    business?: {
      __typename: 'BusinessRelayNode';
      id: string;
      guid: string;
      businessName: string;
    } | null;
    videoTemplateVariant: {
      __typename: 'VideoTemplateVariantRelayNode';
      id: string;
      guid: string;
      displayName: string;
      displayDuration?: number | null;
      width: number;
      height: number;
      isEnabled: boolean;
      videoTemplate: {
        __typename: 'VideoTemplateNode';
        id: string;
        isWaymarkAuthorTemplate: boolean;
        isRetired: boolean;
      };
    };
    currentConfiguredVideo?: {
      __typename: 'ConfiguredVideoNode';
      id: string;
      guid: string;
      renderStatus?: string | null;
      thumbnailUrl?: string | null;
      renderedVideos: {
        __typename: 'RenderedVideoNodeConnection';
        edges: Array<{
          __typename: 'RenderedVideoNodeEdge';
          node?: {
            __typename: 'RenderedVideoNode';
            id: string;
            guid: string;
            renderUrl?: string | null;
            renderFormat?: string | null;
            renderedAt?: string | null;
            renderStatus?: string | null;
            renderSize?: string | null;
            hasWatermark?: boolean | null;
            createdAt: string;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type UserVideoApprovalsQueryVariables = Exact<{
  userVideoGUID: Scalars['String']['input'];
}>;

export type UserVideoApprovalsQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    userVideoApprovals?: Array<{
      __typename: 'UserVideoApprovalNode';
      id: string;
      guid: string;
      createdAt: string;
      fullName: string;
      approvedAt?: string | null;
    }> | null;
  } | null;
};

export type EditorUserVideoQueryVariables = Exact<{
  userVideoGUID: Scalars['String']['input'];
}>;

export type EditorUserVideoQuery = {
  __typename: 'Query';
  userVideoByGuid?: {
    __typename: 'UserVideoNode';
    id: string;
    guid: string;
    title: string;
    videoConfiguration: string;
    purchasedAt?: string | null;
    createdAt: string;
    updatedAt: string;
    lastEditedByUser?: string | null;
    voiceOverText: string;
    voiceOverSpeakerGuid?: string | null;
    voiceOverTiming?: string | null;
    videoTemplateVariant: {
      __typename: 'VideoTemplateVariantRelayNode';
      id: string;
      guid: string;
      slug: string;
      displayName: string;
      displayDuration?: number | null;
      width: number;
      height: number;
      videoDescriptor?: string | null;
      shouldIncludeGlobalAudio: boolean;
      compoundSlug: string;
      vibes: {
        __typename: 'VibeNodeConnection';
        edges: Array<{
          __typename: 'VibeNodeEdge';
          node?: { __typename: 'VibeNode'; id: string; name: string } | null;
        } | null>;
      };
      videoTemplate: {
        __typename: 'VideoTemplateNode';
        id: string;
        slug: string;
        isWaymarkAuthorTemplate: boolean;
      };
    };
    business?: { __typename: 'BusinessRelayNode'; id: string; guid: string } | null;
  } | null;
};

export type EditorVideoTemplateVariantFragmentFragment = {
  __typename: 'VideoTemplateVariantRelayNode';
  id: string;
  guid: string;
  slug: string;
  displayName: string;
  displayDuration?: number | null;
  width: number;
  height: number;
  videoDescriptor?: string | null;
  shouldIncludeGlobalAudio: boolean;
  compoundSlug: string;
  vibes: {
    __typename: 'VibeNodeConnection';
    edges: Array<{
      __typename: 'VibeNodeEdge';
      node?: { __typename: 'VibeNode'; id: string; name: string } | null;
    } | null>;
  };
  videoTemplate: {
    __typename: 'VideoTemplateNode';
    id: string;
    slug: string;
    isWaymarkAuthorTemplate: boolean;
  };
};

export type VariantForAiQueryVariables = Exact<{
  businessGuid?: InputMaybe<Scalars['String']['input']>;
  aspectRatio?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  vibe?: InputMaybe<Scalars['String']['input']>;
  hasFootage?: InputMaybe<Scalars['Boolean']['input']>;
  shouldEnforceDemoBehavior?: InputMaybe<Scalars['Boolean']['input']>;
  recentVariants?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  instructions?: InputMaybe<Scalars['String']['input']>;
}>;

export type VariantForAiQuery = {
  __typename: 'Query';
  videoTemplateVariantForAi?: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    guid: string;
    createdAt: string;
    slug: string;
    omniGroupSlug?: string | null;
    displayName: string;
    displayDuration?: number | null;
    previewVideoUrl?: string | null;
    thumbnailImageUrl?: string | null;
    isPartnerExclusive?: boolean | null;
    width: number;
    height: number;
    price?: number | null;
    compoundSlug: string;
    aspectRatio: string;
  } | null;
};

export type AudioForAiQueryVariables = Exact<{
  duration?: InputMaybe<Scalars['Int']['input']>;
  vibe?: InputMaybe<Scalars['String']['input']>;
}>;

export type AudioForAiQuery = {
  __typename: 'Query';
  videoTemplateAudioForAi?: {
    __typename: 'VideoTemplateAudioNode';
    id: string;
    name: string;
    transcodedAudio?: string | null;
    duration: number;
  } | null;
};

export type EditorVideoTemplateVariantQueryVariables = Exact<{
  videoTemplateVariantSlug: Scalars['String']['input'];
}>;

export type EditorVideoTemplateVariantQuery = {
  __typename: 'Query';
  videoTemplateVariantBySlug?: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    guid: string;
    slug: string;
    displayName: string;
    displayDuration?: number | null;
    width: number;
    height: number;
    videoDescriptor?: string | null;
    shouldIncludeGlobalAudio: boolean;
    compoundSlug: string;
    vibes: {
      __typename: 'VibeNodeConnection';
      edges: Array<{
        __typename: 'VibeNodeEdge';
        node?: { __typename: 'VibeNode'; id: string; name: string } | null;
      } | null>;
    };
    videoTemplate: {
      __typename: 'VideoTemplateNode';
      id: string;
      slug: string;
      isWaymarkAuthorTemplate: boolean;
    };
  } | null;
};

export type VideoTemplateVariantAutofillInfoQueryVariables = Exact<{
  variantSlug: Scalars['String']['input'];
}>;

export type VideoTemplateVariantAutofillInfoQuery = {
  __typename: 'Query';
  videoTemplateVariantBySlug?: {
    __typename: 'VideoTemplateVariantRelayNode';
    id: string;
    aspectRatio: string;
    displayDuration?: number | null;
  } | null;
};

export type VibesQueryVariables = Exact<{
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type VibesQuery = {
  __typename: 'Query';
  vibes?: Array<{
    __typename: 'VibeNode';
    id: string;
    name: string;
    visibleInClient: boolean;
  } | null> | null;
};

export type CoreVideoLibraryVideoFieldsFragment = {
  __typename: 'VideoAssetLibraryVideoNode';
  id: string;
  pk?: number | null;
  guid: string;
  uploadKey: string;
  displayName?: string | null;
  width?: number | null;
  height?: number | null;
  length?: number | null;
  order: number;
  updatedAt: string;
  removedAt?: string | null;
};

export type CoreVideoLibraryFieldsFragment = {
  __typename: 'VideoAssetLibraryNode';
  id: string;
  pk?: number | null;
  slug: string;
  displayName: string;
  videoAssets?: {
    __typename: 'VideoAssetLibraryVideoNodeConnection';
    edges: Array<{
      __typename: 'VideoAssetLibraryVideoNodeEdge';
      node?: {
        __typename: 'VideoAssetLibraryVideoNode';
        id: string;
        pk?: number | null;
        guid: string;
        uploadKey: string;
        displayName?: string | null;
        width?: number | null;
        height?: number | null;
        length?: number | null;
        order: number;
        updatedAt: string;
        removedAt?: string | null;
      } | null;
    } | null>;
  } | null;
};

export const AdminAutofillResultDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserVideoAutofillResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vibe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'languageCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillFeedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminAutofillResultDataFragment, unknown>;
export const AdminAutofillResultAccountDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultAccountData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminAutofillResultAccountDataFragment, unknown>;
export const CoreAccountFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastLoginDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreAccountFieldsFragment, unknown>;
export const AccountAccountGroupFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAccountGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountAccountGroupFieldsFragment, unknown>;
export const TemplateBrowserVariantFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateBrowserVariantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoTemplateVariantRelayNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'omniGroupSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previewVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licensing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateBrowserVariantFragmentFragment, unknown>;
export const AccountAudioAssetFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAudioAssetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountAudioAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountAudioAssetFieldsFragment, unknown>;
export const BfsFontVariantFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BfsFontVariantFieldsFragment, unknown>;
export const TemplateFontFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateFontFieldsFragment, unknown>;
export const FontLibraryFontFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FontLibraryFontFieldsFragment, unknown>;
export const FontLibraryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FontLibraryFontFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FontLibraryFieldsFragment, unknown>;
export const UserVideoRenderedVideoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoRenderedVideo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RenderedVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWatermark' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoRenderedVideoFragment, unknown>;
export const EditorTemplateAudioTrackFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorTemplateAudioTrackFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoTemplateAudioNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audioUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audioPath' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditorTemplateAudioTrackFragmentFragment, unknown>;
export const ImageLibraryImageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageLibraryImageFragmentFragment, unknown>;
export const AutofillResultFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutofillResultFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserVideoAutofillResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSpeakerLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplateLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVoiceOverLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'languageCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vibe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceover' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logoImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutofillResultFieldsFragment, unknown>;
export const BusinessSearchBusinessDataUpdateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessSearchBusinessDataUpdate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessSearchBusinessDataUpdateFragment, unknown>;
export const ColorLibraryColorFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorLibraryColorFragmentFragment, unknown>;
export const BusinessSearchColorsCreatedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessSearchColorsCreated' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ColorLibraryColorFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorLibrary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessSearchColorsCreatedFragment, unknown>;
export const BusinessSearchImagesCreatedFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BusinessSearchImagesCreated' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageLibrary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessSearchImagesCreatedFragment, unknown>;
export const ColorLibraryFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorLibraryFragmentFragment, unknown>;
export const CoreBusinessDetailsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreBusinessDetailsFragmentFragment, unknown>;
export const ImageLibraryFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageLibraryFragmentFragment, unknown>;
export const StockVideoAssetFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockVideoAssetFragmentFragment, unknown>;
export const StockVideoLibraryVideoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockVideoLibraryVideoFragmentFragment, unknown>;
export const StockVideoLibraryFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockVideoLibraryVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockVideoLibraryFragmentFragment, unknown>;
export const CoreUserVideoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreUserVideoFragmentFragment, unknown>;
export const UserVideoApprovalFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoApprovalFragmentFragment, unknown>;
export const UserVideoAccountPageFieldsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoAccountPageFieldsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreUserVideoFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseApproval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoApprovals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voiceOverProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDelivered' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'derivedUserVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalUserVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'omniType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoAccountPageFieldsFragmentFragment, unknown>;
export const UserVideoFeedbackFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoFeedbackFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoFeedbackNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeToCreate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoFeedbackFragmentFragment, unknown>;
export const EditorVideoTemplateVariantFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoTemplateVariantRelayNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIncludeGlobalAudio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vibes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditorVideoTemplateVariantFragmentFragment, unknown>;
export const EditorUserVideoFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverSpeakerGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverTiming' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoTemplateVariantRelayNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIncludeGlobalAudio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vibes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditorUserVideoFragmentFragment, unknown>;
export const UserVideoRendersFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoRendersFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renderedVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderFormat' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasWatermark' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoRendersFragmentFragment, unknown>;
export const SdkUserVideoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SDKUserVideo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreUserVideoFragment' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserVideoRendersFragment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoRendersFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renderedVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderFormat' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasWatermark' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SdkUserVideoFragment, unknown>;
export const CoreVideoLibraryVideoFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreVideoLibraryVideoFieldsFragment, unknown>;
export const CoreVideoLibraryFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoAssetLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoAssets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreVideoLibraryFieldsFragment, unknown>;
export const AccountDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByPk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileImage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstPurchasedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasActiveSubscriptions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summaryUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'offerContext' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hasUnlimited' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasWhiteLabeling' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasVideoApproval' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasVoiceOver' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasConcierge' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'latestVideoDownloadSubscriptionValidThrough',
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'recurringMonthlyDownloads' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurringRolloverDownloads' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalRemainingRecurringDownloads' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalRemainingDownloads' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'partner' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceActivities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'completedOn' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'adminUser' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'fullName' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'activityType' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountDetailQuery, AccountDetailQueryVariables>;
export const AddCreditsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddCreditsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'creditCount' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addAccountCredits' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'creditCount' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'creditCount' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'offerContext' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalRemainingDownloads' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCreditsMutationMutation, AddCreditsMutationMutationVariables>;
export const AdminAccountListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminAccountList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'externalId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName_Istartswith' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName_Istartswith' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName_Istartswith' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone_Startswith' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddress_Istartswith' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddress' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountGroups_DisplayName_Icontains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partner_Name_Icontains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'displayName' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'partner' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'name' },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'partner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminAccountListQuery, AdminAccountListQueryVariables>;
export const BulkRenderJobDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BulkRenderJobDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkRenderJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bulkRenderVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'renderId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'configuration' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkRenderJobDetailQuery, BulkRenderJobDetailQueryVariables>;
export const BulkRenderingJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BulkRenderingJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkRenderJobs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'configurationMapping' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destinationAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkRenderingJobsQuery, BulkRenderingJobsQueryVariables>;
export const ImageLibraryDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImageLibraryDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageLibraryByPk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImageLibraryFragment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageLibraryDetailQuery, ImageLibraryDetailQueryVariables>;
export const UpdateImageLibraryImageMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateImageLibraryImageMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateImageLibraryImageMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateImageLibraryImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateImageLibraryImageMutationMutation,
  UpdateImageLibraryImageMutationMutationVariables
>;
export const DeleteImageLibraryImageMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteImageLibraryImageMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteImageLibraryImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ok' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteImageLibraryImageMutationMutation,
  DeleteImageLibraryImageMutationMutationVariables
>;
export const ImageLibraryListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImageLibraryList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageLibraries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug_Icontains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayName_Icontains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImageLibraryListQuery, ImageLibraryListQueryVariables>;
export const CreateImageLibraryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateImageLibraryMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createImageLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'displayName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'displayName' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateImageLibraryMutationMutation,
  CreateImageLibraryMutationMutationVariables
>;
export const SelectedPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectedPartner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerByPk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectedPartnerQuery, SelectedPartnerQueryVariables>;
export const SearchPartnerByNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchPartnerByName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nameSearchString' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name_Icontains' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nameSearchString' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchPartnerByNameQuery, SearchPartnerByNameQueryVariables>;
export const AdminVideoTemplateVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminVideoTemplateVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchQuery' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'defaultRenderFormat' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoTemplate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminVideoTemplateVariantsQuery,
  AdminVideoTemplateVariantsQueryVariables
>;
export const VariantExportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VariantExport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariantsByIds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'creationData' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VariantExportQuery, VariantExportQueryVariables>;
export const UserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplateVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'licensing' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variantOffer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'offer' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'exclusivePartner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasFootage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'imageAssetCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'exclusivePartner' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoQuery, UserVideoQueryVariables>;
export const VideoAssetLibraryDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VideoAssetLibraryDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoLibraryByPk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreVideoLibraryFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoAssetLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoAssets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoAssetLibraryDetailQuery, VideoAssetLibraryDetailQueryVariables>;
export const AdminVideoAssetLibraryListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminVideoAssetLibraryList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminVideoAssetLibraryListQuery,
  AdminVideoAssetLibraryListQueryVariables
>;
export const VideoAssetLibraryVideoDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VideoAssetLibraryVideoDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoLibraryVideoByPk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pk' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VideoAssetLibraryVideoDetailQuery,
  VideoAssetLibraryVideoDetailQueryVariables
>;
export const AdminAutofillResultsUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminAutofillResultsUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminAutofillResultAccountData' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'autofillResults' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AdminAutofillResultData' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultAccountData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserVideoAutofillResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vibe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'languageCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillFeedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminAutofillResultsUserVideoQuery,
  AdminAutofillResultsUserVideoQueryVariables
>;
export const AdminAutofillResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminAutofillResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'autofillRequestGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillResultByRequestGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestGuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'autofillRequestGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminAutofillResultData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AdminAutofillResultAccountData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserVideoAutofillResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vibe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'languageCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillFeedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultAccountData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AdminAutofillResultQuery, AdminAutofillResultQueryVariables>;
export const AdminAutofillResultsForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AdminAutofillResultsForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AdminAutofillResultAccountData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'autofillResults' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AdminAutofillResultData' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultAccountData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AdminAutofillResultData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserVideoAutofillResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vibe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'languageCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillFeedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminAutofillResultsForAccountQuery,
  AdminAutofillResultsForAccountQueryVariables
>;
export const AccountPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountPartner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activeUserCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentMonthPurchasedVideoCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxActiveUsers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyVideos' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountPartnerQuery, AccountPartnerQueryVariables>;
export const GenerationResultUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenerationResultUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'business' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplateVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerationResultUserVideoQuery,
  GenerationResultUserVideoQueryVariables
>;
export const TemplateVariantOmniSupportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TemplateVariantOmniSupport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'variantSlug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariantBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'variantSlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'omniGroupSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOmni' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TemplateVariantOmniSupportQuery,
  TemplateVariantOmniSupportQueryVariables
>;
export const AccountOmniPermissionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountOmniPermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOmniEnabled' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountOmniPermissionsQuery, AccountOmniPermissionsQueryVariables>;
export const VideoPreviewPageUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VideoPreviewPageUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBeenDeletedByUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'voiceOverProduct' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'finalVideo' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplateVariant' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoTemplate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'account' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserVideoRendersFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoRendersFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renderedVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderFormat' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasWatermark' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VideoPreviewPageUserVideoQuery,
  VideoPreviewPageUserVideoQueryVariables
>;
export const UserVideoShareModalDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserVideoShareModalData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoShareModalDataQuery, UserVideoShareModalDataQueryVariables>;
export const AccountPartnerNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountPartnerName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountPartnerNameQuery, AccountPartnerNameQueryVariables>;
export const ManagedAccountDetailsByGuidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManagedAccountDetailsByGUID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoreAccountFields' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AccountAccountGroupFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastLoginDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAccountGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ManagedAccountDetailsByGuidQuery,
  ManagedAccountDetailsByGuidQueryVariables
>;
export const AccountGroupListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountGroupList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerGuid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managedAccountGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partnerGuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerGuid' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountGroupListQuery, AccountGroupListQueryVariables>;
export const UpdateAccountsByGuidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountsByGUID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateAccountMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedAccounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreAccountFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountAccountGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastLoginDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAccountGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAccountsByGuidMutation, UpdateAccountsByGuidMutationVariables>;
export const CreateAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAccountMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreAccountFields' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AccountAccountGroupFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastLoginDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAccountGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAccountMutation, CreateAccountMutationVariables>;
export const TakenEmailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TakenEmails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddresses' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'takenEmails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailAddresses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'emailAddresses' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TakenEmailsQuery, TakenEmailsQueryVariables>;
export const PasswordValidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PasswordValid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'passwordValid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordValidQuery, PasswordValidQueryVariables>;
export const CanPurchaseForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CanPurchaseForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGuid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'canPurchaseForAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountGuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGuid' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CanPurchaseForAccountQuery, CanPurchaseForAccountQueryVariables>;
export const ApproveUserVideoPurchaseMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveUserVideoPurchaseMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApproveUserVideoPurchaseMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveUserVideoPurchase' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApproveUserVideoPurchaseMutationMutation,
  ApproveUserVideoPurchaseMutationMutationVariables
>;
export const CollectionsForUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollectionsForUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateCollections' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayNameShort' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionsForUserQuery, CollectionsForUserQueryVariables>;
export const SelectedCollectionDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectedCollectionDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionSlug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateCollectionBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionSlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayNameShort' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortDescription' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelectedCollectionDetailsQuery,
  SelectedCollectionDetailsQueryVariables
>;
export const AllTemplateBrowserFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllTemplateBrowserFilters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableFilters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllTemplateBrowserFiltersQuery,
  AllTemplateBrowserFiltersQueryVariables
>;
export const TemplateBrowserVariantsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TemplateBrowserVariants' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'collectionSlug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'length' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aspectRatio' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hasFootage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'maxImageAssetCount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'minImageAssetCount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'templateName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariants' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupVariant_VariantGroup_Slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'collectionSlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayDuration_In' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'length' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'videoTemplate_HasFootage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hasFootage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'videoTemplate_ImageAssetCount_Lte' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'maxImageAssetCount' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'videoTemplate_ImageAssetCount_Gte' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'minImageAssetCount' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aspectRatio_In' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'aspectRatio' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'videoTemplate_Name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'templateName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TemplateBrowserVariantFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateBrowserVariantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoTemplateVariantRelayNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'omniGroupSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'previewVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licensing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'permissions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TemplateBrowserVariantsQuery, TemplateBrowserVariantsQueryVariables>;
export const AccountVideoLibrariesDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountVideoLibrariesDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CoreVideoLibraryFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stockVideoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videoAssetLibraries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'CoreVideoLibraryFields',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoAssetLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoAssets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockVideoLibraryVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountVideoLibrariesDetailQuery,
  AccountVideoLibrariesDetailQueryVariables
>;
export const BusinessVideoDetailsByGuidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BusinessVideoDetailsByGUID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CoreVideoLibraryFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stockVideoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoAssetLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoAssets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockVideoLibraryVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BusinessVideoDetailsByGuidQuery,
  BusinessVideoDetailsByGuidQueryVariables
>;
export const AccountStockVideoLibraryAssetKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountStockVideoLibraryAssetKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stockVideoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stockVideoLibraryVideos' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'stockAsset' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'vpsKey' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'sourceAssetId',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountStockVideoLibraryAssetKeysQuery,
  AccountStockVideoLibraryAssetKeysQueryVariables
>;
export const BusinessStockVideoLibraryAssetKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BusinessStockVideoLibraryAssetKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stockVideoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'stockVideoLibraryVideos' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'stockAsset' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'id' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'vpsKey' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: {
                                                              kind: 'Name',
                                                              value: 'sourceAssetId',
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BusinessStockVideoLibraryAssetKeysQuery,
  BusinessStockVideoLibraryAssetKeysQueryVariables
>;
export const CreateAccountVideoLibraryVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccountVideoLibraryVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateAccountVideoLibraryVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccountVideoLibraryVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoLibraryVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAccountVideoLibraryVideoMutation,
  CreateAccountVideoLibraryVideoMutationVariables
>;
export const CreateBusinessVideoLibraryVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBusinessVideoLibraryVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBusinessVideoLibraryVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBusinessVideoLibraryVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoLibraryVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateBusinessVideoLibraryVideoMutation,
  CreateBusinessVideoLibraryVideoMutationVariables
>;
export const UpdateVideoLibraryVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVideoLibraryVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateVideoLibraryVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVideoLibraryVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreVideoLibraryVideoFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoAssetLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateVideoLibraryVideoMutation,
  UpdateVideoLibraryVideoMutationVariables
>;
export const AccountAudioAssetForUploadKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountAudioAssetForUploadKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountAudioAssetForUploadKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uploadKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'uploadKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AccountAudioAssetFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAudioAssetFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountAudioAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountAudioAssetForUploadKeyQuery,
  AccountAudioAssetForUploadKeyQueryVariables
>;
export const GetOrCreateDefaultAccountFontLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateDefaultAccountFontLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetOrCreateDefaultAccountFontLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateDefaultAccountFontLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fontLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FontLibraryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FontLibraryFontFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateDefaultAccountFontLibraryMutation,
  GetOrCreateDefaultAccountFontLibraryMutationVariables
>;
export const GetOrCreateDefaultBusinessFontLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateDefaultBusinessFontLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetOrCreateDefaultBusinessFontLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateDefaultBusinessFontLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fontLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FontLibraryFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FontLibraryFontFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateDefaultBusinessFontLibraryMutation,
  GetOrCreateDefaultBusinessFontLibraryMutationVariables
>;
export const AddFontToFontLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddFontToFontLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddFontToFontLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addFontToFontLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdFontLibraryFont' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'FontLibraryFontFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedFontLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddFontToFontLibraryMutation, AddFontToFontLibraryMutationVariables>;
export const RemoveFontFromFontLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveFontFromFontLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveFontFromFontLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeFontFromFontLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'removedFontLibraryFontId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveFontFromFontLibraryMutation,
  RemoveFontFromFontLibraryMutationVariables
>;
export const FontLibrariesForBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FontLibrariesForBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fontLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FontLibraryFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FontLibraryFontFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FontLibrariesForBusinessQuery, FontLibrariesForBusinessQueryVariables>;
export const FontLibrariesForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FontLibrariesForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fontLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FontLibraryFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fontLibraries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'FontLibraryFields',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FontLibraryFontFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FontLibrariesForAccountQuery, FontLibrariesForAccountQueryVariables>;
export const GlobalFontLibrariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GlobalFontLibraries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalFontLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FontLibraryFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BFSFontVariantFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BFSFontVariant' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isItalic' } },
          { kind: 'Field', name: { kind: 'Name', value: 'weightIndex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TemplateFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TemplateFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bfsUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontFamilyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BFSFontVariantFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFontFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryFontNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'font' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TemplateFontFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FontLibraryFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FontLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fonts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'FontLibraryFontFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalFontLibrariesQuery, GlobalFontLibrariesQueryVariables>;
export const BusinessImageDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BusinessImageData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logoImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessImageDataQuery, BusinessImageDataQueryVariables>;
export const AccountImageLibrariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountImageLibraries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ImageLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageLibraries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'ImageLibraryFragment',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountImageLibrariesQuery, AccountImageLibrariesQueryVariables>;
export const BusinessImageLibrariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BusinessImageLibraries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logoImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ImageLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessImageLibrariesQuery, BusinessImageLibrariesQueryVariables>;
export const UpdateUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedUserVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'business' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserVideoMutation, UpdateUserVideoMutationVariables>;
export const DeleteUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deletedUserVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserVideoMutation, DeleteUserVideoMutationVariables>;
export const CreateUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserVideoMutation, CreateUserVideoMutationVariables>;
export const RenderUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenderUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RenderUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renderUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreUserVideoFragment' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserVideoAccountPageFieldsFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoAccountPageFieldsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreUserVideoFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseApproval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoApprovals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voiceOverProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDelivered' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'derivedUserVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalUserVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'omniType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenderUserVideoMutation, RenderUserVideoMutationVariables>;
export const CreateUserVideoApprovalRequestDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserVideoApprovalRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserVideoApprovalRequestMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserVideoApprovalRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userVideoApprovals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserVideoApprovalRequestMutation,
  CreateUserVideoApprovalRequestMutationVariables
>;
export const ApproveUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ApproveUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approveUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userVideoApprovals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApproveUserVideoMutation, ApproveUserVideoMutationVariables>;
export const MostRecentlyEditedUserVideoInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MostRecentlyEditedUserVideoInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'lastEditedUserVideo' },
                  name: { kind: 'Name', value: 'userVideos' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'StringValue', value: '-last_edited_by_user', block: false },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastEditedByUser' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'lastPurchasedUserVideo' },
                  name: { kind: 'Name', value: 'userVideos' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'StringValue', value: '-purchased_at', block: false },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MostRecentlyEditedUserVideoInfoQuery,
  MostRecentlyEditedUserVideoInfoQueryVariables
>;
export const UserVideoRendersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserVideoRenders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentConfiguredVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'renderedVideos' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'UserVideoRenderedVideo' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoRenderedVideo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RenderedVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderFormat' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'renderSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasWatermark' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoRendersQuery, UserVideoRendersQueryVariables>;
export const AccountPartnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountPartners' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountPartnersQuery, AccountPartnersQueryVariables>;
export const SelectedAccountPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SelectedAccountPartner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectedAccountPartnerQuery, SelectedAccountPartnerQueryVariables>;
export const ManagedAccountListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ManagedAccountList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isActive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hasNoAccountGroup' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'partnerGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isActive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isActive' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountGroups_Guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupGUID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountGroups_Isnull' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hasNoAccountGroup' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'role' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchString' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchString' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'partner_Guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'partnerGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'endCursor' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CoreAccountFields' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalVideosCreated' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalVideosCompleted' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'AccountAccountGroupFields' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreAccountFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastLoginDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AccountAccountGroupFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AccountRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ManagedAccountListQuery, ManagedAccountListQueryVariables>;
export const RefreshBusinessStockVideoLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshBusinessStockVideoLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stockVideoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockVideoLibraryVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshBusinessStockVideoLibraryQuery,
  RefreshBusinessStockVideoLibraryQueryVariables
>;
export const RefreshAccountStockVideoLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RefreshAccountStockVideoLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stockVideoLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockVideoLibraryVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshAccountStockVideoLibraryQuery,
  RefreshAccountStockVideoLibraryQueryVariables
>;
export const AccountIntercomInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountIntercomInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subscriptionPlan' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountIntercomInfoQuery, AccountIntercomInfoQueryVariables>;
export const MusicTabTemplateAudioTracksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MusicTabTemplateAudioTracks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'variantGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateAudioForVariantGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variantGuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'variantGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorTemplateAudioTrackFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorTemplateAudioTrackFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoTemplateAudioNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audioUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audioPath' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MusicTabTemplateAudioTracksQuery,
  MusicTabTemplateAudioTracksQueryVariables
>;
export const MusicTabGlobalAudioLibrariesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MusicTabGlobalAudioLibraries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'duration' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'globalAudioLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'globalAudio' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'duration' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'duration' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'videoTemplateAudio' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'EditorTemplateAudioTrackFragment',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorTemplateAudioTrackFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'VideoTemplateAudioNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audioUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'audioPath' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MusicTabGlobalAudioLibrariesQuery,
  MusicTabGlobalAudioLibrariesQueryVariables
>;
export const UpscaledImageDataRefetchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UpscaledImageDataRefetch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageLibraryImageGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageLibraryImageByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'imageLibraryImageGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpscaledImageDataRefetchQuery, UpscaledImageDataRefetchQueryVariables>;
export const PartnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Partners' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partners' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailDomain' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'canUsersPurchase' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasVoiceOver' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxActiveUsers' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxMonthlyVideos' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasUnlimited' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shouldHideBusinessPersonalization' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isTvOnlyIntegration' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountGroups' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'guid' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'displayName' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartnersQuery, PartnersQueryVariables>;
export const PartnerStatsBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PartnerStatsBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'visitCount' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'startDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'draftVideoCount' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'startDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'generatedVideoCount' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'startDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'purchasedVideoCount' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'startDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'renderedVideoCount' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'startDate' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartnerStatsBySlugQuery, PartnerStatsBySlugQueryVariables>;
export const PartnerAccountGroupStatsBySlugDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PartnerAccountGroupStatsBySlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partners' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountGroups' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'guid' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'accountGroupGUID' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'visitCount' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'start' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'startDate' },
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'end' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'endDate' },
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'draftVideoCount' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'start' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'startDate' },
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'end' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'endDate' },
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'generatedVideoCount',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'start' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'startDate' },
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'end' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'endDate' },
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'purchasedVideoCount',
                                                },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'start' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'startDate' },
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'end' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'endDate' },
                                                    },
                                                  },
                                                ],
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'renderedVideoCount' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'start' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'startDate' },
                                                    },
                                                  },
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'end' },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: { kind: 'Name', value: 'endDate' },
                                                    },
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PartnerAccountGroupStatsBySlugQuery,
  PartnerAccountGroupStatsBySlugQueryVariables
>;
export const PartnerUsageByAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PartnerUsageByAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerUsageByAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountGroupGUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visitCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draftVideoCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedVideoCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'generatedVideoCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderedVideoCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartnerUsageByAccountQuery, PartnerUsageByAccountQueryVariables>;
export const PartnerUsageByAccountGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PartnerUsageByAccountGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerUsageByAccountGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'groupName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visitCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'draftVideoCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedVideoCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PartnerUsageByAccountGroupQuery,
  PartnerUsageByAccountGroupQueryVariables
>;
export const PartnerVideoDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PartnerVideoDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPurchased' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerVideosByAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'purchased' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isPurchased' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountGroupGUID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGroupGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountEmailAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountDisplayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoCreatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoPurchasedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variantName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'licensing' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoGuid' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PartnerVideoDetailsQuery, PartnerVideoDetailsQueryVariables>;
export const UpdateAutofillResultVideoDescriptorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAutofillResultVideoDescriptor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'videoDescriptorJSON' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAutofillResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'requestGuid' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'videoDescriptorJson' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'videoDescriptorJSON' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedAutofillResult' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAutofillResultVideoDescriptorMutation,
  UpdateAutofillResultVideoDescriptorMutationVariables
>;
export const CreateAutofillFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAutofillFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rating' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedbackText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedbackChipGUIDs' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAutofillFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autofillRequestGuid' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'rating' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'feedbackText' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'feedbackText' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'feedbackChipGuids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'feedbackChipGUIDs' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdAutofillFeedback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedbackChips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'suggestion' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'suggestionTitle' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'category' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'guid' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAutofillFeedbackMutation,
  CreateAutofillFeedbackMutationVariables
>;
export const UpdateAutofillFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAutofillFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'rating' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedbackText' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'feedbackChipGUIDs' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAutofillFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'autofillRequestGuid' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'rating' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'rating' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'feedbackText' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'feedbackText' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'feedbackChipGuids' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'feedbackChipGUIDs' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedAutofillFeedback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedbackChips' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'suggestion' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'suggestionTitle' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'category' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'guid' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAutofillFeedbackMutation,
  UpdateAutofillFeedbackMutationVariables
>;
export const AutofillResultsForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutofillResultsForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'maxAgeDays' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestSource' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'autofillResults' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'maxAgeDays' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'maxAgeDays' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'requestSource' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'requestSource' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AutofillResultFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutofillResultFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserVideoAutofillResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSpeakerLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplateLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVoiceOverLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'languageCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vibe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceover' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logoImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AutofillResultsForAccountQuery,
  AutofillResultsForAccountQueryVariables
>;
export const AutofillResultByRequestGuidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutofillResultByRequestGUID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillResultByRequestGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestGuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AutofillResultFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AutofillResultFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserVideoAutofillResultNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'error' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isSpeakerLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTemplateLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVoiceOverLocked' } },
          { kind: 'Field', name: { kind: 'Name', value: 'languageCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userInstructions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vibe' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceover' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logoImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AutofillResultByRequestGuidQuery,
  AutofillResultByRequestGuidQueryVariables
>;
export const AutofillResultFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutofillResultFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillResultByRequestGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestGuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requestGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'requestGuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'autofillFeedback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'feedbackChips' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'guid' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'text' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'suggestion' },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'suggestionTitle',
                                                      },
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'category' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'guid' },
                                                          },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutofillResultFeedbackQuery, AutofillResultFeedbackQueryVariables>;
export const AutofillFeedbackChipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutofillFeedbackChips' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'autofillFeedbackChipCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feedbackChips' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'suggestion' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'suggestionTitle' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutofillFeedbackChipsQuery, AutofillFeedbackChipsQueryVariables>;
export const CreateBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateBusinessMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createBusiness' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBusiness' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateBusinessMutation, CreateBusinessMutationVariables>;
export const UpdateBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateBusinessMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBusiness' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedBusiness' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateBusinessMutation, UpdateBusinessMutationVariables>;
export const DeleteBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteBusinessMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteBusiness' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedBusinessId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteBusinessMutation, DeleteBusinessMutationVariables>;
export const BusinessDetailsByGuidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BusinessDetailsByGUID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessDetailsByGuidQuery, BusinessDetailsByGuidQueryVariables>;
export const ListAllBusinessProfilesForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAllBusinessProfilesForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businesses' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreBusinessDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BusinessRelayNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVisible' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'businessAbout' } },
          { kind: 'Field', name: { kind: 'Name', value: 'websiteUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress1' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetAddress2' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'industryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalImageCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logoImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colorLibraries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListAllBusinessProfilesForAccountQuery,
  ListAllBusinessProfilesForAccountQueryVariables
>;
export const AccountBusinessCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountBusinessCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'businesses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountBusinessCountQuery, AccountBusinessCountQueryVariables>;
export const BusinessVideoCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BusinessVideoCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalVideoCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BusinessVideoCountQuery, BusinessVideoCountQueryVariables>;
export const UpdateOrCreateColorLibraryColorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrCreateColorLibraryColor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrCreateColorLibraryColorMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrCreateColorLibraryColor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedColorLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ColorLibraryFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrCreateColorLibraryColorMutation,
  UpdateOrCreateColorLibraryColorMutationVariables
>;
export const DeleteColorLibraryColorDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteColorLibraryColor' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeleteColorLibraryColorMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteColorLibraryColor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'removedColorId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteColorLibraryColorMutation,
  DeleteColorLibraryColorMutationVariables
>;
export const GetOrCreateAccountColorLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateAccountColorLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetOrCreateAccountColorLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateAccountColorLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ColorLibraryFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'didCreate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateAccountColorLibraryMutation,
  GetOrCreateAccountColorLibraryMutationVariables
>;
export const GetOrCreateBusinessColorLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateBusinessColorLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetOrCreateBusinessColorLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateBusinessColorLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ColorLibraryFragment' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'didCreate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateBusinessColorLibraryMutation,
  GetOrCreateBusinessColorLibraryMutationVariables
>;
export const ColorLibrariesForAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ColorLibrariesForAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ColorLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountGroups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'colorLibraries' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'edges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'node' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'FragmentSpread',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'ColorLibraryFragment',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColorLibrariesForAccountQuery, ColorLibrariesForAccountQueryVariables>;
export const ColorLibrariesForBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ColorLibrariesForBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'colorLibraries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ColorLibraryFragment' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryColorNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hexCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ColorLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ColorLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'colors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ColorLibraryColorFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ColorLibrariesForBusinessQuery,
  ColorLibrariesForBusinessQueryVariables
>;
export const GetOrCreateDefaultAccountImageLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateDefaultAccountImageLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetOrCreateDefaultAccountImageLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateDefaultAccountImageLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateDefaultAccountImageLibraryMutation,
  GetOrCreateDefaultAccountImageLibraryMutationVariables
>;
export const GetOrCreateDefaultBusinessImageLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetOrCreateDefaultBusinessImageLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetOrCreateDefaultBusinessImageLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getOrCreateDefaultBusinessImageLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrCreateDefaultBusinessImageLibraryMutation,
  GetOrCreateDefaultBusinessImageLibraryMutationVariables
>;
export const AddImageToImageLibraryMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddImageToImageLibraryMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddImageToImageLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addImageToImageLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdImageLibraryImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedImageLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'ImageLibraryImageFragment',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddImageToImageLibraryMutationMutation,
  AddImageToImageLibraryMutationMutationVariables
>;
export const UpdateImageLibraryImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateImageLibraryImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateImageLibraryImageMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateImageLibraryImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateImageLibraryImageMutation,
  UpdateImageLibraryImageMutationVariables
>;
export const UpdateImageLibraryImageLastUsedAtDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateImageLibraryImageLastUsedAt' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateImageLibraryImageLastUsedAtMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateImageLibraryImageLastUsedAt' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImageLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ImageLibraryImageNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pk' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastUsedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateImageLibraryImageLastUsedAtMutation,
  UpdateImageLibraryImageLastUsedAtMutationVariables
>;
export const StockImagesForImageKeysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StockImagesForImageKeys' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageKeys' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockImagesForImageKeys' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageKeys' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'imageKeys' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stockAssetId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stockSearchId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StockImagesForImageKeysQuery, StockImagesForImageKeysQueryVariables>;
export const ImageLibraryImageByImageKeyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImageLibraryImageByImageKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageKey' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageLibraryImageByImageKey' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'imageKey' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'imageKey' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'baseUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upscaledImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageWidth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ImageLibraryImageByImageKeyQuery,
  ImageLibraryImageByImageKeyQueryVariables
>;
export const AddStockVideoToLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddStockVideoToLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AddStockVideoToLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addStockVideoToLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdStockVideoLibraryVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdStockVideoLibrary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddStockVideoToLibraryMutation,
  AddStockVideoToLibraryMutationVariables
>;
export const RemoveStockVideoFromLibraryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveStockVideoFromLibrary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RemoveStockVideoFromLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeStockVideoFromLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedStockVideoLibraryVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoveStockVideoFromLibraryMutation,
  RemoveStockVideoFromLibraryMutationVariables
>;
export const RestoreRemovedStockVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RestoreRemovedStockVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RestoreRemovedStockVideoToLibraryMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'restoreRemovedStockVideoToLibrary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedStockVideoLibraryVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoAssetFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StockVideoAssetNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sourceAssetId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'length' } },
          { kind: 'Field', name: { kind: 'Name', value: 'vpsKey' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StockVideoLibraryVideoFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StockVideoLibraryVideoNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'removedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockAsset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'StockVideoAssetFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RestoreRemovedStockVideoMutation,
  RestoreRemovedStockVideoMutationVariables
>;
export const CreateUserVideoFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserVideoFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserVideoFeedbackMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserVideoFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedUserVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'feedback' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'UserVideoFeedbackFragment',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoFeedbackFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoFeedbackNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedbackText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeToCreate' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserVideoFeedbackMutation,
  CreateUserVideoFeedbackMutationVariables
>;
export const CreateEditorUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEditorUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'EditorUserVideoFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoTemplateVariantRelayNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIncludeGlobalAudio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vibes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverSpeakerGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverTiming' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEditorUserVideoMutation, CreateEditorUserVideoMutationVariables>;
export const UpdateUserVideoTitleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserVideoTitle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedUserVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserVideoTitleMutation, UpdateUserVideoTitleMutationVariables>;
export const UpdateUserVideoBusinessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserVideoBusiness' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedUserVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'business' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserVideoBusinessMutation,
  UpdateUserVideoBusinessMutationVariables
>;
export const UpdateUserVideoVoiceOverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserVideoVoiceOver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedUserVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'voiceOverText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'voiceOverSpeakerGuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'voiceOverTiming' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserVideoVoiceOverMutation,
  UpdateUserVideoVoiceOverMutationVariables
>;
export const UpdateUserVideoConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserVideoConfiguration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserVideoMutationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserVideo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatedUserVideo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserVideoConfigurationMutation,
  UpdateUserVideoConfigurationMutationVariables
>;
export const AccountUserVideoCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountUserVideoCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountUserVideoCountQuery, AccountUserVideoCountQueryVariables>;
export const AccountPageUserVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountPageUserVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isPurchased' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'unfilteredUserVideos' },
                  name: { kind: 'Name', value: 'userVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideos' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'isPurchased' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'isPurchased' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchQuery' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'UserVideoAccountPageFieldsFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoAccountPageFieldsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreUserVideoFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseApproval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoApprovals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voiceOverProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDelivered' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'derivedUserVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalUserVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'omniType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AccountPageUserVideosQuery, AccountPageUserVideosQueryVariables>;
export const AccountPageUserVideoAndDerivedVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountPageUserVideoAndDerivedVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserVideoAccountPageFieldsFragment' },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'derivedAccountPageUserVideos' },
                  name: { kind: 'Name', value: 'derivedUserVideos' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchQuery' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchQuery' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'UserVideoAccountPageFieldsFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoAccountPageFieldsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreUserVideoFragment' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseApproval' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoApprovals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'voiceOverProduct' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDelivered' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'derivedUserVideos' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalUserVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'omniType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AccountPageUserVideoAndDerivedVideosQuery,
  AccountPageUserVideoAndDerivedVideosQueryVariables
>;
export const SdkAllAccountUserVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SDKAllAccountUserVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accountGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SDKUserVideo' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoRendersFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renderedVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderFormat' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasWatermark' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SDKUserVideo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreUserVideoFragment' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserVideoRendersFragment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SdkAllAccountUserVideosQuery, SdkAllAccountUserVideosQueryVariables>;
export const UserVideoByGuidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserVideoByGUID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SDKUserVideo' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CoreUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditableDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'businessName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEnabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoTemplate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRetired' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoRendersFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentConfiguredVideo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'renderedVideos' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderUrl' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderFormat' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderedAt' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderStatus' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'renderSize' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasWatermark' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SDKUserVideo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CoreUserVideoFragment' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserVideoRendersFragment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoByGuidQuery, UserVideoByGuidQueryVariables>;
export const UserVideoApprovalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserVideoApprovals' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userVideoApprovals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoApprovalFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoApprovalNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserVideoApprovalsQuery, UserVideoApprovalsQueryVariables>;
export const EditorUserVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditorUserVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoByGuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userVideoGUID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorUserVideoFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoTemplateVariantRelayNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIncludeGlobalAudio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vibes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorUserVideoFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoNode' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoConfiguration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariant' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'purchasedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastEditedByUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'business' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverSpeakerGuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'voiceOverTiming' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditorUserVideoQuery, EditorUserVideoQueryVariables>;
export const VariantForAiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VariantForAI' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'businessGuid' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'aspectRatio' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'displayDuration' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'vibe' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hasFootage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shouldEnforceDemoBehavior' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recentVariants' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'instructions' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariantForAi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessGuid' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'businessGuid' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'aspectRatio' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'aspectRatio' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'displayDuration' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'displayDuration' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vibe' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'vibe' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'hasFootage' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'hasFootage' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldEnforceDemoBehavior' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shouldEnforceDemoBehavior' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recentVariants' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recentVariants' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'instructions' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'instructions' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'omniGroupSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previewVideoUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thumbnailImageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPartnerExclusive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VariantForAiQuery, VariantForAiQueryVariables>;
export const AudioForAiDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AudioForAI' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'duration' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'vibe' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateAudioForAi' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'duration' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'duration' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'vibe' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'vibe' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'transcodedAudio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AudioForAiQuery, AudioForAiQueryVariables>;
export const EditorVideoTemplateVariantDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditorVideoTemplateVariant' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'videoTemplateVariantSlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariantBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'videoTemplateVariantSlug' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditorVideoTemplateVariantFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoTemplateVariantRelayNode' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoDescriptor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldIncludeGlobalAudio' } },
          { kind: 'Field', name: { kind: 'Name', value: 'compoundSlug' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vibes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isWaymarkAuthorTemplate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditorVideoTemplateVariantQuery,
  EditorVideoTemplateVariantQueryVariables
>;
export const VideoTemplateVariantAutofillInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VideoTemplateVariantAutofillInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'variantSlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videoTemplateVariantBySlug' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'variantSlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayDuration' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VideoTemplateVariantAutofillInfoQuery,
  VideoTemplateVariantAutofillInfoQueryVariables
>;
export const VibesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Vibes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeHidden' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'vibes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeHidden' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeHidden' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibleInClient' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VibesQuery, VibesQueryVariables>;
